import { useEffect, useState, useRef } from "react";

import Card from "src/components/card/card";
import Filter from "src/components/filter/filter";

import Grid from "src/layouts/grid/grid";
import Header from "src/layouts/header/header";
import Main from "src/layouts/main/main";
import Footer from "src/layouts/footer";
import Menu from "src/components/menu/menu";
import { getModels, searchModels } from "src/apis/aimodels";
import Button from "src/components/ui/button";

import iconFull from "src/assets/images/icon-full.svg";
import iconGrid from "src/assets/images/icon-grid.svg";
import Preloader from "src/components/preloader/preloader";
import TitleBar from "src/layouts/title-bar/title-bar";

const Home = (props) => {
    const [models, setModels] = useState([]);

    const [pageNum, setPageNum] = useState(1);
    const [fetchingMore, setFetchingMore] = useState(false);

    useEffect(() => {
        handleModels({ opr: "list", pageNum });
        return () => {
            setModels([]);
        };
    }, []);

    // useEffect(() => {

    //     handleModels({ opr: "list", pageNum });
    // }, [pageNum]);

    const handleModels = async ({ opr, pageNum }) => {
        setFetchingMore(true);

        switch (opr) {
            case "list":
                let listRes = await getModels({ private: "false" }, pageNum);
                console.log(listRes);

                if (listRes) {
                    setModels([...models, ...listRes]);

                    if (listRes.length > 0) {
                        setPageNum(pageNum + 1);
                    }
                }
                break;

            default:
                break;
        }
        setFetchingMore(false);
    };

    return (
        <Main>
            {models &&
            <TitleBar length={models?.length}>
                <div className="p-1">
                    <Filter />
                </div>
            </TitleBar>
}

            <Grid
                OnBottom={(e) => {
                    console.log("page :", pageNum);
                    handleModels({ opr: "list", pageNum });
                }}
            >
                {models.map((model, m) => (
                    <Card key={m} model={model} navigateEdit={`/character/${model.id}`} />
                ))}
            </Grid>
            {fetchingMore && <Preloader type={"more"} />}
            {/* <Preloader type={"more"} /> */}
        </Main>
    );
};

export default Home;
