import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import iconPlay from "src/assets/images/icon-play.svg";
import iconPause from "src/assets/images/icon-pause.svg";

import "./tts.css";
import BgFloating from "src/layouts/bg-floating";
import endpoint from "src/apis/endpoints";

const TTSPreview = () => {
    const params = useParams();
    const [speakPath, setSpeakPath] = useState();
    const [vidState, setVidState] = useState("paused");

    let videoRef = useRef();

    useEffect(()=>{
        if(params.key && params.avatarId){
            setSpeakPath(`${endpoint.download}/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/${params.key}/file000${params.avatarId}.ts`)
        }
    },[params])

    return (
        
        <div className="container-fluid flex flex-row p-0">
            <BgFloating />
            <div className="video-wrapper video-wrapper-solo">
                <>
                {speakPath &&
                    <video
                        src={speakPath}
                        autoPlay
                        
                        ref={(el) => {
                            if (el) {
                                videoRef.current = el;
                                console.log(el);

                                el.addEventListener("playing", (event) => {
                                    setVidState("playing");
                                    // setVidState('playing');
                                });

                                el.addEventListener("pause", (event) => {
                                    setVidState("paused");
                                });
                            }
                        }}
                    />
                }

                    <div className="video-wrapper-actions">
                                                {vidState === "playing" && (
                                                    <div
                                                        className="button-action"
                                                        onClick={() => {
                                                            videoRef.current.pause();
                                                        }}
                                                    >
                                                        <img src={iconPause} />
                                                    </div>
                                                )}

                                                {vidState === "paused" && (
                                                    <div
                                                        className="button-action"
                                                        onClick={() => {
                                                            videoRef.current.play();
                                                        }}
                                                    >
                                                        <img src={iconPlay} />
                                                    </div>
                                                )}
                                            </div>
                </>
                
            </div>
        </div>
    );
};

export default TTSPreview;
