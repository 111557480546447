import React, { useState, useRef, useEffect } from "react";
import AudioWaveform from "./wave";
import "./audio-recorder.css";
import { upload } from "src/apis/utils";
import Preloader from "../preloader/preloader";

const AudioRecorderWithLevels = (props) => {
    const [isRecording, setIsRecording] = useState(false);
    const [recordedAudio, setRecordedAudio] = useState(null);
    const [recordedAudioUrl, setRecordedAudioUrl] = useState(null);
    const [transcribedText, setTranscribedText] = useState('');
    const [audioStream, setAudioStream] = useState(null);
    const [fetchingUpload, setFetchingUpload] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const meterRef = useRef(null);
    const audioContextRef = useRef(new AudioContext());
    const audioPlayerRef = useRef(null);
    const timerRef = useRef();

    useEffect(() => {
        startRecording();
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    const startRecording = async () => {
        setRecordedAudioUrl(null);

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            // setAudioStream(stream);

            mediaRecorderRef.current = new MediaRecorder(stream);
            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };

            mediaRecorderRef.current.onstart = () => {
                setIsRecording(true);

                timerRef.current = setTimeout(() => {
                    mediaRecorderRef.current.stop();
                }, 20000);
            };

            mediaRecorderRef.current.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
                setRecordedAudio(audioBlob);

                const audioUrl = URL.createObjectURL(audioBlob);
                setRecordedAudioUrl(audioUrl);

                audioChunksRef.current = [];
                setIsRecording(false);
            };

            // Start recording
            mediaRecorderRef.current.start();
        } catch (error) {
            console.error("Error accessing microphone:", error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            audioChunksRef.current = [];
        }
    };

    const handleUpload = async () => {
        setFetchingUpload(true);
        if (recordedAudio) {
            console.log("Uploading recorded audio:", recordedAudio);
            let uploadAudioRes = await upload({ file: recordedAudio, directory: props.session.id, segment: "recorded_audio", postprocess: "whisper" });
            const transText = uploadAudioRes.data[0].transcription;
            console.log("transcribed text :", transText);
            setTranscribedText(transText);
            if (props.OnTranscribe && transText && transText !== "") {
                props.OnTranscribe(transText);
            }
        } else {
            console.error("No recorded audio available");
        }
        setFetchingUpload(false);
    };

    const handlePlay = () => {
        console.log(recordedAudio);
        if (recordedAudio) {
            try {
                const audioUrl = URL.createObjectURL(recordedAudio);
                setRecordedAudioUrl(audioUrl);

                const audio = new Audio(audioUrl);

                audio.play();
            } catch (error) {
                console.error("Error playing audio:", error);
            }
        }
    };

    const handleCancel = () =>{
        setRecordedAudio(null);
        setRecordedAudioUrl(null);
        setTranscribedText('')
        if(props.OnCancel){
        props.OnCancel(true);
        }
    }

    return (
        <div className="chat-input-recording flex flex-1 items-center fadeIn relative">
            {fetchingUpload && <Preloader type="full-relative" textOnly={"transcribing audio, please wait..."} />}
            {transcribedText === undefined ? (
                <div className="w-full h-full z-50 flex items-center justify-center">
                    Sorry we cannot process you audio. Please try again. <button onClick={()=>{handleCancel()}} className="button button-small button-trans ml-3">try again</button>
                </div>
            ) : (
                <>
                    <div className="flex flex-row flex-1">
                        {recordedAudioUrl && (
                            <div className="recording-controls">
                                <div className="item" onClick={handlePlay}>
                                    <i className="iicon iicon-play-med link"></i>
                                </div>
                            </div>
                        )}

                        {isRecording && (
                            <div className="recording-controls">
                                <div className="flex flex-row items-center px-2 ">
                                    <i className="iicon iicon-record blipping"></i> <div className="ps-2 red">Recording</div>
                                </div>

                                <div className="item-separator"></div>

                                <div className="item" onClick={stopRecording}>
                                    <i className="iicon iicon-stop-record"></i> <div className="ps-1">Stop</div>
                                </div>
                            </div>
                        )}

                        {recordedAudioUrl && (
                            <div className="flex-1">
                                <AudioWaveform audioUrl={recordedAudioUrl} audioStream={audioStream} />
                            </div>
                        )}
                    </div>

                    <div className="flex-0 flex items-center justify-content-between ps-3">
                        <div className="px-2 ">
                            <i
                                className="iicon iicon-x link link-icon"
                                onClick={() => {
                                    handleCancel()
                                }}
                            ></i>
                        </div>

                        <button className="button" onClick={handleUpload} disabled={!recordedAudioUrl} style={{ marginRight: "5px" }}>
                            Send
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default AudioRecorderWithLevels;
