import React, { useEffect, useState } from "react";
import { Route, Redirect, useHistory, useNavigate } from "react-router-dom";
import { getStoredUser } from "./auth/auth";
import Preloader from "./preloader/preloader";
import { getUser } from "src/apis/user";

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        setFetching(true)
        console.log("protected-route fired");
        initUser();
        setFetching(false)
    }, []);
    

    const initUser = async () => {
        let theUser = getStoredUser();
        if (theUser && theUser.id) {
            //let theUser = await getUser({ id: storedUser.id }, storedUser.id);
            setUser(theUser);
            localStorage.setItem("AVATARZ_user", JSON.stringify(theUser));
        } else {
            navigate("/login");
        }
    };

    return (
        

        <>
            {fetching && <Preloader type={'full'} />}
            {!fetching && user && user.id && <Component {...rest} key={document.location.href} />}
        </>
    );
};

export default ProtectedRoute;
