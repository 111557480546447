import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Card from "src/components/card/card";
import Filter from "src/components/filter/filter";

import Grid from "src/layouts/grid/grid";
import Header from "src/layouts/header/header";
import Main from "src/layouts/main/main";
import Footer from "src/layouts/footer";
import Menu from "src/components/menu/menu";
import { getModels } from "src/apis/aimodels";
import Button from "src/components/ui/button";

import iconFull from "src/assets/images/icon-full.svg";
import iconGrid from "src/assets/images/icon-grid.svg";
import Preloader from "src/components/preloader/preloader";
import { useNavigate } from "react-router-dom";

import { searchModels } from "src/apis/aimodels";
import TitleBar from "src/layouts/title-bar/title-bar";

const Search = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [models, setModels] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [pageNum, setPageNum] = useState(1);
    const [fetchingMore, setFetchingMore] = useState(false);

    useEffect(() => {
        if (location && location.state && location.state.searchText && location.state.searchText !== "") {
            setSearchText(location.state.searchText);
            setPageNum(1);
        }
    }, [location]);

    useEffect(() => {
        if (searchText && searchText !== "") {
            handleSearch(searchText, 1);
        }
    }, [searchText]);



    const handleSearch = async (searchText, pagenum) => {
        setSearchText(searchText);
        console.log(searchText);
        let searchRes = await searchModels(searchText, pagenum);

        console.log(searchRes);
        if (searchRes !== undefined) {
            if (pageNum === 1) {
                setModels([...searchRes]);
            } else {
                setModels([...models, ...searchRes]);
            }

            if (searchRes.length > 0) {
                setPageNum(pageNum + 1);
            }
        }
    };

    return (
        <Main>
            <TitleBar length={models?.length}>
                <div className="p-3 flex flex-1 items-center">
                    <div
                        className="navi navi-back mr-2"
                        onClick={() => {
                            navigate("/avatars");
                        }}
                    >
                        <i className="iicon iicon-nav-back"></i>
                    </div>
                    <div className="text-base mr-3">Search :</div>
                    <div className="opacity-50 text-base font-semibold"> {searchText !== "" ? `for ${searchText}` : ""}</div>
                </div>
            </TitleBar>

            {/* <Filter /> */}
            {pageNum === 1 && !fetchingMore && models.length <= 0 ? (
                <div className="flex flex-1 items-center justify-center">We could not find any avatar that matches "{searchText}"</div>
            ) : (
                <Grid
                    OnBottom={async () => {
                        if (models.length > 1) {
                            handleSearch(searchText, pageNum);
                        }
                    }}
                >
                    {models.map((model, m) => (
                        <Card key={m} model={model} navigateEdit={`/character/${model.id}`} />
                    ))}

                    {fetchingMore && <Preloader type={"more"} />}
                </Grid>
            )}
        </Main>
    );
};

export default Search;
