import "./main.css";
import Header from "src/layouts/header/header";
import Menu from "src/components/menu/menu";

import BgFloating from "src/layouts/bg-floating/";

import { useState } from "react";

const Main = (props) => {
    
    return (
        <div className="container-fluid flex flex-row p-0">
            {/* <BgFloating /> */}
           
            <div className="main">
                {!props.noHeader && (
                    <div className="header-wrapper">
                        <div className={`header`}>
                            <Header />
                        </div>
                    </div>
                )}

                <div className={`main-body ${props.noHeader ? "no-header" : ""}  ${props.noSidebar ? "no-sidebar" : ""}`}>
                    {!props.noSidebar && <Menu {...props} />}

                    <div className={`main-body-content`}>
                        <div className="main-body-wrapper">{props.children}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;
