import { useEffect, useState, useRef } from "react";
import VideoPlayer from "src/components/video-player";
import FormInput from "src/components/form/input";
import TypeText from "src/components/typetext/typetext";
import Popup from "src/layouts/popup/popup";
import Iicon from "src/components/iicon/iicon";
import { getModel } from "src/apis/aimodels";

const CharacterSummary = (props) => {
    // const { character } = props;

    const [character, setCharacter] = useState(props.character);

    const [fetching, setFetching] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        // console.log('111', character
        if (props.character.image !== "") {
            character.image = props.character.image;
            setCharacter({ ...character });
        }
    }, [props.character.image]);

    useEffect(() => {
        // console.log('111', character)
        if (props.character.fast0 !== "") {
            character.fast0 = props.character.fast0;
            character.fast1 = props.character.fast1;
            setCharacter({ ...character });
        }
    }, [props.character.fast0]);

    useEffect(() => {
        console.log("props.checkChange : ", props.checkChange);
        getModel({ id: character.id }).then((res) => {
            console.log(res);
            const mergechar = { ...character, ...res };
            setCharacter(mergechar);
        });
    }, [props.checkChange]);

    const Field = (props) => {
        return (
            <div className="flex flex-col md:flex-row gap-1 md:gap-4">
                <div className="opacity-40 text-nowrap min-w-24">{props.label} :</div> <pre className="whitespace-pre-wrap font-inherit">{props.value}</pre>
            </div>
        );
    };

    return (
        <>
            {showPreview && (
                <Popup
                    OnHide={() => {
                        setShowPreview(false);
                    }}
                >
                    <div className="aspect-video h-2/3 bg-black overflow-hidden  bg-black relative rounded-lg">
                        <VideoPlayer src={character.fast1} autoplay={false} />
                    </div>
                </Popup>
            )}

            {character && (
                <>
                    <div className="flex  flex-row gap-10">
                        <div className="flex flex-1 flex-col tab-content  mt-0 overflow-hidden">
                            <div className="p-6 flex flex-col md:flex-row gap-6">
                                <div>
                                    <div className="w-full md:w-52 md:h-52 avatar-box aspect-square overflow-hidden  bg-black relative rounded-lg">
                                        {character.image && (
                                            <img
                                                src={character.image.name ? URL.createObjectURL(character.image) : character.image}
                                                className="w-full h-full object-contain"
                                            />
                                        )}
                                    </div>

                                    <FormInput
                                        labelHasSelected={`Change Image`}
                                        className={`mt-2 form-button-small`}
                                        value={character.image}
                                        type={"file"}
                                        accept={"image/*"}
                                        OnSelect={(file) => {
                                            character.image = file;
                                            props.OnSet(character);
                                        }}
                                        OnUpload={() => {
                                            setFetching(true);
                                        }}
                                    />
                                </div>

                                <div className="flex-1 md:p-6 pr-0 flex flex-col gap-3">
                                    <Field label={`Name`} value={character.name} />
                                    <Field label={`Description`} value={character.description} />
                                    <Field label={`Category`} value={character.category} />

                                    <div className="flex flex-row gap-4 justify-center mt-5">
                                        <div className="opacity-40 text-nowrap min-w-24">Private :</div>
                                        <div className="flex-1">your avatar is {character.private === "true" ? "private" : "public"}</div>

                                        <div className="flex flex-0 flex-row">
                                            <FormInput
                                                // label={`Private :`}
                                                type={"slider"}
                                                value={character.private === "true" ? true : false}
                                                onChange={(value) => {
                                                    character.private = value.toString();
                                                    props.OnSet(character);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-6 flex flex-col gap-3">
                                <Field label={`Audio only`} value={character.audioonly === "true" ? "Yes" : "No"} />
                                <Field label={`LLM`} value={character.llm} />
                                <Field label={`Prompt`} value={character.prompt} />
                            </div>

                            {/* <div className="form-separator h-0"></div> */}

                            {/* <div className="flex-1 p-6 flex flex-col gap-3">
                                <Field label={`Name`} value={character.name} />

                                <Field label={`LLM`} value={character.llm} />
                                <Field label={`Category`} value={character.category} />

                                <Field label={`Prompt`} value={character.prompt} />
                                <Field label={`Description`} value={character.description} />
                            </div> */}

                            <div className="form-separator h-0"></div>
                            <div className="flex-1 p-6 flex flex-col gap-3">
                                <Field label={`Example Conversation`} value={character.exampleconvo} />
                                <Field label={`First Mesasge`} value={character.firstmsg} />

                                <Field label={`Personality`} value={character.personality} />

                                <Field label={`Scenario`} value={character.scenario} />
                                <Field label={`Tags`} value={character.tags} />
                            </div>

                            <div className="form-separator h-0"></div>

                            <div className="p-6">
                                <div className="mb-4">Avatar Video : </div>
                                <div className="flex flex-1 justify-between">
                                    <div className="w-1/6 aspect-video overflow-hidden  bg-black relative rounded-lg">
                                        {character.fast1 !== "" && <VideoPlayer src={character.fast1} autoplay={false} hideActions={true} muted={true} />}
                                    </div>
                                    <div className="flex items-center">
                                        <FormInput
                                            labelHasSelected={`Change`}
                                            className={` form-button-small`}
                                            directory={character.id}
                                            value={character.fast0}
                                            type={"file"}
                                            accept={"video/*, .ts"}
                                            requiredToUpload={character.id}
                                            OnSelect={(file) => {
                                                character.fast0 = file;
                                                character.fast1 = URL.createObjectURL(file);
                                                props.OnSet(character);
                                            }}
                                            OnUpload={() => {
                                                setFetching(true);
                                            }}
                                            required={true}
                                        />

                                        {/* {character.fast1 !== "" ? (
                                            <button
                                                className="button button-small"
                                                onClick={() => {
                                                    character.fast1 = "";
                                                    props.OnSet(character);
                                                }}
                                            >
                                                change
                                            </button>
                                        ) : (
                                            <button className="button button-small">Upload a video</button>
                                        )} */}
                                    </div>
                                </div>

                                <div className="opacity-40 flex gap-2 mt-3">
                                    <Iicon icon={`info`} /> This video will be used to generate you AI avatar
                                </div>
                                {/* <div className="upload-box relative aspect-video flex-1 flex rounded-lg overflow-hidden">
                                    <div className="previewer-box">
                                        {character.fast1 !== "" ? (
                                            <button
                                                className="button button-small button-white"
                                                onClick={() => {
                                                    character.fast1 = "";
                                                    props.OnSet(character);
                                                }}
                                            >
                                                clear
                                            </button>
                                        ) : (
                                            <button className="button button-small">Upload a video</button>
                                        )}

                                        {character.fast1 !== "" && <VideoPlayer src={character.fast1} autoplay={false} />}
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        {/* <div className="w-1/2 h-72 aspect-video flex-1 flex flex-col items-center justify-center  previewer-box">
                            {character.fast1 !== "" && <VideoPlayer src={character.fast1} autoplay={false} />}
                            {character.fast1 == "" && <div>No Video</div>}
                        </div> */}
                    </div>
                </>
            )}
        </>
    );
};
export default CharacterSummary;
