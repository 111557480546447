import { useEffect, useState } from "react";

const RadioTab = (props) => {
    const [selectedTab, setSelectedTab] = useState();
    const { tabs } = props;

    useEffect(() => {
       
        if (tabs && tabs.length > 1 && !selectedTab) {
            setSelectedTab(tabs[0]);
        }
    }, [tabs]);

    useEffect(() => {
        if (selectedTab && props.OnSelectType) {
            props.OnSelectType(selectedTab);
        }
    }, [selectedTab]);

    return (
        <div className="radio-tab flex flex-row rounded-xl bg-gray-200 relative overflow-hidden p-1">
            {tabs &&
                tabs.length > 1 &&
                tabs.map((tab, t) => (
                    <div
                        key={t}
                        className={`tab ${tab.id === selectedTab?.id ? `tab-selected bg-white ` : `opacity-30 `} px-4 py-1 rounded-lg cursor-pointer font-semibold transition-all text-xs`}
                        onClick={() => {
                            setSelectedTab(tab);
                        }}
                    >
                        {tab.name}
                    </div>
                ))}
        </div>
    );
};

export default RadioTab;
