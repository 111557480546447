import { useEffect, useState, useRef } from "react";
import FormInput from "src/components/form/input";
import Iicon from "src/components/iicon/iicon";
import Preloader from "src/components/preloader/preloader";
import VideoPlayer from "src/components/video-player";

const CharacterUploadVid = (props) => {
    const { character } = props;
    const [fetching, setFetching] = useState(false);
    return (
        <>
            {fetching && <Preloader type={"full-relative"} />}

            <div className="flex flex-col  p-10 flex-1 w-1/2 mx-auto gap-8 items-center justify-center">
                <div className="w-full">
                    {/* <div className="font-2xl text-center mb-20" >
                            Step 1
                        </div> */}

                    <div className="form-item-label">
                        <div>Avatar Video : </div>
                        <span className="required-icon">*</span>
                    </div>

                    <div className="upload-box relative aspect-video">
                        {/* <div className="p-5 rounded-xl info-floating">
                                <Iicon icon="info" />
                                <div className="flex flex-col gap-3 flex-wrap mt-3">
                                    To get better results please upload a video that has atleast 10 sec of silent frames.
                                </div>
                            </div> */}

                        {character.fast0 === "" && (
                            <div className="flex items-center flex-col">
                                <div>Please select a video to upload :</div>
                                <div className="text-center mt-3">
                                    <FormInput
                                        label={`Select video to upload`}
                                        placeholder={`Select video to upload`}
                                        placeholderSelected={"Select a another video"}
                                        directory={character.id}
                                        value={character.fast0}
                                        type={"file"}
                                        accept={"video/*, .ts"}
                                        requiredToUpload={character.id}
                                        OnSelect={(file) => {
                                            character.fast0 = file;
                                            character.fast1 = URL.createObjectURL(file);
                                            props.OnSet(character);
                                        }}
                                        OnUpload={() => {
                                            setFetching(true);
                                        }}
                                        required={true}
                                    />
                                </div>
                            </div>
                        )}

                        {character && character.fast1.indexOf("http") !== -1 && (
                            <div className="previewer-box">
                                <button
                                    className="button button-small button-white"
                                    onClick={() => {
                                        character.fast1 = "";
                                        character.fast0 = "";
                                        character.modelpath = "";
                                        character.silence = "";
                                        props.OnSet(character);
                                        // setChar({ ...character });
                                    }}
                                >
                                    change
                                </button>
                                <VideoPlayer src={character.fast1} autoplay={false} />
                            </div>
                        )}
                    </div>

                    <div className="flex mt-5 gap-3 items-center ">
                        <Iicon icon="info" />
                        <div className="">To get better results please upload a video that has atleast 10 sec of silent frames.</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CharacterUploadVid;
