import axios from "axios";
import { getStoredUser } from "src/components/auth/auth";
import endpoint from "./endpoints";
import { handleResponse } from "./response";

///////////////////////////QUERIES
export const getUser = async (input, params) => {
  
        try {
            let query = {
                Params: {
                    userid: getStoredUser()?.id,
                    auth: getStoredUser()?.auth,
                    sys: "users",
                    act: "select",
                    pagesize: "1",
                    pagenumber: "1",
                },
                Where: input,
            };
            if (params?.userid) {
                query.Params.userid = params.userid;
            }
            if (params?.auth) {
                query.Params.auth = params.auth;
            }

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    
};

export const searchUsers = async (input, params) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "users",
                    act: "select",
                    pagesize: params?.pagesize ? params.pagesize : "10",
                    pagenumber: params?.pagenumber ? params.pagenumber : "1",
                },
                WhereLike: input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const getFollowingNetworks = async (input, params) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,

                    sys: "followingnetworks",
                    act: "select",
                    pagesize: params?.pagesize ? params.pagesize : "20",
                    pagenumber: params?.pagenumber ? params.pagenumber : "1",
                },
                Where: input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const usersByNetwork = async (input, params) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,

                    sys: "networkmembers",
                    act: "select",
                    pagesize: params?.pagesize ? params.pagesize : "20",
                    pagenumber: params?.pagenumber ? params.pagenumber : "1",
                },
                Where: input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const getUserChats = async (params) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "users",
                    act: "select",
                    pagesize: params?.pagesize ? params.pagesize : "20",
                    pagenumber: params?.pagenumber ? params.pagenumber : "1",
                },
                WhereLike: {
                    name: "%%",
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

///////////////////////////MUTATIONS

export const loginUser = async (phone) => {

        try {
            let query = {
                Params: {
                    sys: "auth",
                    act: "login",
                    phone,
                    pagesize: "1",
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    
};

export const logoutUser = async () => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "auth",
                    act: "logout",
                    pagesize: "1",
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const createUser = async (input) => {
    if (getStoredUser() && getStoredUser().auth) {
        try {
            let query = {
                Params: {
                    auth: getStoredUser().auth,
                    sys: "users",
                    act: "insert",
                    pagesize: "1",
                    pagenumber: "1",
                },
                Values: input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const updateUser = async (userid, input, returnUser) => {
    if (userid) {
        if (getStoredUser() && getStoredUser().id) {
            try {
                let query = {
                    Params: {
                        userid: getStoredUser().id ? getStoredUser().id : userid,
                        auth: getStoredUser().auth,
                        sys: "page",
                        act: "updateuser",
                        pagesize: "1",
                    },
                    Where: { userid: userid },
                    Values: input,
                };

                const res = await axios.post(endpoint.rest, query);

                if (res.data && res.data.updateuser && res.data.updateuser.length > 0) {
                    let userobj = res.data.updateuser[0];
                    localStorage.setItem("AVATAR_user", JSON.stringify(userobj));
                }

                if (returnUser) {
                    return await getUser({ id: userid });
                } else {
                    return handleResponse(query, res);
                }
            } catch (error) {
                console.log("error :", error);
            }
        }
    }
};

export const createStreamChatUserToken = async (userId, auth) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: userId,
                    auth: auth,
                    // publishto: selectedChannel.id,

                    sys: "chat",
                    act: "token",
                    pagesize: "1",
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error:", error);
        }
    }
};
