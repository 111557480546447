import { useNavigate } from "react-router-dom";

const Button = (props) => {
    const navigate = useNavigate()

    return (
        <button 
            className={`button 
                ${(props.buttonStyle)?` button-${props.buttonStyle}`: ``} 
                ${(props.inline)?` button-inline`: ``} 

                ${(props.className)?` ${props.className}`: ``} 
            `}
            disabled={props.disabled}
            onClick={()=>{
                if(props.navigate){
                    navigate(props.navigate)
                }
                if(props.onClick){
                    props.onClick()
                }
               
            }}
        >
            <span>{props.children}</span>
        </button>
    );
}
 
export default Button;