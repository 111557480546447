export const models = [
    {
        id:'3',
        name:'Obama',
        image:'',
        model:'obama',
        firstmsg:'https://squadz.ngrok.app/download/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/541c0264-1a70-4c96-a7e9-8a0badb331a4/file0003.ts',
        key:"541c0264-1a70-4c96-a7e9-8a0badb331a4",
        pathkey:"541c0264-1a70-4c96-a7e9-8a0badb331a4/file0003.ts"
    },
    {
        id:'1',
        name:'Luka',
        image:'',
        model:'luka',
        firstmsg:'https://squadz.ngrok.app/download/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/bbadf5c2-bd3a-4190-b024-461d3b93cb7a/file0001.ts',
        key:"bbadf5c2-bd3a-4190-b024-461d3b93cb7a",
        pathkey:"bbadf5c2-bd3a-4190-b024-461d3b93cb7a/file0001.ts"
    }
]