import "./popup.css";

const Popup = (props) => {
    return (
        <>
            <div
                className={`popup-container fadeIn ${props.fixed ? "popup-container-fixed" : ""}`}
                onClick={() => {
                    if(props.OnHide){
                        props.OnHide(true);
                    }
                   
                }}
            ></div>

            <div className={`popup-content fadeIn ${props.fixed ? "popup-content-fixed" : ""}  ${props.className ? props.className : ''}`}>{props.children}</div>
        </>
    );
};

export default Popup;
