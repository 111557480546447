import axios from "axios";
import endpoint from "./endpoints";
import { getStoredUser } from "src/components/auth/auth";
import { handleResponse } from "./response";

const getPublicUser = () => {
    // let storedUser = localStorage.getItem("AVATARZ_user")
    // let currentUser =(storedUser && storedUser !== 'undefined')? JSON.parse(storedUser) : null;
    // let currentAuth = localStorage.getItem("AVATARZ_auth");
    // return {auth: currentAuth, user: currentUser};

    return {
        id: "11111111-1111-1111-1111-111111111111",
        auth: "xxxx",
    };
};

///////////////////////////QUERIES
export const getModels = async (input, pagenum) => {
  
        try {
            if (!pagenum) {
                pagenum = 1;
            }
            let query = {
                Params: {
                    userid: getPublicUser().id,
                    auth: getPublicUser().auth,
                    sys: "ai",
                    act: "getaimodels",
                    pagesize: "20",
                    pagenumber: pagenum.toString(),
                    orderby: "createdate desc",
                },
                Where: input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    
};

export const getModel = async (input) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getPublicUser().id,
                    auth: getPublicUser().auth,
                    sys: "ai",
                    act: "getaimodels",
                    pagesize: "1",
                    pagenumber: "1",
                },
                Where: input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const getMyModels = async (pagenum) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "getaimodels",
                    pagesize: "20",
                    pagenumber: pagenum.toString(),
                    orderby: "createdate desc",
                },
                Where: {
                    userid: getStoredUser().id,
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const getModelsByCategory = async (searchText, pagenum) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            pagenum = pagenum ? pagenum : 1;
            let query = {
                Params: {
                    userid: getPublicUser().id,
                    auth: getPublicUser().auth,
                    sys: "ai",
                    act: "getaimodels",
                    pagenumber: pagenum.toString(),
                    pagesize: "30",
                    orderby: "createdate desc",
                },
                Where: { private: "false" },
                WhereLike: { category: `%${searchText}%` },
            };

            if (getStoredUser() && getStoredUser().id) {
                // show private  characters if logged in
                delete query.Where;
            }

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const searchModels = async (searchText, pagenum) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "getaimodels",
                    pagenumber: pagenum.toString(),
                    pagesize: "20",
                },
                // Where: { private: "false" },
                WhereLike: {
                    name: `%${searchText}%`
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const listRecentTts = async (avatarId) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "s3",
                    act: "select",
                    pagesize: "50",
                    orderby: "createdate desc",
                },
                Where: {
                    userid: getStoredUser().id,
                    filename: `file000${avatarId}.ts`,
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

///////////////////////////MUTATIONS

export const createModel = async (input) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "addaimodels",
                    pagesize: "1",
                    pagenumber: "1",
                },
                Values: input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const updateModel = async (id, input) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "aimodels",
                    act: "update",
                    pagesize: "1",
                    pagenumber: "1",
                },
                Where: {
                    id: id,
                },
                Values: input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const deleteModel = async (input) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "deleteaimodels",
                    pagesize: "1",
                    pagenumber: "1",
                },
                Where: input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};
