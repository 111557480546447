
import { useState, useEffect } from "react";
import Popup from "src/layouts/popup/popup";
import Button from "src/components/ui/button";
import FormInput from "src/components/form/input";
import { createAiStyle } from "src/apis/aistyle";

const ModalCharacterVoice = (props) => {

    const [nerfVoice, setNerfVoice] = useState({
        name: "",
        mp3: "",
    });

    const handleCreateVoice = async () => {
        let input = {
            name:nerfVoice.name,
            path:nerfVoice.mp3,
            mtype:'voice'
        }

        let createRes = await createAiStyle(input);
        console.log(createRes)
        if(createRes && createRes[0] && createRes[0].id){
            //props.OnHide(true);
        }
        props.OnHide(true);
        //let createRes = await createAiStyle(input);

    };

    return (
        <Popup
            OnHide={() => {
                props.OnHide(true);
            }}
        >
            <div className="p-5 ">
                <h3>Create Nerf Voice</h3>
                <div className="">
                    <FormInput
                        label={`Voice name :`}
                        placeholder={`Your voice name`}
                        value={nerfVoice.name}
                        onChange={(e) => {
                            nerfVoice.name = e.target.value;
                            setNerfVoice({ ...nerfVoice });
                        }}
                        required={true}
                    />

                    { nerfVoice.mp3 !=='' && <div className="mb-3 note">{nerfVoice.mp3}</div>}

                    <FormInput
                        label={`Select audio to upload`}
                        placeholder={`Select mp3 to upload`}
                        value={nerfVoice.mp3}
                        type={"file"}
                        OnSuccess={(res) => {
                            nerfVoice.mp3 = `${res.filepaths3}`;
                            setNerfVoice({ ...nerfVoice });
                        }}
                        required={true}
                    />
                    
                    <Button disabled={(nerfVoice.name ==='' || nerfVoice.mp3 ==='')? true : false} onClick={() => {handleCreateVoice()}}> Create Voice</Button>
                </div>
            </div>
        </Popup>
    );
};

export default ModalCharacterVoice;
