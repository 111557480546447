import React, { useEffect, useRef, useState } from "react";
import VideoSilent from "../video-player/video-silent";
import { groupVideosByDirectory, isJsonString } from "../utils";
import { getStoredUser } from "../auth/auth";
import "./webrtc.css";
import { useParams, useSearchParams } from "react-router-dom";

const WebRTC = (props) => {
   //let wsurl = `wss://54.89.24.57.traefik.me/ws`;WORKING

    const testbotId = '77d3164e-79fc-4c46-b23f-d49249df0f19' // JUST FOR TESTING GENEFACE
    const sessionId = "2aeac2e1-2a57-4775-921d-c2d40657cbab" // JUST FOR TESTING GENEFACE
    let wsurl = `wss://54.242.236.130.nip.io/ws`;
   
    const [searchParams, setSearchParams] = useSearchParams();
    const [webrtcText, setWebrtcText] = useState({ input: "", sent: true });
    const [currentSession, setCurrentSession] = useState();

    //const webrtcText = useRef({ input: "", sent: true });

    const { botId, silence } = props;

   

    let pc = useRef();
    const [pcStatus, setPcStatus] = useState("disconnected");
    let ws = useRef();
    let wstatus = useRef();
    const [show, setShow] = useState(true);
    let videoRef = useRef();

    const [vari, setVari] = useState(0);
    const [varData, setVarData] = useState([]);
    // let msgText = useRef()

    useEffect(() => {
        console.log("PARAMS ws:", searchParams.get("ws"));
        if (searchParams.get("ws")) {
            wsurl = searchParams.get("ws");
        }
    }, []);

    useEffect(() => {
        if (silence && isJsonString(silence)) {
            let sparsed = JSON.parse(silence);
            let grouped = groupVideosByDirectory(sparsed);
            setVarData([...grouped]);
        }
    }, [silence]);

    useEffect(() => {
        if (botId) {
            //WS
            handleWS(botId);
            // Cleanup function
            return () => {
                if (!wstatus.current) {
                    pc.current.close();
                    ws.current.close();

                    props.OnClose({ state: true });
                }
            };
        }
    }, [botId]);

    useEffect(() => {
        console.log("wstatus.current :: ", wstatus.current);
    }, [wstatus.current]);

    useEffect(() => {
        if (!currentSession && !wstatus.current) {
            pc.current.close();
            ws.current.close();

            videoRef.current.stop();
            videoRef.current.src = "";
            videoRef.current.getTracks().forEach((track) => {
                track.stop();
            });
            props.OnClose({ state: true });
        }
    }, [currentSession]);

    useEffect(() => {
        console.log("------> ", currentSession?.id, props.currentSession?.id);
        if (props.currentSession && props.currentSession.id !== currentSession?.id) {
            if (pc.current && ws.current) {
                pc.current.close();
                ws.current.close();
            }
            
            // if(videoRef.current){
            //     videoRef.current.stop();
            //     videoRef.current.src = "";
            //     videoRef.current.getTracks().forEach((track) => {
            //         track.stop();
            //     });
            // }
            props.OnClose({ state: true });
        }

        setCurrentSession(props.currentSession);
    }, [props.currentSession]);

    useEffect(() => {
        console.log(`pc ${pcStatus} : ${webrtcText.input} ,${webrtcText.sent}`);
        if (pcStatus === "connected" && webrtcText.input !== "" && webrtcText.sent === false) {
            handleText(webrtcText.input);
        }
    }, [pcStatus, webrtcText]);

    useEffect(() => {
        console.log(props.webrtcText);
        webrtcText.input = props.webrtcText.input;
        webrtcText.sent = false;

        setWebrtcText({ ...webrtcText });
        handleText(webrtcText.input);
    }, [props.webrtcText]);

    const handleWS = (botId) => {
        console.log("ws url is: ", wsurl);
        ws.current = new WebSocket(`${wsurl}/${botId}${currentSession?.audioonly === "true" ? ".audioonly" : ""}`);
        wstatus.current = "connecting";
        ws.current.onmessage = function (evt) {
            let msg = JSON.parse(evt.data);
            console.log("WEBSOCKET MSG :: ", msg);
            if (!msg) {
                return console.log("failed to parse msg");
            }
            switch (msg.event) {
                case "answer":
                    let answer = JSON.parse(msg.data);
                    if (!answer) {
                        return console.log("failed to parse answer");
                    }
                    // console.log("ANSWER: " + answer);
                    pc.current.setRemoteDescription(answer);
                    return;

                case "render":
                    if (msg.data === "on") {
                        console.log(`RENDER ON ${Date.now()}`);
                        setShow(true);
                    } else {
                        console.log(`RENDER OFF ${Date.now()}`);
                        setShow(false);
                    }
                    return;

                case "response":
                    console.log(`RESPONSE : ${Date.now()}`, msg);
                    // if(!msg.chatid){
                    //     msgText.current = msgText.current + msg.data
                    // }else{
                    //     msgText.current = msgText.current + msg.data
                    // }
                    props.OnResponse(msg);

                    return;

                default:
            }
        };
        ws.current.onopen = function (evt) {
            wstatus.current = "open";
            handlePC();
        };
        ws.current.onerror = function (evt) {
            wstatus.current = "error";
            console.log("ERROR: " + evt.data);

            if (botId) {
                const timeout = setTimeout(() => {
                    handleWS(botId);
                    clearTimeout(timeout);
                }, 1000);
            }
        };
        ws.current.onclose = function (evt) {
            wstatus.current = "close";
            console.log("Websocket has closed");
        };
    };

    const handlePC = () => {
        if (wstatus.current === "open" && videoRef.current) {
            //PC
            pc.current = new RTCPeerConnection();
            const log = (msg) => {
                props.OnWebrtcStatus(msg);
                console.log("webrtc :: ", msg);
                setPcStatus(msg);
            };

            let inboundStream = new MediaStream();
            videoRef.current.srcObject = inboundStream;

            pc.current.ontrack = function (event) {
                // console.log("pc.current.ontrack :: ", event);
                inboundStream.addTrack(event.track);
                videoRef.current
                    .play()
                    .then(() => {
                        console.log("playing webrtc");
                    })
                    .catch((error) => {
                        console.log(`ERROR  playing webrtc ::`, error);
                    }); // anyway, audio plays by default, no idea how to disable this
            };
            pc.current.oniceconnectionstatechange = (e) => log(pc.current.iceConnectionState);

            pc.current.addTransceiver("video", {
                direction: "sendrecv",
            });

            pc.current.addTransceiver("audio", {
                direction: "sendrecv",
            });

            pc.current
                .createOffer()
                .then((offer) => {
                    pc.current.setLocalDescription(offer);
                    ws.current.send(
                        JSON.stringify({
                            event: "candidate",
                            data: JSON.stringify(offer),
                        })
                    );
                })

                .catch(log);
        }
    };

    const handleText = (text) => {
        if (!pc.current && !ws.current && !getStoredUser()?.id && !getStoredUser()?.auth && !currentSession && text && text === "") return;
        if (wstatus.current === "open") {
            const input = {
                Params: {
                    userid: getStoredUser()?.id,
                    auth: getStoredUser()?.auth,
                    sys: "ai",
                    act: "chat",
                    pagesize: "100",
                    pagenumber: "1",
                    llm: props.model.llm,
                },
                Values: {
                    id: currentSession.id,
                    
                    prompt: text,
                },
            };

            console.log(`sending WEBRTC TEXT ${Date.now()} :: ${text}`);
            props.OnSent(true);
            ws.current.send(
                JSON.stringify({
                    event: "text",
                    data: JSON.stringify(input),
                    //data: text,
                })
            );
            //webrtcText.current = { input: "", sent: true }
            setWebrtcText({ input: "", sent: true });
        }
    };

    return (
        <div className="video-player-wrapper">
            <div className="video-player-players relative">
                {varData.length > 0 && (
                    <div className={`silent`}>
                        <VideoSilent vdata={varData[vari]} />
                    </div>
                )}
                <div className={`transition-all ${show ? `opacity-100` : `opacity-0`}`} id="remoteVideos">
                    <video ref={videoRef} height={500} playsInline />
                </div>
            </div>
        </div>
    );
};

export default WebRTC;
