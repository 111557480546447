import React, { useRef, useEffect } from "react";
import WaveSurfer from "wavesurfer.js";

const AudioWaveform = ({ audioUrl }) => {
    const waveformRef = useRef(null);
    useEffect(() => {
        const wavesurfer = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: "#332668",
            progressColor: "#9147CA",
            cursorColor: "NAN",
            barWidth: 2,
            barGap: 4,
            barHeight: 10,
            backend: "MediaElement",
            height: 30,
            width:"100%",
            autoplay: true,
        });

        // Load the audio file
        wavesurfer.load(audioUrl);

        wavesurfer.on("interaction", () => {
            wavesurfer.play();
        });

        return () => wavesurfer.destroy();
    }, [audioUrl]);

    return <div ref={waveformRef} />;
};

export default AudioWaveform;




// import React, { useRef, useEffect } from 'react';
// import WaveSurfer from 'wavesurfer.js';
// import RecordPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.record.min.js';
// import 'wavesurfer.js/dist/wavesurfer.css';

// const AudioWaveform = ({ audioStream }) => {
//   const waveformRef = useRef(null);
//   const wavesurfer = useRef(null);

//   useEffect(() => {
//     wavesurfer.current = WaveSurfer.create({
//       container: waveformRef.current,
//       waveColor: 'violet',
//       progressColor: 'purple',
//       cursorColor: 'navy',
//       barWidth: 2,
//       barHeight: 2,
//       plugins: [
//         RecordPlugin.create({
//           audioChannels: 1, // Change this based on your requirement
//           audioScriptProcessor: {
//             bufferSize: 4096,
//             numberOfInputChannels: 1,
//             numberOfOutputChannels: 1,
//           },
//         }),
//       ],
//     });

//     if (audioStream) {
//       wavesurfer.current.microphone.on('deviceReady', () => {
//         wavesurfer.current.microphone.start();
//         wavesurfer.current.microphone.on('audioprocess', () => {
//           wavesurfer.current.fireEvent('redraw');
//         });
//       });
//       wavesurfer.current.microphone.init(audioStream);
//     }

//     return () => {
//       if (wavesurfer.current) {
//         wavesurfer.current.microphone.stop();
//         wavesurfer.current.destroy();
//       }
//     };
//   }, [audioStream]);

//   return <div ref={waveformRef} />;
// };

// export default AudioWaveform;

