import React, { useEffect, useState, useRef } from "react";

const VideoSilent = (props) => {
    // const dir = "https://squadz-dev-bucket-02.s3.amazonaws.com/4b07b6f7-40fc-4521-b6a6-ec4d986f0369_3";
    // const vars = parseInt(dir.split(_)[1]);

    const [silentVids, setSilentVids] = useState([]);
    let silentVidsRef = useRef([]);
    const [silentCurr, setSilentCurr] = useState({ index: 0 });

    useEffect(() => {
        let theSilentVids = [];
        for (let v = 0; v < props.vdata.clips; v++) {
            let vid = ("0000" + v).slice(-4);
            // let vurl = `${process.env.PUBLIC_URL}/mvids/${vid}s.mp4`;

            let vurl = `${props.vdata.url}${vid}.mp4`;
            let newvid = {
                received: Date.now(),
                video: vurl,
            };
            theSilentVids.push(newvid);
        }
        // for (let v = 0; v < 3; v++) {
        //     let vid = ("0000" + v).slice(-4);
        //     let vurl = `${process.env.PUBLIC_URL}/svids/${vid}s.mp4`;
        //     let newvid = {
        //         received: Date.now(),
        //         video: vurl,
        //     };
        //     theSilentVids.push(newvid);
        // }
        setSilentVids([...theSilentVids]);

        // setSilentCurr({ index: 0 });
        // silentVidsRef.current = [];
    }, [props.vdata]);

    // useEffect(() => {
    //     if (props.showVoice) {
    //         setSilentCurr({ index: 0 });
    //     }
    // }, [props.showVoice]);

    useEffect(() => {
        let sVidElem = silentVidsRef.current.find((vid) => parseInt(vid.dataset.index) === silentCurr.index);
        if (sVidElem) {
            sVidElem
                .play()
                .then(() => {
                    //  console.log(`SILENT PLAYING :: ${sVidElem.src} `);\
                    // if (props.OnLoaded && silentCurr.index === 0) {
                    //     props.OnLoaded(true);
                    // }
                })
                .catch((error) => {
                    console.log(`SILENT ERROR :: ${sVidElem.src} `, error.message);

                    //  gotoNext();
                });

            const onEnded = () => {
                sVidElem.pause();
                sVidElem.currentTime = 0;

                if (silentCurr.index === silentVids.length - 1) {
                    silentCurr.index = 0;
                    setSilentCurr({ ...silentCurr });
                } else {
                    silentCurr.index = silentCurr.index + 1;
                    setSilentCurr({ ...silentCurr });
                }
            };

            sVidElem.addEventListener("ended", onEnded);

            // sVidElem.onended = (e) => {
            //     // console.log("SILENT ENDED");
            //     {
            //         sVidElem.pause();
            //         sVidElem.currentTime = 0;

            //         if (silentCurr.index === silentVids.length - 1) {
            //             silentCurr.index = 0;
            //             setSilentCurr({ ...silentCurr });
            //         } else {
            //             silentCurr.index = silentCurr.index + 1;
            //             setSilentCurr({ ...silentCurr });
            //         }
            //     }
            // };

            // if (!props.showVoice) {
            //     sVidElem.removeEventListener("ended", onEnded);
            // }

            return () => {
                sVidElem.removeEventListener("ended", onEnded);
            };
        }
    }, [silentCurr, silentVidsRef, silentVids]);

    return (
        <>
            {silentVids.map((svid, s) => (
                <video
                    key={s}
                    // className={`video-player`}
                    className={`s-${s} video-player-${s === silentCurr.index ? "current" : "sub"}`}
                    data-index={s}
                    loop={false}
                    src={svid.video}
                    autoPlay={false}
                    muted={true}
                    playsInline
                    ref={(vidref) => {
                        if (vidref) {
                            let findvidref = silentVidsRef.current.find((vid) => vid.dataset.index === vidref.dataset.index);
                            if (!findvidref) {
                                silentVidsRef.current = [...silentVidsRef.current, vidref];
                            }
                        }
                    }}
                />
            ))}
        </>
    );
};

export default VideoSilent;
