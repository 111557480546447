import { useEffect, useState } from "react";
import VideoQueuer from "src/components/video-player/video-queuer";

import { usePubNub } from "pubnub-react";
import { epochToDate } from "src/components/utils";
import "./tester.css";

const TesterBot = () => {
    const [botid, setBotid] = useState("");
    const [msgToPlay, setMsgToPlay] = useState("");
    const pubnub = usePubNub();


    // useEffect(()=>{
    //     console.log('subbing to :',botid)
    // },[botid])

    const go = () => {
        if(pubnub && botid !== ''){
            console.log('subbing to :',botid)
            const listenerParams = { message: handlePubnubMessage };
            pubnub.addListener(listenerParams);
            pubnub.subscribe({ channels: [botid]});
            return () => {
                pubnub.unsubscribe({ channels: [botid]});
                pubnub.removeListener(listenerParams);
            };
        }
    };



    const handlePubnubMessage = (msg) => {
        if (msg.indexOf(`"cache"`) !== -1) {
            let parsedMsg = JSON.parse(msg);
            const received = Date.now();
            console.log(`video chunk received ${epochToDate(received)}:`, parsedMsg[0].cache);
            setMsgToPlay({
                received,
                video: parsedMsg[0].cache,
            });
        }
    };

    
    return (
        <>
           
            <div className="tester-page">
                <div className="content">
                <div className="vid-container">
                    <VideoQueuer toQueue={msgToPlay} />
                </div>
                <div className="flex">
                    <input
                    
                        val={botid}
                        type="text"
                        onChange={(e) => {
                            setBotid(e.target.value);
                        }}
                    />
                    <button className="button button-small" onClick={()=>{
                        go();
                    }}>go</button>
                </div>
               
                </div>
            </div>
        </>
    );
};

export default TesterBot;
