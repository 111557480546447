import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./menu.css";
import { listAiStyle } from "src/apis/aistyle";
import iconDropdown from "src/assets/images/arrow-down.svg";

import { discover, marketMenu } from "src/data/menu";
import iconArrow from "src/assets/images/icon-arrow-circ.svg";
import iconMenuArrow from "src/assets/images/icon-menu-arrow.svg";
import iconArrowDark from "src/assets/images/icon-arrow-circ-dark.svg";
import Iicon from "../iicon/iicon";
import Popup from "src/layouts/popup/popup";
import graphicBuilder from "src/assets/images/graphic-builder.svg";
import graphicConfig from "src/assets/images/graphic-config.svg";

const Menu = (props) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [theMenu, setTheMenu] = useState();

    let mainMenuRef = useRef();
    const [currentTheme, setCurrentTheme] = useState();
    const [categories, setCategories] = useState([]);
    const [showCreate, setShowCreate] = useState(false);

    useEffect(() => {
        getCurrentTheme();
        populateCategories();
        return () => {
            setCategories([]);
        };
    }, []);

    const populateCategories = async () => {
        let catRes = await listAiStyle("category", true);
        if (catRes) {
            let prepCats = catRes.map((cat) => {
                let catObj = {
                    label: cat.name,
                    path: `/category/${cat.name}`,
                    icon: iconArrow,
                    iconDark: iconArrowDark,
                };

                return catObj;
            });
            setCategories(prepCats);
        }
    };

    const hideMenu = (e) => {
        mainMenuRef.current.classList.remove("menu-mobile-show");
    };

    const getCurrentTheme = () => {
        const themeName = "AVATARZ_theme";
        const currentTheme = localStorage.getItem(themeName);

        if (currentTheme && currentTheme !== "") {
            setCurrentTheme(currentTheme);
            localStorage.setItem(themeName, currentTheme);
        } else {
            setCurrentTheme("light");
            localStorage.setItem(themeName, "light");
        }
    };

    const MenuItems = (props) => {
        return (
            <div className={`menu-section ${props.className ? props.className : ""}`}>
                {props.label && <div className="label mb-2">{props.label}</div>}
                <div className="menu-section-content">
                    {props.menuItems.map((m, mindex) => (
                        <div
                            key={mindex}
                            className={`menu-item 
                        ${m.children ? `menu-item-has-children` : ``} 
                        ${m.path === pathname ? `menu-item-selected` : ``}   
                        `}
                        >
                            <div className="menu-item-label">
                                {/* {currentTheme === "light" && m.iconDark && <img className="icon" src={m.iconDark} />}
                            {currentTheme === "dark" && m.icon && <img className="icon" src={m.icon} />} */}
                                {/* <img className="icon" src={iconMenuArrow} /> */}
                                {m.icon && <img className="icon" src={m.icon} />}

                                <a
                                    href={m.path}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (m.path) {
                                            navigate(m.path);
                                        }
                                    }}
                                >
                                    {m.label}
                                </a>
                                {m.children && m.children.length > 0 && <img className="menu-item-icon-dropdown" src={iconDropdown} />}
                            </div>

                            {m.children && m.children.length > 0 && (
                                <div className="menu-item-children">
                                    {m.children.map((mChild, mChildIndex) => (
                                        <div key={mChildIndex} className="menu-item-label">
                                            {mChild.label}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <>
            {showCreate && (
                <Popup
                    className={"w-1/2"}
                    fixed={true}
                    size={"med"}
                    OnHide={() => {
                        setShowCreate(false);
                    }}
                >
                    <div className="flex flex-col gap-10 items-center p-10 md:p-20">
                        <div className="text-lg font-semibold">Select on how you want to create your avatar</div>
                        <div className="flex flex-col md:flex-row gap-4 md:gap-10 text-center">
                            <div
                                className="flex flex-1 flex-col border border-gray-250 rounded-3xl items-center p-5 md:p-10 gap-4 md:gap-8 cursor-pointer hover:bg-gray-50"
                                onClick={() => {
                                    navigate("/character/create", { state: { mainTab: "Generate" } });
                                    setShowCreate(false);
                                }}
                            >
                                <div className="font-semibold text-base">Builder</div>
                                <div className="max-h-24  md:max-h-60 flex-1 aspect-square">
                                    <img src={graphicBuilder} />
                                </div>
                                <div className="flex-0 opacity-60">Let our chat builder AI help you build your avatar.</div>
                            </div>

                            <div
                                className="flex flex-1 flex-col border border-gray-250 rounded-3xl items-center p-5 md:p-10 gap-4 md:gap-8 cursor-pointer hover:bg-gray-50"
                                onClick={() => {
                                    navigate("/character/create", { state: { mainTab: "Configure" } });
                                    setShowCreate(false);
                                }}
                            >
                                <div className="font-semibold  text-base">Configure</div>
                                <div className="max-h-24 md:max-h-60 flex-1 aspect-square">
                                    <img src={graphicConfig} />
                                </div>
                                <div className="flex-0 opacity-60">Manually configure your avatar according to your preference.</div>
                            </div>
                        </div>
                    </div>
                </Popup>
            )}

            <div
                ref={mainMenuRef}
                id={`main-menu`}
                className={`menu-container`}
                onClick={() => {
                    hideMenu();
                }}
            >
                <div className="menu">
                    {props.noHeader && (
                        <div
                            className="logo px-5 pt-8 pb-0 text-center"
                            onClick={() => {
                                navigate("/");
                            }}
                        >
                            fanlynk
                        </div>
                    )}

                    {props.market ? (
                        <>
                            <div
                                className="logo px-5 pt-8 pb-0 text-center"
                                onClick={() => {
                                    navigate("/");
                                }}
                            >
                                fanlynk
                            </div>

                            <MenuItems menuItems={marketMenu} />
                        </>
                    ) : (
                        <>
                            <div className="p-6 mb-4">
                                <button
                                    className="button"
                                    onClick={() => {
                                        //setShowCreate(true);
                                        navigate("/character/create",{state:{mainTab:'Configure'}});
                                    }}
                                >
                                    <span>Create Avatar</span>
                                    <Iicon icon={`character-white`} className={`translate-x-5`} />
                                </button>
                            </div>
                            <MenuItems label={"Main"} menuItems={discover} />
                            <MenuItems label={"Categories"} menuItems={categories} />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Menu;
