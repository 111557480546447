import { useEffect, useState } from "react";
import { getUser, loginUser, updateUser } from "src/apis/user";
import { useNavigate } from "react-router-dom";

import "./login.css";

import iconBot from "src/assets/images/icon-bot.svg";
import iconMan from "src/assets/images/icon-man.svg";

import Header from "src/layouts/header/header";
import Main from "src/layouts/main/main";
import Menu from "src/components/menu/menu";

import FormInput from "src/components/form/input";
import Button from "src/components/ui/button";
import BgFloating from "src/layouts/bg-floating";
import Preloader from "src/components/preloader/preloader";

const Login = (props) => {
    const navigate = useNavigate();
    const [authState, setAuthState] = useState("login");
    const [number, setNumber] = useState("");
    const regNum = /^[0-9\b]+$/;
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
       
    });
    
    useEffect(() => {
        setAuthState("login");
    }, []);

    const signIn = async () => {
        setFetching(true);

        try {
            let loginRes = await loginUser(number);

            console.log(`${number} :: `, loginRes);
            if (loginRes && loginRes.auth) {
                //localStorage.setItem("AVATARZ_auth", loginRes.auth);
                localStorage.setItem("AVATARZ_auth", "xxxx");
            }

            if (loginRes && loginRes.userid) {
                try {
                    let theUser = await getUser({ id: loginRes.userid }, { userid: loginRes.userid, auth: loginRes.auth });
                    console.log(theUser);
                    if (theUser.error) {
                        navigate("/login");
                        //handle error here
                    } else {
                        localStorage.setItem("AVATARZ_user", JSON.stringify(theUser));
                        navigate(-1);
                    }
                } catch (error) {
                    setFetching(false);
                    console.log(`getUser error :` + error);
                }
            } else {
                setFetching(false);
            }
        } catch (error) {
            setFetching(false);
            console.log(`login error :` + error);
        }
    };

  

    return (
        <>
            {fetching && <Preloader type={"full"} />}

            <Main noHeader={true} noSidebar={true}>
                <div className={`login-box login-box-${authState}`}>
                    <div className="login-content flex-1 col p-10">
                        {/* <div className={`logo logo-main`}>avatarz</div> */}

                        {authState === "login" && (
                            <>
                                <div className="login-form">
                                    <div
                                        className="logo mb-10"
                                        onClick={() => {
                                            navigate("/");
                                        }}
                                    >
                                        fanlynk
                                    </div>

                                    <h3>Login to your account</h3>
                                    <p className="opacity-25 mb-10">Enjoy exclusive features and many more. </p>

                                    <div className="form-item mb-5">
                                        <div className="form-item-label">Phone Number</div>
                                        <div className="form-item-input">
                                            <input
                                                type="text"
                                                style={{ letterSpacing: "5px", textAlign: "center" }}
                                                placeholder="eg. +18888881234"
                                                onChange={(e) => {
                                                    setNumber(e.target.value);
                                                }}
                                                onKeyDown={async (e) => {
                                                    if (e.key === "Enter" && e.target.value !== "") {
                                                        signIn();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <Button
                                        onClick={() => {
                                            signIn();
                                        }}
                                    >
                                        Login
                                    </Button>
                                </div>
                                {/* <div className="login-extra">New to avatarz? <span className="link ml-3" onClick={()=>{setAuthState('signup')}}>Sign up</span></div> */}
                                <div className="login-extra mt-5">Enjoy exclusive features and many more.</div>
                            </>
                        )}

                        {authState === "signup" && (
                            <>
                                <div className="login-form">
                                    <h3>Let's get started</h3>
                                    <p className="opacity-25 mb-4">Sign to enjoy exclusive features and many more. </p>

                                    <FormInput label={`Email :`} placeholder={`Enter your Email`} />
                                    <FormInput label={`Password :`} placeholder={`Enter your Password`} />
                                    <FormInput label={`Confirm Password :`} placeholder={`Confirm your Password`} />
                                    <Button
                                        onClick={() => {
                                            setAuthState("login");
                                        }}
                                    >
                                        Signup
                                    </Button>
                                </div>
                                <div className="login-extra">
                                    Already have an account?{" "}
                                    <span
                                        className="link ml-3"
                                        onClick={() => {
                                            setAuthState("login");
                                        }}
                                    >
                                        Log in
                                    </span>
                                </div>
                            </>
                        )}
                    </div>

                    {/* <div className="login-graphic flex-1 col">
                        <div className="card-avatar">
                            <img src={iconBot} className="default-img" />
                        </div>
                    </div> */}
                </div>
            </Main>
        </>
    );
};

export default Login;
