

import {useNavigate} from "react-router-dom";

import iconFacebook from 'src/assets/images/icon-facebook.svg'
import iconTwitter from 'src/assets/images/icon-twitter.svg'
import iconInstagram from 'src/assets/images/icon-instagram.svg'

import './footer.css'

import Menu from 'src/components/menu/menu';
import SubscribeBox from 'src/components/subscribe-box';

const Footer = (props) => {
    const navigate = useNavigate();

    return (  
        <>
        <div className="footer container-fluid">

            <div className='footer-main'>

                <div className='pe-5'>
                    <div className={`logo logo-main`} onClick={()=>{navigate('/')}}>avatarz</div>

                </div>

                <div className='flex-1 pe-5'>
                    <Menu footer={true} />
                </div>
                
               <SubscribeBox />
               
            </div>

            <div className='footer-extra'>
                <div className='opacity-25 flex-1'>
                    © 2017-2023, Mobworx
                </div>


                <div className='sm'>
                    <img src={iconFacebook} className='mx-2' />
                    <img src={iconTwitter} className='mx-3' />
                    <img src={iconInstagram} className='mx-2' />
                </div>
            </div>
          
        </div>
        
        </>
    );
}
 
export default Footer;