import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";

// import "bootstrap/dist/css/bootstrap.css";
import "./index.css";


import App from "src/App";

const pubnub = new PubNub({
    publishKey: "pub-c-9fdf1134-3fcc-4c5b-8260-f5456eb9a70c",
    subscribeKey: "sub-c-7c52a593-7b38-4f0f-b724-4b514f28abac",
    uuid: "myUniqueUUID",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <PubNubProvider client={pubnub}>
            <App />
        </PubNubProvider>
    </BrowserRouter>
);
