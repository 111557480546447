import axios from "axios";
import endpoint from "./endpoints";
import { getStoredUser } from "src/components/auth/auth";

import { handleResponse } from "./response";
const getPublicUser = () => {
    // let storedUser = localStorage.getItem("AVATARZ_user")
    // let currentUser =(storedUser && storedUser !== 'undefined')? JSON.parse(storedUser) : null;
    // let currentAuth = localStorage.getItem("AVATARZ_auth");
    // return {auth: currentAuth, user: currentUser};

    return {
        id: "11111111-1111-1111-1111-111111111111",
        auth: "xxxx",
    };
};

export const getAiStyle = async (input) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "getaistyle",
                    pagesize: "1",
                    pagenumber: "1",
                },
                Where: input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const listAiStyle = async (mtype, forPublic) => {
    
        try {
            let query = {
                Params: {
                    userid: forPublic ? getPublicUser().id : getStoredUser().id,
                    auth: forPublic ? getPublicUser().auth : getStoredUser().auth,
                    sys: "ai",
                    act: "getaistyle",
                    pagesize: "30",
                    pagenumber: "1",
                },
                Where: {
                    mtype: mtype,
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    
};

export const createAiStyle = async (input) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let theInput = input;
            theInput.userid = getStoredUser().id;

            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "addaistyle",
                    pagesize: "1",
                    pagenumber: "1",
                },
                Values: theInput,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};
