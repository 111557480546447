import { useState, useEffect, useRef } from "react";
import "./grid.css";

const Grid = (props) => {
    let bottomRef = useRef();
    let scrollableRef = useRef();
    const [isBottom, setIsBottom] = useState({ value: false });

    useEffect(() => {
        if (isBottom) {
            props.OnBottom(true);
        }
    }, [isBottom]);
    // useEffect(() => {
    //     const handleScroll = () => {

    //         if (scrollableRef.current.scrollTop + scrollableRef.current.clientHeight >= scrollableRef.current.scrollHeight) {
    //             const timeout = setTimeout(()=>{
    //                 isBottom.value = true
    //                 setIsBottom({...isBottom})
    //                 clearTimeout(timeout)
    //             },1000)

    //         }
    //     };

    //     scrollableRef.current.addEventListener("scroll", handleScroll);

    //     return () => {
    //         if (scrollableRef && scrollableRef.current) {
    //             scrollableRef.current.removeEventListener("scroll", handleScroll);
    //         }
    //     };
    // }, [scrollableRef]);

    const checkVisibility = () => {
        if (!bottomRef.current) return;

        const { top, bottom } = bottomRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        if (top >= 0 && bottom <= viewportHeight) {
            setIsBottom(true);
        } else {
            setIsBottom(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", checkVisibility);
        window.addEventListener("resize", checkVisibility);
        checkVisibility(); // Check visibility on initial render

        return () => {
            window.removeEventListener("scroll", checkVisibility);
            window.removeEventListener("resize", checkVisibility);
        };
    }, []);

    return (
        <>
            <div className="py-5 scrollable" ref={scrollableRef}>
                <div className="grid grid-cols-1 max-w-full gap-3 md:gap-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">{props.children}</div>
            </div>

            <div ref={bottomRef}></div>
        </>
        // <div className="grid-wrapper">
        //     <div className="grid">
        //         {props.children}
        //     </div>
        //
        //  </div>
    );
};

export default Grid;
