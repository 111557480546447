import axios from "axios";
import endpoint from "./endpoints";
import { getStoredUser } from "src/components/auth/auth";
import { handleResponse } from "./response";

export const getFirstMessageOfSession = async (chatid) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "getaihistory",
                    pagesize: "1",
                    pagenumber: "1",
                    orderby: "createdate asc",
                },
                Where: {
                    chatid: chatid,
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const getHistoryByUser = async (userid, chatid, pagenumber) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "getaihistory",
                    pagesize: "25",
                    pagenumber: pagenumber.toString(),
                    orderby: "createdate desc",
                },
                Where: {
                    chatid: chatid,
                    userid: userid,
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

/// MUTATIONS

export const setHistory = async (chatid, input) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "setaihistory",
                    pagesize: "1",
                },
                Where: {
                    id: chatid,
                },
                Values: input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};
