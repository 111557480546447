import React, { useState, useEffect, useRef } from "react";
import Hls from "hls.js";
import iconPlay from "src/assets/images/icon-play.svg";
import iconPause from "src/assets/images/icon-pause.svg";

const HLSPlayer = (props) => {
    const [manifestLoaded, setManifestLoaded] = useState(false);
    var playerRef = useRef();
    let hls;
    const [vidState, setVidState] = useState("paused");
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
        if (Hls.isSupported() && playerRef.current) {
            const video = playerRef.current;
            var config = {
                // debug:true,
                enableWorker: true,
                lowLatencyMode: props.lowLatencyMode ? props.lowLatencyMode : true,
                maxBufferLength: 2,
                liveBackBufferLength: 0,
                liveSyncDuration: 0,
                liveMaxLatencyDuration: 5,
                liveDurationInfinity: true,
                highBufferWatchdogPeriod: 1,
            };

            if (props.config) {
                config = props.config;
            }

            if (props.debug === true) {
                config.debug = true;
            }

            hls = new Hls(config);
            hls.loadSource(props.src);
            hls.attachMedia(video);
            hls.on(Hls.Events.MEDIA_ATTACHED, function () {
                if (manifestLoaded) {
                    video.play();
                }
            });

            hls.on(Hls.Events.MANIFEST_LOADED, function (data) {
                console.log("manifest loaded :: ", data);
                setManifestLoaded(true);
            });

            hls.once(Hls.Events.LEVEL_LOADED, async function (data) {
                console.log("level loaded :: ", data);
            });

            hls.on(Hls.Events.ERROR, function (event, data) {
                // console.log('hls on error :', data)

                if (props.retryTillSuccess) {
                    props.OnNetworkError(data);
                }

                if (data.fatal) {
                    switch (data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            // try to recover network error
                            //console.log('fatal network error encountered, try to recover');
                            hls.startLoad();
                            break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            //console.log('fatal media error encountered, try to recover');
                            hls.recoverMediaError();
                            break;
                        default:
                            // cannot recover
                            hls.destroy();
                            break;
                    }
                }
            });
        }

        return () => {
            hls.destroy();
        };
    }, [props.src]);

    useEffect(() => {
        if (playerRef && playerRef.current) {
            playerRef.current.addEventListener("canplaythrough", (event) => {
                setHasLoaded(true);
            });

            playerRef.current.addEventListener("playing", (event) => {
                setVidState("playing");
            });
            playerRef.current.addEventListener("pause", (event) => {
                setVidState("paused");
            });
        }
    }, [playerRef]);

    return (
        <div className="video-player-wrapper">
            <video
                className="video-hls"
                playsInline={true}
                autoPlay={true}
                controls={props.controls ? props.controls : false}
                loop={props.loop ? props.loop : false}
                muted={props.muted !== undefined ? props.muted : true}
                width="100%"
                height="100%"
                ref={playerRef}
            />

            <div className="video-wrapper-actions">
                {vidState === "playing" && (
                    <div
                        className="button-action"
                        onClick={() => {
                            playerRef.current.pause();
                        }}
                    >
                        <img src={iconPause} />
                    </div>
                )}

                {vidState === "paused" && (
                    <div
                        className="button-action"
                        onClick={() => {
                            playerRef.current.play();
                        }}
                    >
                        <img src={iconPlay} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default HLSPlayer;
