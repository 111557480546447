import Header from "src/layouts/header/header";
import Main from "src/layouts/main/main";
import { useNavigate } from "react-router-dom";

import "./free-coins.css";

import Menu from "src/components/menu/menu";
import { plans } from "src/data/plans";
import Button from "src/components/ui/button";

import iconCheckCircle from "src/assets/images/icon-check-circle.svg";
import { useEffect, useState } from "react";
import FormInput from "src/components/form/input";
import iconBot from "src/assets/images/icon-bot.svg";
import { createModel } from "src/apis/aimodels";

const FreeCoins = (props) => {
    const navigate = useNavigate();

    const [user, setUser] = useState();

    useEffect(() => {
        let storedUser = localStorage.getItem("AVATARZ_user");
        let currentUser = storedUser && storedUser !== "undefined" ? JSON.parse(storedUser) : null;
        if (currentUser && currentUser.id) {
            setUser(currentUser);
        }
    }, []);

    const handleSave = async () => {};

    return (
        <Main>
            <div className="box flex flex-1 flex-col">
                <div className="flex items-center my-1">
                    <div
                        className="navi navi-back mr-2"
                        onClick={() => {
                            navigate("/avatars");
                        }}
                    >
                        <i className="iicon iicon-nav-back"></i>
                    </div>
                    <div className="text-base mr-3 font-semibold">Get Free coins</div>
                    <div className="opacity-40">Share and use the platform to get rewarded. 100% free.</div>

                </div>

               
                <div className="panel-container">
                    <div className="panel">
                        <div className=" me-3 flex flex-col">
                            <h3 className="flex-1 font-semibold mb-5">Invite Friends</h3>
                            <div className="opacity-40">Invite people and ask them to use your invite code within 24 hours after registration </div>
                        </div>
                        <div className="flex-1 py-5">Your coins will get assigned to your account automatically.</div>
                        <Button buttonStyle={"inline"}>Claim</Button>
                    </div>

                    <div className="panel">
                        <div className=" me-3 flex flex-col">
                            <h3 className="flex-1 font-semibold mb-5">Join Discord</h3>
                            <div className="opacity-40">
                                Join our community on Discord and submit your username in the #free-coins channel. You can find your username
                            </div>
                        </div>
                        <div className="flex-1 py-5">Your coins will get assigned to your account automatically.</div>
                        <Button buttonStyle={"inline"}>Claim</Button>
                    </div>

                    <div className="panel">
                        <div className=" me-3 flex flex-col">
                            <h3 className="flex-1 font-semibold mb-5">Post a TikTok Video</h3>
                            <div className="opacity-40">Create unique Capcuts, Memes, Reviews based on GirlfriendGPT and share them on a public account. </div>
                        </div>
                        <div className="flex-1 py-5">Your coins will get assigned to your account automatically.</div>
                        <Button buttonStyle={"inline"}>Claim</Button>
                    </div>

                    <div className="panel">
                        <div className=" me-3 flex flex-col">
                            <h3 className="flex-1 font-semibold mb-5">Post on any Social Media</h3>
                            <div className="opacity-40">
                                Create unique social media posts based on GirlfriendGPT and share them to the public on reddit,twitter, facebook or instagram.{" "}
                            </div>
                        </div>
                        <div className="flex-1 py-5 font-semibold">Your coins will get assigned to your account automatically.</div>
                        <Button buttonStyle={"inline"}>Claim</Button>
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default FreeCoins;
