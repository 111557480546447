import { useEffect, useRef, useState } from "react";
import "./iicon.css";

const Iicon = (props) => {
    const [showTooltip, setShowTooltip] = useState(false);
    let iconRef = useRef();

    const handleClickOutside = (event) => {
        if (iconRef.current && !iconRef.current.contains(event.target)) {
           setShowTooltip(false)
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
            setShowTooltip(false)
        };
    }, []);

    const handleTooltip = (e) => {
        if (props.children) {
            setShowTooltip(!showTooltip);
        }
    };
    return (
        <i
            ref={iconRef}
            className={`iicon iicon-${props.icon} ${props.className}`}
            title={props.title}
            onClick={(e) => {
                handleTooltip(e);
            }}
        >
            {props.children && showTooltip && <div className="iicon-tooltip">{props.children}</div>}
        </i>
    );
};

export default Iicon;
