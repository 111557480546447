
import { useState, useEffect } from "react";
import Popup from "src/layouts/popup/popup";
import Button from "src/components/ui/button";
import FormInput from "src/components/form/input";
import { createAiStyle } from "src/apis/aistyle";

const ModalCharacterNerf = (props) => {

    const [nerfModel, setNerfModel] = useState({
        name: "",
        video: "",
    });

    const handleCreateModel = async () => {
        let input = {
            name:nerfModel.name,
            path:nerfModel.video,
            mtype:'nerf'
        }

        let createRes = await createAiStyle(input);
        console.log(createRes)
        if(createRes && createRes[0] && createRes[0].id){
            //props.OnHide(true);
        }

        props.OnHide(true);

    };

    return (
        <Popup
            OnHide={() => {
                props.OnHide(true);
            }}
        >
            <div className="p-5 ">
                <h3>Create Nerf Model</h3>
                <div className="">
                    <FormInput
                        label={`Model Name :`}
                        placeholder={`Your model name`}
                        value={nerfModel.name}
                        onChange={(e) => {
                            nerfModel.name = e.target.value;
                            setNerfModel({ ...nerfModel });
                        }}
                        required={true}
                    />

                    { nerfModel.video !=='' && <div className="mb-3 note">{nerfModel.video}</div>}

                    <FormInput
                        label={`Select video to upload`}
                        placeholder={`Select video to upload`}
                        value={nerfModel.video}
                        type={"file"}
                        OnSuccess={(res) => {
                            console.log(res)
                            nerfModel.video = `${res.filepaths3}`;
                            setNerfModel({ ...nerfModel });
                        }}
                        required={true}
                    />

                    <Button disabled={(nerfModel.name ==='' || nerfModel.video ==='')? true : false} onClick={() => {handleCreateModel()}}> Create Model</Button>
                </div>
            </div>
        </Popup>
    );
};

export default ModalCharacterNerf;
