import { useEffect, useRef } from 'react'
import Parallax from 'parallax-js'

import './bg-floating.css'

import bgBlue from 'src/assets/images/bg-blur-blue.svg'
import bgViolet from 'src/assets/images/bg-blur-violet.svg'


const BgFloating = () => {
    let bgRef = useRef()

    // useEffect(()=>{
    //     new Parallax(bgRef.current);
    // },[])
   

    return ( 
        <div className="bg-floating" ref={bgRef}>
            <div className="bg-float bg-float-blue"></div>
            <div className="bg-float bg-float-violet"></div> 
            <div className="bg-float bg-float-neuro"></div>
        </div>
     );
}
 
export default BgFloating;