import { useNavigate } from "react-router-dom";
const MarketingFooter = (props) => {
    const navigate = useNavigate();
    return (
        <div className="m-footer  pb-10 m-width mx-auto">
            <div className="flex items-center gap-10  flex-col md:flex-row">
                <div
                    className="logo flex-1"
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    fanlynk
                </div>

                <div className="flex flex-col md:flex-row flex-1 gap-3 md:gap-20 justify-end font-semibold text-center">
                    <a href="/avatars" className="link link-black">
                        Models
                    </a>
                    <a href="/avatars" className="link link-black">
                        Trending
                    </a>
                    <a href="/pricing" className="link link-black">
                        Pricing
                    </a>
                    <a href="/for-creators" className="link link-black">
                        For Creators
                    </a>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-5 text-sm mt-10 pt-4 pb-1 opacity-40 items-center m-footer-extra">
                <div> Copyright 2024. All Rights Reserved.</div>

                <div className="flex flex-1 gap-10 justify-end">
                    <div className="link">Terms</div>
                    <div className="link">Privacy</div>
                    <div className="link">Trust & Security</div>
                </div>
            </div>
        </div>
    );
};

export default MarketingFooter;
