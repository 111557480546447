import { useNavigate } from "react-router-dom";

import "./card.css";

import iconBot from "src/assets/images/icon-bot.svg";
import iconEdit from "src/assets/images/icon-edit.svg";
import iconMan from "src/assets/images/icon-man.svg";
import Button from "src/components/ui/button";
import { useEffect, useState } from "react";

import HLSPlayer from "src/components/hls-player";
import Iicon from "../iicon/iicon";

import imgPlaceholder from "src/assets/images/image-placeholder.svg";
import { getStoredUser } from "../auth/auth";

const Card = (props) => {
    const navigate = useNavigate();
    const [model, setModel] = useState();

    useEffect(() => {
        if (props.model) {
            setModel(props.model);
        }
    }, [props.model]);

    return (
        <div className={`mcard overflow-hidden relative ` + (props.category ? `card-category` : ``)}>

            {model && (
                <a
                    href={props.navigate ? props.navigate : `/chat/${model.id}`}
                    className="card-avatar w-full aspect-square"
                    onClick={(e) => {
                        e.preventDefault();
                        if (props.navigate) {
                            navigate(props.navigate);
                        } else {
                            navigate(`/chat/${model.id}`);
                        }
                    }}
                >
                    {model && model.video && model.video.indexOf(".m3u8") !== -1 ? (
                        <HLSPlayer src={model.video} />
                    ) : (
                        <img className="model-img" src={model.image === "" ? imgPlaceholder : model.image} />
                    )}

                    <div className="gradient-cover"></div>
                </a>
            )}

            {model && (
                <div className="card-header item-actions flex flex-row p-5 ">
                    <div className="item item-name flex text-sm text-white flex-1">
                        <div className="flex-1 font-semibold drop-shadow">{model.name === "" ? " - " : model.name}</div>
                    </div>
                    {model && model.userid === getStoredUser()?.id && (
                    <Iicon icon="more-white" className={"overflow-visible"}>
                        <div
                            className="link flex gap-2"
                            onClick={() => {
                                navigate(props.navigateEdit, { state: { model: props.model, mainTab: "Configure" } });
                            }}
                        >
                            <Iicon icon={"edit"} />
                            <div>Edit</div>
                        </div>
                    </Iicon>
                     )}
                </div>
            )}

            {model && (
                <div className="mcard-info flex flex-row items-end p-4 gap-4">
                    <div className="flex flex-1 flex-col relative  p-1 gap-3">
                        {model.description !== "" && (
                            <div className="item item-desc  opacity-90 leading-4 text-white drop-shadow-sm">
                                {model.description.length > 100 ? `${model.description.substring(0, 100)} ...` : model.description}
                            </div>
                        )}
                        <div className="item item-tags flex gap-4">
                            {/* {model.fast0 !== "" && <Iicon icon={"video-white"} title="Can reply with video" />}  */}
                            {model.private !== "false" && <Iicon icon={"lock-white"} title="Private" />}
                            {model.mouth !== "" && <Iicon icon={"magic-white"} title="Fine-tuned" />}

                            {model.paid !== "false" && <span className="tag">Paid</span>}
                        </div>
                    </div>

                    <a
                        href={props.navigate ? props.navigate : `/chat/${model.id}`}
                        className="transition-all bg-white p-3 rounded-3xl cursor-pointer hover:translate-x-0.5"
                        onClick={(e) => {
                            e.preventDefault();
                            if (props.navigate) {
                                navigate(props.navigate);
                            } else {
                                navigate(`/chat/${model.id}`);
                            }
                        }}
                    >
                        <Iicon icon={`nav-right`} />
                    </a>
                </div>
            )}
        </div>
    );
};

export default Card;
