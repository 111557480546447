const  TitleBar = (props) => {
    return ( 
        <div className="bg-gray rounded-2xl flex p-2 w-full stick-header items-center">
                <div className="flex flex-1 items-center">
                    {props.children}
                </div>
                <div className="page-results mobile-hide">
                    <div className="pr-4 ">
                    Showing {props.length} {props.length <= 1 ? "Avatar" : "Avatars"}.
                    </div>
                </div>
            </div>
     );
}
 
export default TitleBar;