import { ReactLenis, useLenis } from "@studio-freight/react-lenis";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import Iicon from "src/components/iicon/iicon";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Header from "src/layouts/header/header";

import "src/pages/marketing/marketing.css";
import "./for-creators.css";

import person1 from "src/assets/images/person-1.png";
import person2 from "src/assets/images/person-2.png";
import person3 from "src/assets/images/person-3.png";
import person4 from "src/assets/images/person-4.png";
import person5 from "src/assets/images/person-5.png";
import person6 from "src/assets/images/person-6.png";
import person7 from "src/assets/images/person-7.png";
import person8 from "src/assets/images/person-8.png";
import person9 from "src/assets/images/person-9.png";
import person10 from "src/assets/images/person-10.png";

import person11 from "src/assets/images/person-11.png";
import person12 from "src/assets/images/person-12.png";
import person13 from "src/assets/images/person-13.png";
import person14 from "src/assets/images/person-14.png";
import person15 from "src/assets/images/person-15.png";
import person16 from "src/assets/images/person-16.png";
import person16Clone from "src/pages/marketing/images/person-16-clone.png";

import iconClock from "src/pages/marketing/images/icon-clock.svg";
import iconDumbell from "src/pages/marketing/images/icon-dumbell.svg";
import iconBrain from "src/pages/marketing/images/icon-brain.svg";
import iconCallout from "src/pages/marketing/images/icon-callout.svg";
import iconQmark from "src/pages/marketing/images/icon-qmark.svg";
import iconId from "src/pages/marketing/images/icon-id.svg";
import iconCoin from "src/pages/marketing/images/icon-coin.svg";

import arrowDown from "src/pages/marketing/images/arrow-accordion.svg";

import iconSlideDown from "src/pages/marketing/images/icon-slide-down.svg";

import graphicChat from "src/pages/marketing/images/graphic-chat.svg";
import graphicFire from "src/pages/marketing/images/graphic-fire.svg";
import graphicMusic from "src/pages/marketing/images/graphic-music.svg";
import graphicToggle from "src/pages/marketing/images/graphic-toggle.svg";
import graphicThumb from "src/pages/marketing/images/graphic-thumb.svg";
import graphicFollowers from "src/pages/marketing/images/graphic-followers.svg";
import graphicPic from "src/pages/marketing/images/graphic-pic.svg";
import MarketingFooter from "../footer/footer";

import {
    timelineHero,
    timelineHow,
    timelineClone,
    timelineCloneMore,
    timelineGrow,
    timelineMessage,
    timelineMessageMore,
    timelineGrowMore,
    timelineFaq,
    timelineTrending,
    timelineCreate,
} from "./animation";
import Menu from "src/components/menu/menu";

const ForCreators = (props) => {
    const navigate = useNavigate();
    const main = useRef();
    useGSAP(
        () => {
            gsap.fromTo(
                ".m-header",
                { y: -50, opacity: 0, skewY: 1 },
                {
                    y: 0,
                    opacity: 1,
                    skewY: 0,
                }
            );

            timelineHero();
            timelineHow();
            timelineClone();
            timelineCloneMore();
            timelineMessage();
            timelineMessageMore();
            timelineGrow();
            timelineGrowMore();
            timelineFaq();
            timelineTrending();
            timelineCreate();
        },
        { scope: main }
    );

    const lenis = useLenis(({ velocity }) => {
        // gsap.to(".m-header", {
        //     skewY: velocity / 20,
        //     y: -Math.abs(velocity * 2),
        // });
        // gsap.to(".a-text", {
        //     skewY: velocity / 10,
        //     y: velocity * 4,
        // });
    });

    const persons = [
        { img: person1, name: "Alice Johnson", msg: "Hello, Rex! It's a pleasure to see you today." },
        { img: person2, name: "Diana Smith", msg: "Hi there, Bob! I hope you're having a fantastic day." },
        { img: person3, name: "Charlie Williams", msg: "Greetings, Jane! Wishing you a wonderful and productive day ahead." },

        { img: person4, name: "Eve Davis", msg: "Nice to see you, Peter! I trust you're enjoying the moment." },

        { img: person5, name: "Bob Anderson", msg: "Hey, Diana! Your presence brightens up the room. Welcome!" },
        { img: person6, name: "Grace Taylor", msg: "Salutations, Quinn! May your day be filled with joy and success." },
        { img: person7, name: "Frank Miller", msg: "Howdy, Greg! Your positive energy is contagious. Keep shining!" },

        { img: person8, name: "Shiela Miller", msg: "Hello, Henry! It's a pleasure to have you here. Let's make it a great day!" },
        { img: person9, name: "Ivy Wilson", msg: "Hey, Joshua! Your presence adds so much warmth. Welcome to our gathering." },
        { img: person10, name: "Jack Robinson", msg: "Hi, Carl! Wishing you a day filled with exciting opportunities and happiness." },
    ];

    return (
        <ReactLenis root>
            <div className="md:hidden">
                <Menu market={true} />
            </div>
            <div className="pgm mx-auto pgm-creators" ref={main}>
                <div className="bg-floating opacity-40">
                    <div className="bg-violet"></div>
                    <div className="bg-torq"></div>
                </div>
                <div className="m-header">
                    <div className="box box-60 m-width mx-auto flex items-center mt-5 p-3 px-10 rounded-2xl overflow-hidden">
                        <Header menu={`market`} />
                    </div>
                </div>

                <div className="m-section-floater">
                    <div className="m-width mx-auto relative scale-75">
                        <div className="box box1 overflow-hidden hidden md:block">
                            <img src={person16} className="img-cover" />
                        </div>
                        <div className="box box2 overflow-hidden  hidden md:block">
                            <img src={person15} className="img-cover" />
                        </div>
                        <div className="box box3 overflow-hidden  hidden md:block">
                            <img src={person12} className="img-cover" />
                        </div>
                        <img src={graphicChat} className="graphic1" />
                        <img src={graphicMusic} className="graphic2 " />
                        <img src={graphicFire} className="graphic3 hidden md:block" />
                    </div>
                </div>

                {/* ---------------------------------------- SECTION HERO */}

                <div className="m-section m-section-hero m-width mx-auto text-center items-center w-full mb-0">
                    <div className="a-text text-4xl leading-tight md:text-7xl md:leading-tight  p-5 font-alt-extrabold">
                        <div className="aphrase">
                            <div className="aword">
                                <span className="rounded-3xl px-4" style={{ background: "#FFB3CA" }}>
                                    Earn
                                </span>{" "}
                                &amp; Connect
                            </div>
                        </div>
                        <div className="aphrase px-5">
                            <div className="aword">with your most loyal fans</div>
                        </div>
                        <div className="aphrase">
                            <div className="aword">
                                personalized{" "}
                                <span className="rounded-3xl px-4" style={{ background: "#62E1BB" }}>
                                    videos.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="b-text">
                        <div className="opacity-40 my-10 text-base font-alt-semibold">Record personalized videos from your phone and start earning.</div>
                        <div className="flex gap-10 items-center justify-center">
                            <a
                                href="/join-creators"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/join-creators");
                                }}
                                className="button button-inline text-lg font-alt-bold px-10"
                            >
                                Start for free
                            </a>
                            <div
                                className="link text-lg font-alt-bold link-black learn-more"
                                onClick={() => {
                                    lenis.scrollTo(".m-section-how");
                                }}
                            >
                                Learn more
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---------------------------------------- SECTION HOW IT WORKS */}
                <div className="m-section m-section-how m-width mx-auto text-center flex flex-col items-center justify-center mb-0">
                    <div className="flex flex-col flex-1">
                        <div className="flex flex-col flex-1 justify-center">
                            <div className="a-text font-alt-extrabold text-5xl leading-tight md:text-7xl md:leading-tight">
                                How it
                                <span className="rounded-3xl px-5 mx-4" style={{ background: "#C4A8FF" }}>
                                    works.
                                </span>
                            </div>
                            <div className="opacity-40 my-10 text-base font-alt-semibold p-5">
                                Join 30,000 creators who have sold over $500M in tickets, merch, and music directly to their fans.
                            </div>
                        </div>
                        <div
                            className="flex flex-col items-center pb-10 link "
                            onClick={() => {
                                lenis.scrollTo(".m-section-clone");
                            }}
                        >
                            <img src={iconSlideDown} />
                        </div>
                    </div>
                </div>

                {/* ---------------------------------------- SECTION CLONE */}
                <div className="m-section m-section-clone m-width mx-auto flex flex-col justify-center mb-0 py-40">
                    <div className="flex flex-col md:flex-row gap-5 md:gap-20">
                        <div className="flex-1 flex flex-col justify-center gap-5">
                            <div className="a-text font-alt-extrabold text-4xl leading-tight md:text-6xl md:leading-tight text-center md:text-left">
                                Create
                                <div>
                                    your
                                    <span className="rounded-3xl px-4 ml-2" style={{ background: "#FFB3CA" }}>
                                        avatar.
                                    </span>
                                </div>
                            </div>
                            <div className="opacity-40  text-base font-alt-semibold  md:pr-10 p-5 md:p-0 text-center md:text-left">
                                Build the AI version of you to scale your expertise and availability, infinitely.
                            </div>
                            <div className="flex md:pt-20 flex-row gap-6 items-center p-2">
                                <a
                                    href="/join-creators"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/join-creators");
                                    }}
                                    className="button button-inline text-lg font-alt-bold px-10"
                                >
                                    Start for free
                                </a>
                                <a href="/avatars" className="link text-lg font-alt-bold link-black">
                                    Browse avatars
                                </a>
                            </div>
                        </div>

                        <div className="flex-1 relative scale-75 md:scale-100">
                            <div className="box box1  overflow-hidden">
                                <img src={person16} className="img-cover" />
                            </div>
                            <div className="box box2  overflow-hidden">
                                <img src={person16Clone} className="img-cover" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---------------------------------------- SECTION CLONE MORE */}
                <div className="m-dark m-dark-clone-more flex-col justify-center mb-0 hidden md:flex">
                    <div className="bg-floating">
                        <div className="bg-violet"></div>
                        <div className="bg-torq"></div>
                        <div className="bg-grid"></div>
                    </div>
                    <div className="m-section m-section-clone-more m-width mx-auto flex flex-col justify-center  mb-0 mt-10">
                        <div className="flex gap-20">
                            <div className="flex-1">
                                <div className="box box1 relative overflow-hidden">
                                    <img src={person16} className="img-cover" />
                                    <div className="chat p-10">
                                        <div className="text-sm opacity-50 mb-2">Andrea Miller</div>
                                        <div className="mb-3 text-lg mb-5">Nice to see you, Peter! I trust you're enjoying the moment.</div>
                                        <div className="flex gap-5">
                                            <Iicon icon={"video-white"} />
                                            <Iicon icon={"speaker"} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-1 flex flex-col relative justify-evenly">
                                <div className="flex flex-row gap-10 glist">
                                    <div>
                                        <div className="boxed-icon">
                                            <img src={iconClock} className="icon" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-5 justify-center">
                                        <div className="text-3xl font-alt-extrabold">24/7 access to you.</div>
                                        <div>Build the AI version of you to scale your expertise and availability, infinitely.</div>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-10 glist">
                                    <div>
                                        <div className="boxed-icon">
                                            <img src={iconDumbell} className="icon" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-5 justify-center">
                                        <div className="text-3xl font-alt-extrabold">Train your clone on as much data as you'd like.</div>
                                        <div>Build the AI version of you to scale your expertise and availability, infinitely.</div>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-10 glist">
                                    <div>
                                        <div className="boxed-icon">
                                            <img src={iconBrain} className="icon" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-5 justify-center">
                                        <div className="text-3xl font-alt-extrabold">Your clone will think and speak like you</div>
                                        <div>Build the AI version of you to scale your expertise and availability, infinitely.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---------------------------------------- SECTION MESSAGE */}
                <div className="m-section m-section-message m-width mx-auto flex flex-col justify-center mb-0  py-20">
                    <div className="flex  flex-col md:flex-row gap-5 md:gap-20 items-center">
                        <div className="flex-1">
                            <div className="a-text font-alt-extrabold text-4xl leading-tight md:text-5xl md:leading-tight text-center md:text-left">
                                Message your
                                <div>
                                    <span className="rounded-3xl px-4" style={{ background: "#F0C780" }}>
                                        fans
                                    </span>
                                    anytime &amp;
                                </div>
                                anywhere.
                            </div>
                            <div className="opacity-40 text-base font-alt-semibold md:pr-10 p-5 md:p-0 text-center md:text-left">
                                Earn a recurring income by accepting monthly subscriptions.
                            </div>
                        </div>

                        <div className="flex-1 flex relative items-center scale-75 md:scale-100">
                            <div className="box box1 overflow-hidden">
                                <img src={person15} className="img-cover" />
                            </div>
                            <div className="box box2 overflow-hidden font-alt-bold-italic text-base">
                                “happy birthday jane! hope you have a wonderfulday... “
                            </div>
                            <img src={graphicChat} className="graphic1" />
                        </div>

                        <div className="relative flex-0  text-center md:text-left">
                            <div className="my-10 ">
                                Transcend traditional time and access barriers by enabling 1-on-1 personalized interactions with you possible at scale.
                            </div>

                            <a
                                href="/join-creators"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/join-creators");
                                }}
                                className="button button-inline text-lg font-alt-bold px-10"
                            >
                                Start for free
                            </a>
                        </div>
                    </div>
                </div>

                {/* ---------------------------------------- SECTION MESSAGE MORE */}
                <div className="m-dark flex-col  justify-center mb-0  hidden md:flex">
                    <div className="bg-floating">
                        <div className="bg-violet"></div>
                        <div className="bg-torq"></div>
                        <div className="bg-grid"></div>
                    </div>
                    <div className="m-section m-section-message-more m-width mx-auto flex flex-col justify-center  mb-0 mt-10">
                        <div className="flex gap-20">
                            <div className="flex-1 flex flex-col relative justify-evenly glist">
                                <div className="flex flex-col gap-10 ">
                                    <div>
                                        <div className="boxed-icon">
                                            <img src={iconCallout} className="icon" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-5 justify-center">
                                        <div className="text-3xl font-alt-extrabold">Provide personalized responses for each audience member.</div>
                                        <div>Build the AI version of you to scale your expertise and availability, infinitely.</div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-1">
                                <div className="box box1 relative overflow-hidden">
                                    <img src={person15} className="img-cover" />
                                    <div className="chat p-10">
                                        <div className="text-sm opacity-50 mb-2">Andrea Miller</div>
                                        <div className="mb-3 text-lg mb-5">Nice to see you, Peter! I trust you're enjoying the moment.</div>
                                        <div className="flex gap-5">
                                            <Iicon icon={"video-white"} />
                                            <Iicon icon={"speaker"} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-1 flex flex-col relative justify-evenly glist">
                                <div className="flex flex-col gap-10 ">
                                    <div>
                                        <div className="boxed-icon">
                                            <img src={iconQmark} className="icon" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-5 justify-center">
                                        <div className="text-3xl font-alt-extrabold">Drive engagement by providing suggested questions.</div>
                                        <div>Build the AI version of you to scale your expertise and availability, infinitely.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---------------------------------------- SECTION GROW */}
                <div className="m-section m-section-grow m-width mx-auto flex flex-col justify-center mb-0  py-20">
                    <div className="flex  flex-col md:flex-row   gap-5 md:gap-20  items-center">
                        <div className="flex-1 relative">
                            <div className="a-text  font-alt-extrabold  text-4xl leading-tight md:text-5xl md:leading-tight text-center md:text-left">
                                Track your stats
                                <div>
                                    and
                                    <span className="rounded-3xl px-4 mx-3" style={{ background: "#62E1BB" }}>
                                        Grow
                                    </span>
                                    your
                                </div>
                                following.
                            </div>
                            <div className="opacity-40 text-base font-alt-semibold md:pr-10 p-5 md:p-0 text-center md:text-left">
                                Earn a recurring income by accepting monthly subscriptions.
                            </div>

                            <img src={graphicToggle} className="graphic1 hidden md:block" />
                        </div>

                        <div className="flex-1 flex relative items-center  scale-75 md:scale-100">
                            <div className="box box1 overflow-hidden">
                                <img src={person12} className="img-cover" />
                                <div className="chat p-10">
                                    <div className="text-sm opacity-50 mb-2">Andrea Miller</div>
                                    <div className="mb-3 text-lg mb-5">Nice to see you, Peter! I trust you're enjoying the moment.</div>
                                    <div className="flex gap-5">
                                        <Iicon icon={"video-white"} />
                                        <Iicon icon={"speaker"} />
                                    </div>
                                </div>
                            </div>

                            <img src={graphicThumb} className="graphic2" />
                            <img src={graphicFollowers} className="graphic3 " />
                        </div>

                        <div className="relative flex-0  text-center md:text-left">
                            <div className="my-10">Track your monthly income, subscriber count, and more.</div>
                            <a
                                href="/join-creators"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/join-creators");
                                }}
                                className="button button-inline text-lg font-alt-bold px-10"
                            >
                                Start for free
                            </a>
                        </div>
                    </div>
                </div>

                {/* ---------------------------------------- SECTION GROW MORE */}
                <div className="m-dark flex-col  justify-center mb-0  hidden md:flex">
                    <div className="bg-floating">
                        <div className="bg-violet"></div>
                        <div className="bg-torq"></div>
                        <div className="bg-grid"></div>
                    </div>
                    <div className="m-section m-section-grow-more m-width mx-auto flex flex-col justify-center  mb-0 mt-10">
                        <div className="flex gap-20">
                            <div className="flex-1 flex flex-col relative justify-evenly">
                                <div className="flex flex-row gap-10 glist">
                                    <div>
                                        <div className="boxed-icon">
                                            <img src={iconId} className="icon" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-5 justify-center">
                                        <div className="text-3xl font-alt-extrabold">Monthly memberships for your biggest fans</div>
                                        <div>Build the AI version of you to scale your expertise and availability, infinitely.</div>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-10 glist">
                                    <div>
                                        <div className="boxed-icon">
                                            <img src={iconCoin} className="icon" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-5 justify-center">
                                        <div className="text-3xl font-alt-extrabold">
                                            Monetize your following by creating clean, exclusive, behind-the-scenes content.
                                        </div>
                                        <div>Build the AI version of you to scale your expertise and availability, infinitely.</div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-1">
                                <div className="box box1 relative overflow-hidden">
                                    <img src={person12} className="img-cover" />
                                    <div className="chat p-10">
                                        <div className="text-sm opacity-50 mb-2">Andrea Miller</div>
                                        <div className="mb-3 text-lg mb-5">Nice to see you, Peter! I trust you're enjoying the moment.</div>
                                        <div className="flex gap-5">
                                            <Iicon icon={"video-white"} />
                                            <Iicon icon={"speaker"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---------------------------------------- SECTION FAQ */}
                <div className="m-section m-section-faq m-width mx-auto flex flex-col justify-center mb-0 py-20">
                    <div className="flex  flex-col md:flex-row   gap-5 md:gap-20 items-center gcontent">
                        <div className="flex-1 relative">
                            <div className="a-text font-alt-extrabold text-5xl leading-tight md:text-7xl md:leading-tight text-center md:text-left ">
                                Frequently
                                <div>asked</div>
                                <span className="rounded-3xl px-4" style={{ background: "#FFC28A" }}>
                                    questions.
                                </span>
                            </div>
                        </div>

                        <div className="flex-1 flex relative items-center p-5">
                            <div className="accordion">
                                <div className="item item-open text-base">
                                    <div className="label">
                                        <div className="flex-1 font-alt-extrabold text-lg">How many followers do I need to become a fanlynk Creator? </div>
                                        <img src={arrowDown} className="arrow" />
                                    </div>
                                    <div className="value">You need at least 10,000 followers across all your social media platforms to apply.</div>
                                </div>

                                <div className="item item text-base">
                                    <div className="label">
                                        <div className="flex-1 font-alt-extrabold text-lg">How many followers do I need to become a fanlynk Creator? </div>
                                        <img src={arrowDown} className="arrow" />
                                    </div>
                                    <div className="value">You need at least 10,000 followers across all your social media platforms to apply.</div>
                                </div>

                                <div className="item item text-base">
                                    <div className="label">
                                        <div className="flex-1 font-alt-extrabold text-lg">How many followers do I need to become a fanlynk Creator? </div>
                                        <img src={arrowDown} className="arrow" />
                                    </div>
                                    <div className="value">You need at least 10,000 followers across all your social media platforms to apply.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---------------------------------------- SECTION TRENDING  */}
                <div className="m-dark flex-col  justify-center mb-0 ">
                    <div className="bg-floating">
                        <div className="bg-violet"></div>
                        <div className="bg-torq"></div>
                        <div className="bg-grid"></div>
                    </div>
                    <div className="m-section m-section-trending m-width mx-auto flex flex-col justify-evenly  mb-0 mt-10">
                        <div className="flex-col md:flex-row   gap-5 md:gap-20">
                            <div className="a-text font-alt-extrabold leading-tight flex-1 text-5xl leading-tight md:text-7xl md:leading-tight text-center md:text-left ">
                                Trending creator /
                                <div>
                                    influencer{" "}
                                    <span className="rounded-3xl px-4" style={{ background: "#766BF4" }}>
                                        models.
                                    </span>
                                </div>
                            </div>
                            <div className="flex pt-20 flex-row gap-6 items-center p-2">
                                <a
                                    href="/join-creators"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/join-creators");
                                    }}
                                    className="button button-inline text-lg font-alt-bold px-10 button-white"
                                >
                                    Start for free
                                </a>
                                <a href="/avatars" className="link text-lg font-alt-bold link-white">
                                    Browse avatars
                                </a>
                            </div>
                        </div>

                        <div
                            className="slider scale-90 md:scale-100"
                            onClick={() => {
                                navigate("/avatars");
                            }}
                        >
                            <div className="slider-track">
                                {persons.map((person, p) => (
                                    <div key={p} className="item">
                                        <img src={person.img} className="s-img" />
                                        <div className="chat">
                                            <div className="text-xs opacity-50 mb-2">{person.name}</div>
                                            <div className="mb-3">{person.msg}</div>
                                            <div className="flex gap-2">
                                                <Iicon icon={"video-white"} />
                                                <Iicon icon={"speaker"} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {persons.map((person, p) => (
                                    <div key={p} className="item">
                                        <img src={person.img} className="s-img" />
                                        <div className="chat">
                                            <div className="text-xs opacity-50 mb-2">{person.name}</div>
                                            <div className="mb-3">{person.msg}</div>
                                            <div className="flex gap-2">
                                                <Iicon icon={"video-white"} />
                                                <Iicon icon={"speaker"} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---------------------------------------- SECTION CREATE */}
                <div className="m-section m-section-create flex flex-col justify-center  mb-0">
                    <div className="flex gap-20 m-width  mx-auto flex-1">
                        <div className="flex-1 flex flex-col justify-center">
                            <div className="a-text font-alt-extrabold text-5xl leading-tight md:text-7xl md:leading-tight text-center md:text-left ">
                                What will
                                <div>
                                    you
                                    <span className="rounded-3xl px-4 ml-2" style={{ background: "#9D94FF" }}>
                                        create.
                                    </span>
                                </div>
                            </div>
                            <div className="opacity-40 my-10 text-base font-alt-semibold md:pr-10 p-5 md:p-0 text-center md:text-left">
                                Build the AI version of you to scale your expertise and availability, infinitely.
                            </div>
                            <div className="flex pt-20 flex-row gap-6 items-center p-2">
                                <a
                                    href="/join-creators"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/join-creators");
                                    }}
                                    className="button button-inline text-lg font-alt-bold px-10"
                                >
                                    Start for free
                                </a>
                                <a href="/avatars" className="link text-lg font-alt-bold link-black">
                                    Browse avatars
                                </a>
                            </div>
                        </div>

                        <div className="flex-1 flex-col relative  justify-center hidden md:flex">
                            <div className="relative h-3/6">
                                <div className="box box1  overflow-hidden">
                                    <img src={person16} className="img-cover" />
                                </div>
                                <div className="box box2  overflow-hidden">
                                    <img src={person15} className="img-cover" />
                                </div>

                                <div className="box box3  overflow-hidden">
                                    <img src={person12} className="img-cover" />
                                </div>

                                <img src={graphicChat} className="graphic1" />
                                <img src={graphicThumb} className="graphic2" />
                                <img src={graphicPic} className="graphic3" />
                            </div>
                        </div>
                    </div>

                    <MarketingFooter />
                </div>
            </div>
        </ReactLenis>
    );
};

export default ForCreators;
