import Header from "src/layouts/header/header";
import Main from "src/layouts/main/main";
import { useNavigate } from "react-router-dom";

import "./blog.css";

import Menu from "src/components/menu/menu";



import iconCheckCircle from "src/assets/images/icon-check-circle.svg";
import { useEffect, useState } from "react";
import FormInput from "src/components/form/input";
import Button from "src/components/ui/button";


const Blog = (props) => {
    const navigate = useNavigate();
    const [user, setUser] = useState();
    useEffect(() => {
        let storedUser = localStorage.getItem("AVATARZ_user");
        let currentUser = storedUser && storedUser !== "undefined" ? JSON.parse(storedUser) : null;
        if (currentUser && currentUser.id) {
            setUser(currentUser);
        }
    }, []);


    return (
        <div className="container-fluid flex flex-row p-0">
            <Menu />
            <Main>
                <Header />

                <div className="page-content">
                    <div className="flex align-items-center">
                        <h2 className="flex-1">Blog</h2>
                       
                    </div>
                   
                </div>
            </Main>
        </div>
    );
};

export default Blog;
