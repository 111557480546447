
import { useState, useEffect } from "react";
import Popup from "src/layouts/popup/popup";
import Button from "src/components/ui/button";
import FormInput from "src/components/form/input";
import { createAiStyle } from "src/apis/aistyle";

const ModalCharacterFast = (props) => {

    const [model, setModel] = useState({
        name: "",
        video: "",
        cache: "",
    });

    const handleCreateModel = async () => {
        let input = {
            name:model.name,
            path:model.video,
            s3:model.s3,
            cache:model.cache,
            url:model.url ,
            mtype:'fast'
        }

        let createRes = await createAiStyle(input);
        console.log(createRes)
        if(createRes && createRes[0] && createRes[0].id){
            // createRes[0].id
        }
        props.OnHide(true);

    };

    return (
        <Popup
            OnHide={() => {
                props.OnHide(true);
            }}
        >
            <div className="p-5 ">
                <h3>Create Fast Model</h3>
                <div className="">
                    <FormInput
                        label={`Model Name :`}
                        placeholder={`Your model name`}
                        value={model.name}
                        onChange={(e) => {
                            model.name = e.target.value;
                            setModel({ ...model });
                        }}
                        required={true}
                    />

                    { model.cache !=='' && <div className="mb-3 note">{model.cache}</div>}

                    <FormInput
                        label={`Select video to upload`}
                        placeholder={`Select video to upload`}
                        value={model.video}
                        type={"file"}
                        OnSuccess={(res) => {
                            console.log(res)
                            model.video = `${res.s3}`;
                            model.s3 = `${res.s3}`;
                            model.cache = `${res.cache}`;
                            model.url = `${res.url}`;
                            setModel({ ...model });
                        }}
                        required={true}
                    />

                    <Button disabled={(model.name ==='' || model.video ==='')? true : false} onClick={() => {handleCreateModel()}}> Create Model</Button>
                </div>
            </div>
        </Popup>
    );
};

export default ModalCharacterFast;
