import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Card from "src/components/card/card";
import Filter from "src/components/filter/filter";

import Grid from "src/layouts/grid/grid";
import Header from "src/layouts/header/header";
import Main from "src/layouts/main/main";
import Footer from "src/layouts/footer";
import Menu from "src/components/menu/menu";
import { getModelsByCategory } from "src/apis/aimodels";
import Button from "src/components/ui/button";

import iconFull from "src/assets/images/icon-full.svg";
import iconGrid from "src/assets/images/icon-grid.svg";
import Preloader from "src/components/preloader/preloader";
import TitleBar from "src/layouts/title-bar/title-bar";

const Category = (props) => {
    const navigate = useNavigate();
    const params = useParams();

    const [pageNum, setPageNum] = useState(1);
    const [models, setModels] = useState([]);
    const [fetchingMore, setFetchingMore] = useState(false);

    useEffect(() => {
        handleModels({ opr: "list", pageNum: 1 });
    }, [params.catname]);

    useEffect(() => {
        console.log(pageNum)
    }, [pageNum]);

    const handleModels = async ({ opr, pageNum }) => {

        if (pageNum === 1) {
            setModels([]);
        }
        setFetchingMore(true);
        switch (opr) {
            case "list":
                let listRes = await getModelsByCategory(params.catname, pageNum);
                if (listRes) {
                    if (pageNum === 1) {
                        setModels([...listRes]);
                    } else {
                        setModels([...models, ...listRes]);
                    }

                    if (listRes.length > 0) {
                        setPageNum(pageNum + 1);
                    }
                }

                break;

            default:
                break;
        }
        setFetchingMore(false);
    };

    return (
        <Main>
            <TitleBar length={models?.length}>
                <div className="p-3 flex flex-1 items-center">
                    <div
                        className="navi navi-back mr-2"
                        onClick={() => {
                            navigate("/avatars");
                        }}
                    >
                        <i className="iicon iicon-nav-back"></i>
                    </div>
                    <div className="text-base mr-3">Category :</div>
                    <div className="opacity-50 text-base font-semibold"> {params?.catname}</div>
                </div>
            </TitleBar>

            {/* <Filter /> */}
            {pageNum === 1 && !fetchingMore && models.length <= 0 ? (
                <div className="flex flex-1 items-center justify-center">We dont have avatars for this category yet.</div>
            ) : (
                <Grid
                    OnBottom={async () => {
                        handleModels({ opr: "list", pageNum });
                    }}
                >
                    {models.map((model, m) => (
                        <Card key={m} model={model} />
                    ))}
                </Grid>
            )}

            {fetchingMore && <div className="flex flex-1 items-center justify-center"><Preloader type={"more"} /></div>}
        </Main>
    );
};

export default Category;
