import { useEffect, useState, useRef } from "react";
import "./tester.css";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const Tester = () => {
    const apikey = "bf65a0a5-8ea6-4ae2-8f0b-1fadaf866539";
    const audioRef = useRef();
    const [audio, setAudio] = useState();
    const ws = useRef();
    const wstatus = useRef("closed");
    const [WStatus, setWStatus] = useState();

    const [currChunk, setCurrChunk] = useState();
    const chunksRef = useRef([]);
    const chunksPlaybackRef = useRef([]);

    const [transcript, setTranscript] = useState("Let me just say for the record that I think middle school is the dumbest idea ever invented.");

    const [talking, setTalking] = useState(false);

    const [showReplay, setShowReplay] = useState(false);

    const audioContextRef = useRef()

    const voices = [
        {
            name: "elon",
            voice: [
                -0.15771307051181793, 0.07505576312541962, -0.0036501612048596144, 0.032043106853961945, -0.06922848522663116, -0.013089844025671482,
                0.16396908462047577, 0.05400986596941948, 0.15713636577129364, 0.05416099354624748, -0.07462599873542786, 0.09032028913497925,
                0.0066451807506382465, -0.09460761398077011, -0.0394757054746151, -0.005417547654360533, -0.02884308062493801, -0.04090874269604683,
                -0.05778485909104347, 0.07470017671585083, 0.11026556044816971, 0.021048076450824738, -0.03233029320836067, -0.01600204035639763,
                0.07196195423603058, -0.024803927168250084, -0.04296457767486572, -0.06818905472755432, 0.10662660747766495, -0.016146810725331306,
                0.008660662919282913, 0.017059605568647385, -0.03035685233771801, 0.0698576271533966, -0.0030485496390610933, 0.07051672041416168,
                0.008557137101888657, 0.026481876149773598, 0.026063553988933563, 0.0472552552819252, -0.12140069901943207, -0.0810152217745781,
                -0.07533254474401474, -0.13417229056358337, -0.08606477081775665, 0.169035404920578, -0.10786296427249908, 0.08529751747846603,
                -0.08265304565429688, 0.020071109756827354, -0.027428964152932167, -0.05381937325000763, 0.09390252083539963, -0.06768958270549774,
                0.011532807722687721, 0.12791426479816437, 0.08300770819187164, 0.15604262053966522, -0.005823212210088968, 0.014162533916532993,
                0.08559529483318329, 0.04658273234963417, -0.22544322907924652, -0.028416728600859642, -0.060493104159832, -0.048475805670022964,
                -0.03917375206947327, 0.009680286049842834, 0.057637132704257965, -0.10247282683849335, -0.11693831533193588, 0.05334232375025749,
                0.05356185510754585, -0.104105144739151, -0.09375128149986267, -0.03427519276738167, -0.009786372072994709, -0.01973838545382023,
                0.0808948278427124, -0.07302117347717285, -0.01045240182429552, -0.030155623331665993, 0.048068225383758545, 0.07773355394601822,
                0.04644661769270897, -0.023839294910430908, 0.01869518868625164, 0.02654062956571579, -0.11237078905105591, 0.06118985638022423,
                0.0684877559542656, -0.12786537408828735, 0.11904812604188919, 0.032902415841817856, 0.1370631754398346, 0.12678825855255127,
                0.0301517341285944, -0.12068725377321243, -0.029223386198282242, 0.07067935168743134, -0.006284422241151333, -0.07750992476940155,
                -0.06876497715711594, 0.04643366485834122, -0.11298689246177673, -0.02916554920375347, 0.09392213076353073, 0.0292524341493845,
                -0.011924207210540771, 0.026698045432567596, 0.04528956115245819, -0.05643439292907715, -0.08865830302238464, 0.05237392336130142,
                -0.056890517473220825, -0.01293178740888834, 0.10093050450086594, 0.030972249805927277, -0.008765820413827896, 0.0748928040266037,
                0.002087469445541501, -0.036137621849775314, 0.05416034162044525, 0.09589381515979767, 0.013540576212108135, 0.044262856245040894,
                0.002851711353287101, 0.179615780711174, 0.024755757302045822, 0.029223840683698654, 0.029370250180363655, -0.020031888037919998,
                -0.0001267787447432056, -0.023713963106274605, -0.03017384186387062, -0.043762050569057465, 0.07914582639932632, -0.0007654555956833065,
                0.10258952528238297, -0.046336978673934937, 0.07733434438705444, 0.05226004123687744, -0.15239843726158142, -0.1024336889386177,
                -0.07030586153268814, -0.05462817847728729, -0.014199165627360344, 0.0014455736381933093, -0.12751922011375427, 0.04607950150966644,
                -0.01912204921245575, -0.12429999560117722, -0.005951236002147198, -0.09997352957725525, -0.05379069223999977, -0.14760492742061615,
                0.04470783472061157, -0.09013431519269943, -0.023413417860865593, -0.025165459141135216, 0.1531536877155304, -0.10856357961893082,
                -0.009565003216266632, -0.022537847980856895, 0.011145787313580513, -0.035780999809503555, -0.059942495077848434, 0.02466926909983158,
                -0.09347712993621826, 0.03790978342294693, -0.05655808001756668, 0.019980810582637787, 0.02851776033639908, -0.03476877883076668,
                0.004679158329963684, -0.05175479128956795, -0.020990470424294472, -0.04457499086856842, 0.09844481945037842, -0.027330106124281883,
                0.023787889629602432, 0.0930275022983551, 0.09652523696422577, 0.03452182188630104, 0.010188983753323555, 0.011779382824897766,
                -0.057428691536188126, -0.004690444562584162, 0.010947082191705704, 0.005610255524516106, 0.014457185752689838, 0.12289321422576904,
            ],
        },
        {
            name: "charles",
            voice: [
                -0.06476722657680511, -0.08661460876464844, 0.10020004957914352, 0.08144273608922958, -0.0004363080079201609, -0.08556665480136871,
                -0.09842879325151443, 0.09377976506948471, 0.02780737727880478, -0.1511729508638382, -0.06779322028160095, -0.08573248982429504,
                0.18914906680583954, 0.041257984936237335, -0.009983992204070091, 0.0009453511447645724, 0.0839661955833435, 0.06856650114059448,
                -0.020737389102578163, 0.006245976313948631, -0.001095659681595862, -0.004563812166452408, -0.029557252302765846, -0.05535881221294403,
                0.048159919679164886, 0.0020663090981543064, -0.024093572050333023, -0.13923555612564087, 0.12012413889169693, 0.07688266038894653,
                -0.05284630134701729, 0.001446447684429586, 0.07827533036470413, 0.04754665121436119, -0.026078414171934128, 0.028847744688391685,
                -0.05725747346878052, -0.025797875598073006, 0.051300160586833954, 0.08022396266460419, -0.017031840980052948, -0.006339931394904852,
                -0.005373467691242695, 0.047635518014431, 0.15351414680480957, -0.12422313541173935, 0.04672535881400108, 0.051016952842473984,
                0.008202388882637024, -0.1367645561695099, -0.06098075956106186, 0.015056200325489044, 0.09322244673967361, 0.028597209602594376,
                0.05759895220398903, 0.1931113451719284, -0.09141160547733307, -0.061432845890522, -0.04744882881641388, -0.05878319963812828,
                -0.07675696164369583, 0.03563271462917328, 0.020037570968270302, 0.012337539345026016, 0.0413544625043869, -0.060795869678258896,
                -0.016330048441886902, 0.04136929661035538, -0.060908425599336624, -0.061307284981012344, -0.13410784304141998, 0.0365779809653759,
                -0.06952183693647385, 0.00248466688208282, 0.057901740074157715, -0.1301397830247879, 0.006851634010672569, 0.021507391706109047,
                -0.036527957767248154, -0.032382067292928696, -0.06155126169323921, 0.01628998853266239, -0.06661932170391083, 0.0731501653790474,
                -0.17885220050811768, -0.051206499338150024, 0.06987018138170242, -0.010614668019115925, 0.07297895103693008, 0.049351342022418976,
                0.11807286739349365, -0.0743866041302681, 0.09891197830438614, -0.15164445340633392, 0.021480871364474297, 0.08157101273536682,
                -0.022407662123441696, -0.07369722425937653, 0.07605593651533127, 0.03692801669239998, 0.08192219585180283, 0.11333295702934265,
                -0.11941041797399521, -0.10782638192176819, 0.04025733470916748, -0.046027302742004395, 0.0076535907573997974, -0.0037592032458633184,
                0.02830916829407215, -0.013141672126948833, -0.1162838563323021, -0.12746596336364746, -0.043823935091495514, -0.027674833312630653,
                -0.027249107137322426, 0.08146524429321289, -0.09754255414009094, 0.06710710376501083, 0.09248395264148712, -0.01862400211393833,
                0.011106457561254501, -0.13539233803749084, -0.0026127202436327934, 0.00020458227663766593, 0.0871015191078186, -0.04269711300730705,
                -0.07310633361339569, -0.06185191124677658, 0.029682449996471405, -0.017490491271018982, -0.07039811462163925, -0.12635654211044312,
                0.09695267677307129, -0.043327637016773224, -0.0032729676458984613, 0.0697680115699768, -0.10166241228580475, -0.051494065672159195,
                -0.04372252896428108, 0.11071494966745377, 0.010546470992267132, -0.011347854509949684, 0.03835749626159668, 0.03509983792901039,
                0.00041201928979717195, 0.1590648740530014, -0.023072117939591408, 0.03886541351675987, -0.037926629185676575, 0.05973292142152786,
                -0.1718595325946808, 0.026856960728764534, -0.019943946972489357, -0.10483556985855103, 0.10359900444746017, -0.10822563618421555,
                -0.051933299750089645, 0.05932432413101196, -0.056485701352357864, 0.04521060362458229, 0.039502453058958054, -0.014540141448378563,
                -0.005315692629665136, 0.04356075078248978, -0.11117832362651825, 0.011600052937865257, 0.029363928362727165, 0.05200082063674927,
                -0.012420229613780975, 0.042441289871931076, 0.007669663522392511, -0.00023050577146932483, -0.07781671732664108, 0.028338341042399406,
                -0.08707479387521744, 0.1020454689860344, 0.07158569246530533, -0.005499645136296749, -0.028042562305927277, 0.05643247812986374,
                0.027396442368626595, -0.02716149389743805, -0.0005403922987170517, -0.085087351500988, 0.12430493533611298, -0.01488230936229229,
                -0.09546170383691788, -0.02426108717918396, -0.007308406755328178, -0.09158048033714294, -0.02158229984343052, 0.149857759475708,
            ],
        },
        {
            name: "obama",
            voice: [
                -0.042309001088142395, 0.09053689986467361, 0.17032723128795624, 0.053757358342409134, 0.09061377495527267, 0.09941024333238602,
                -0.01922895945608616, 0.08504343777894974, 0.040500808507204056, -0.0031550179701298475, -0.04282929375767708, 0.05506937578320503,
                0.11076482385396957, -0.03619429096579552, -0.037535518407821655, 0.08332674205303192, 0.09548086673021317, 0.06541432440280914,
                -0.027804996818304062, -0.024326246231794357, 0.024587541818618774, -0.01441147830337286, -0.02809225209057331, 0.015022428706288338,
                0.1345394104719162, 0.07588084787130356, -0.03703523054718971, -0.011885213665664196, 0.16330669820308685, -0.0256172064691782,
                -0.010063494555652142, 0.014024058356881142, 0.13590417802333832, 0.04123144596815109, 0.03255653753876686, 0.0654260516166687,
                -0.14900554716587067, -0.04155885800719261, 0.061692144721746445, -0.02699386700987816, 0.0433521568775177, -0.039614930748939514,
                -0.022985894232988358, -0.06946641206741333, 0.08324351906776428, 0.011441459879279137, 0.15043281018733978, -0.07453928142786026,
                -0.0417848601937294, -0.056496359407901764, -0.05056583881378174, -0.028770072385668755, 0.1453293263912201, 0.040924303233623505,
                -0.06765903532505035, 0.12254517525434494, 0.01944595016539097, 0.0939965769648552, -0.0065172165632247925, 0.024899711832404137,
                -0.01914064772427082, 0.11713841557502747, -0.009522204287350178, 0.057269059121608734, -0.019305918365716934, -0.024249546229839325,
                -0.0310251172631979, 0.11242582648992538, 0.06093819439411163, -0.0598115436732769, -0.11380857974290848, 0.07843858748674393,
                -0.013627387583255768, -0.036896396428346634, -0.033808354288339615, -0.07147768139839172, 0.008780858479440212, 0.0508795827627182,
                0.11696991324424744, 0.02653776854276657, -0.1058017835021019, 0.025808511301875114, 0.07719259709119797, 0.03301769867539406,
                0.043168891221284866, -0.06674356013536453, 0.11154213547706604, -0.011171586811542511, 0.0066647217608988285, -0.10706423223018646,
                0.050901953130960464, -0.09183656424283981, -0.02113194949924946, 0.09351155906915665, 0.012267904356122017, 0.10467222332954407,
                0.14470498263835907, -0.027173498645424843, 0.039556507021188736, 0.025751259177923203, 0.0397639274597168, -0.08103721588850021,
                -0.02279435098171234, -0.05693821236491203, 0.039382148534059525, -0.016553182154893875, 0.05513325706124306, -0.0447581522166729,
                0.0008178162970580161, 0.02429463341832161, -0.11829661577939987, -0.07654664665460587, 0.11828074604272842, 0.07071709632873535,
                -0.037033118307590485, 0.015440571121871471, 0.0256357379257679, 0.15929420292377472, 0.05271100625395775, -0.016738133504986763,
                0.08724426478147507, 0.1214866116642952, -0.035847704857587814, -0.030557602643966675, -0.1608465015888214, -0.11015166342258453,
                -0.041022416204214096, 0.030876968055963516, 0.10021242499351501, -0.015111376531422138, -0.02637428604066372, -0.03575260937213898,
                -0.0165794026106596, -0.14535459876060486, -0.0841895267367363, -0.02461867965757847, 0.049406055361032486, 0.018367627635598183,
                0.14726050198078156, 0.027493735775351524, 0.0800938829779625, 0.048036567866802216, 0.02997451275587082, 0.007116257678717375,
                -0.02570991963148117, 0.06338861584663391, -0.07192949205636978, -0.006308145821094513, -0.07553284615278244, 0.04736044630408287,
                0.05345527455210686, -0.07885411381721497, -0.0024652057327330112, -0.004716050811111927, 0.043691202998161316, -0.018291139975190163,
                -0.03901040181517601, 0.01952221430838108, 0.09808790683746338, -0.05857115238904953, 0.038431063294410706, -0.011637917719781399,
                -0.11450925469398499, -0.058730605989694595, 0.027902957051992416, -0.04633088782429695, -0.16099120676517487, -0.05767619237303734,
                -0.08379068225622177, 0.030462006106972694, 0.08403589576482773, -0.02264740690588951, -0.06327755004167557, -0.1281491369009018,
                0.03797232732176781, -0.09460695087909698, 0.008128675632178783, 0.03092091530561447, 0.06407081335783005, 0.07788737118244171,
                -0.09861636161804199, -0.01450484711676836, 0.06579113006591797, -0.06812871992588043, 0.09313516318798065, -0.027700744569301605,
                -0.11514485627412796, -0.0015832390636205673, -0.02580125629901886, -0.11493758112192154, 0.13342608511447906, 0.17968887090682983,
            ],
        },
        {
            name: "trump",
            voice: [
                -0.03405626118183136, -0.09054780751466751, 0.014082108624279499, 0.08523661643266678, 0.018947182223200798, 0.027217412367463112,
                -0.07789282500743866, 0.08125083148479462, 0.012818725779652596, -0.03443264961242676, 0.006642937660217285, -0.007175588048994541,
                0.009369540959596634, 0.022404560819268227, -0.04315333813428879, 0.030171604827046394, -0.186653271317482, -0.08383829891681671,
                0.05782796069979668, -0.04130946099758148, -0.10986773669719696, -0.09623005986213684, -0.04653109982609749, 0.01048886589705944,
                0.022083358839154243, 0.04728139936923981, 0.14522585272789001, 0.02871091105043888, 0.0017797125037759542, 0.11064168810844421,
                -0.04368220642209053, 0.050419535487890244, 0.007375865709036589, -0.0037580509670078754, -0.15330038964748383, -0.04638483002781868,
                -0.11915600299835205, 0.0008046987932175398, -0.007809147238731384, -0.04184484854340553, 0.08068512380123138, -0.014448943547904491,
                -0.04446130618453026, -0.04283057898283005, -0.06120217591524124, 0.0491708442568779, 0.0194296482950449, -0.08926733583211899,
                0.023671556264162064, -0.027375003322958946, 0.028551116585731506, 0.01873941160738468, -0.07708606868982315, -0.036764081567525864,
                -0.11169084906578064, 0.016860200092196465, -0.0310006495565176, -0.07325908541679382, -0.006423509679734707, -0.09123452007770538,
                0.11104796081781387, -0.0967046394944191, -0.017846666276454926, -0.17990614473819733, 0.014209718443453312, 0.00785071775317192,
                -0.03254244104027748, -0.014699346385896206, 0.09107998758554459, -0.11102525144815445, 0.029038624837994576, -0.035886235535144806,
                0.01152746006846428, 0.027119625359773636, -0.06175526604056358, -0.08533185720443726, 0.09746664017438889, -0.08610963076353073,
                -0.030862117186188698, -0.06191660836338997, 0.07590588927268982, 0.029613198712468147, 0.03828198090195656, 0.03871889039874077,
                0.03648381307721138, 0.05077184736728668, 0.032860737293958664, -0.0028256962541490793, 0.042126089334487915, 0.0076939817517995834,
                -0.03886566683650017, -0.02829962968826294, -0.10716209560632706, 0.014101793058216572, -0.075873002409935, 0.03067552112042904,
                -0.07300828397274017, -0.11237891763448715, 0.028237424790859222, -0.01188732124865055, 0.015101218596100807, 0.014848317950963974,
                -0.06338316202163696, -0.11141606420278549, 0.06284931302070618, 0.06377162039279938, 0.005474508740007877, -0.003656700486317277,
                -0.001875130576081574, -0.0001433761208318174, 0.025972403585910797, -0.09757480770349503, 0.039342351257801056, -0.04917595162987709,
                0.04984312132000923, 0.1638636440038681, 0.07853895425796509, 0.11310184746980667, 0.015465778298676014, -0.0012943549081683159,
                0.0031454768031835556, 0.07699697464704514, 0.0395137257874012, -0.0668078288435936, 0.10777880996465683, -0.1457117646932602,
                -0.03750438243150711, 0.16752022504806519, 0.0533401258289814, -0.09876715391874313, 0.017611602321267128, 0.12737968564033508,
                0.013426865451037884, 0.02793593518435955, -0.021179240196943283, 0.05806150659918785, -0.25146377086639404, -0.04457610100507736,
                -0.03279135003685951, -0.022789429873228073, 0.04588507115840912, 0.003262547543272376, -0.07700304687023163, -0.06841634958982468,
                0.0057855816558003426, -0.03274593502283096, 0.05932090803980827, -0.24205350875854492, -0.03177105262875557, -0.06143695488572121,
                0.10177811235189438, 0.07047571986913681, -0.045721858739852905, 0.0005782972439192235, 0.048295505344867706, 0.08337479829788208,
                -0.03901873528957367, 0.0838077962398529, -0.034582577645778656, -0.013635419309139252, 0.004497157409787178, 0.023558039218187332,
                0.1740172654390335, -0.003269863547757268, 0.005319973919540644, 0.16242098808288574, 0.05345053970813751, 0.011196502484381199,
                0.019950952380895615, 0.04411664977669716, 0.10685582458972931, 0.04303129389882088, -0.03731752187013626, -0.017799973487854004,
                -0.049580059945583344, 0.09504292905330658, -0.044384684413671494, 0.19648052752017975, -0.10423533618450165, -0.043842751532793045,
                0.030248913913965225, 0.05746665224432945, 0.04536888748407364, 0.05431270971894264, -0.004403301049023867, 0.04146552458405495,
                -0.09407880157232285, 0.13248737156391144, -0.09752625972032547, 0.09827639907598495, -0.013517970219254494, -0.03517767786979675,
            ],
        },
        {
            name: "paige",
            voice: [
                0.024352876469492912, -0.0795929953455925, 0.09651323407888412, 0.03234654664993286, -0.02505425550043583, 0.07800919562578201,
                0.11714059114456177, 0.10571622103452682, -0.026193693280220032, 0.024262161925435066, 0.013086672872304916, 0.02118777297437191,
                0.0709088072180748, 0.1108522117137909, 0.06322606652975082, 0.10885848104953766, -0.018606051802635193, 0.05857429280877113,
                -0.011790460906922817, 0.043195534497499466, 0.040501028299331665, 0.12793415784835815, 0.00020398122433107346, -0.029653161764144897,
                0.06058279052376747, -0.03470339626073837, 0.08462663739919662, -0.01330393087118864, -0.043978139758110046, -0.08744227886199951,
                -0.06721945852041245, -0.02459062822163105, 0.06829594820737839, -0.011258203536272049, 0.0587615929543972, 0.020174769684672356,
                0.10410122573375702, 0.014195152558386326, -0.07415774464607239, -0.057497091591358185, -0.04045764356851578, -0.1175219938158989,
                0.09912519156932831, -0.025091715157032013, -0.08495934307575226, 0.07120278477668762, 0.020037813112139702, 0.013993991538882256,
                0.08040254563093185, 0.07516029477119446, -0.06237556040287018, -0.00560593931004405, 0.07415476441383362, -0.042556032538414,
                0.19655944406986237, -0.08408293128013611, -0.06329617649316788, 0.035477131605148315, 0.08541905134916306, -0.026727451011538506,
                0.03694029152393341, 0.05398166552186012, -0.062430694699287415, -0.10512454807758331, 0.024901170283555984, -0.01751178875565529,
                0.12893955409526825, 0.06317213922739029, -0.016771996393799782, -0.0376984179019928, 0.07714028656482697, -0.042599648237228394,
                0.05628872290253639, -0.006058503407984972, 0.05198816955089569, 0.04198359698057175, 0.06838256865739822, -0.012141373939812183,
                -0.06955272704362869, -0.05334799736738205, 0.009628304280340672, 0.006844992283731699, -0.03861363232135773, 0.12991999089717865,
                -0.033123042434453964, -0.10982740670442581, 0.038719430565834045, 0.02944677136838436, 0.04701204597949982, 0.08395223319530487,
                -0.034323256462812424, -0.03583408519625664, -0.015480583533644676, 0.0048130168579518795, 0.053233109414577484, -0.058359917253255844,
                0.061929166316986084, 0.021432990208268166, -0.05069312825798988, 0.0930444598197937, 0.020872557535767555, -0.00022156168415676802,
                0.12341200560331345, 0.09808707982301712, 0.03577093407511711, 0.08244865387678146, -0.03701452910900116, -0.07328739762306213,
                0.12374996393918991, 0.007199258077889681, -0.025999702513217926, 0.015358216129243374, -0.010024159215390682, -0.10960175096988678,
                -0.029997417703270912, 0.18420837819576263, 0.04174439236521721, -0.057178277522325516, 0.0486086830496788, 0.14042185246944427,
                0.10544780641794205, 0.06063293665647507, 0.01796002686023712, -0.024557411670684814, 0.02406846359372139, 0.08915075659751892,
                0.020250685513019562, -0.038184087723493576, -0.158383309841156, -0.11263561248779297, -0.12466248869895935, -0.04619666561484337,
                -0.06934709846973419, 0.09849907457828522, 0.010112756863236427, -0.06861189752817154, -0.045648589730262756, -0.0861363559961319,
                -0.007480570115149021, 0.12407255172729492, 0.13764475286006927, 0.0913444310426712, -0.008387972600758076, 0.0793902575969696,
                0.06182701885700226, 0.009881002828478813, 0.061830755323171616, 0.06501966714859009, -0.09335026144981384, -0.08211749792098999,
                0.03628707677125931, 0.050376828759908676, -0.037136875092983246, 0.06907516717910767, -0.08581896871328354, -0.04460665211081505,
                0.04782356321811676, 0.07025742530822754, 0.08545660972595215, 0.025687983259558678, 0.01982387900352478, -0.22168968617916107,
                -0.0530119352042675, -0.03377775102853775, 0.03418970853090286, 0.2024589627981186, 0.13130110502243042, -0.059311360120773315,
                0.025126352906227112, -0.032431021332740784, -0.076346255838871, -0.07956558465957642, 0.015459401533007622, 0.052444279193878174,
                0.03238639608025551, -0.16298791766166687, 0.09801552444696426, -0.019003702327609062, 0.01793990470468998, -0.046171557158231735,
                0.1564195454120636, -0.0228763185441494, -0.07749677449464798, -0.03802260756492615, 0.051283419132232666, -0.06379225850105286,
                -0.0475638248026371, -0.02648727037012577, -0.03971964493393898, -0.05802498385310173, -0.06638197600841522, -0.004638445097953081,
            ],
        },
    ];

    const [selectedVoice, setSelectedVoice] = useState(voices[0]);

    useEffect(() => {
        if (!currChunk) return;

        if (currChunk.done === true) {
            setShowReplay(true);
            //chunksRef.current = [];
            setTalking(false);
            //setTranscript("");
        } else {
            playPCM(currChunk, "live");
        }
    }, [currChunk]);

    const initws = () => {
        ws.current = new WebSocket(`wss://api.cartesia.ai/v0/audio/websocket?api_key=${apikey}`);

        wstatus.current = "connecting";
        setWStatus(wstatus.current);
        ws.current.onmessage = function (evt) {
            let msg = JSON.parse(evt.data);
            console.log("ws msg :: ", msg);
            msg.id = uuidv4();


            chunksRef.current = [...chunksRef.current, msg];
            chunksPlaybackRef.current = [...chunksPlaybackRef.current, msg];

            if (chunksRef.current.length === 1) {
                setCurrChunk(msg);
            }

            if (msg.done) {
                setShowReplay(true);
                setTalking(false);
            }
        };
        ws.current.onopen = function (evt) {
            wstatus.current = "open";
            setWStatus(wstatus.current);
            console.log("ws opened");
            // ws.current.send(JSON.stringify({ context_id: uuidv4(), data: ttsdata }));
        };
        ws.current.onerror = function (evt) {
            wstatus.current = "error";
            setWStatus(wstatus.current);
            console.log("ws error: " + evt.data);
        };
        ws.current.onclose = function (evt) {
            wstatus.current = "closed";
            setWStatus(wstatus.current);
            console.log("ws closed");
        };
    };

    const playPCM = (msg, type) => {
        if (msg && !msg.data || !audioContextRef) return;

        try {
            const binaryString = atob(msg.data);
            const len = binaryString.length;
            const buffer = new ArrayBuffer(len);
            const view = new DataView(buffer);
            for (let i = 0; i < len; i++) {
                view.setUint8(i, binaryString.charCodeAt(i));
            }
            const float32Array = new Float32Array(buffer);
            // const audioContext = new (window.AudioContext || window.webkitAudioContext)();

            const sampleRate = 44100;
            const audioBuffer = audioContextRef.current.createBuffer(1, float32Array.length, sampleRate);
            //audioBuffer.getChannelData(0).set(float32Array);
            audioBuffer.copyToChannel(float32Array, 0);
            const source = audioContextRef.current.createBufferSource();
            source.buffer = audioBuffer;

            console.log(`SOURCE:: ${msg.data.substring(0, 8)}`, source);
            source.connect(audioContextRef.current.destination);

            source.onended = function () {
                switch (type) {
                    case "standalone":
                        console.log("playing standalone");
                        break;
                    case "live":
                        // console.log(`ended ::  ${msg.data.substring(0, 8)}...`);
                        chunksRef.current.shift();
                        setCurrChunk({ ...chunksRef.current[0] });
                        break;

                    default:
                        break;
                }

                // console.log('ended')
            };

            source.start(0);
            console.log(`playing ::  ${msg.data.substring(0, 8)}...`);
        } catch (error) {
            console.error("Error during audio setup or playback:", error);
        }
    };

    const sendTranscript = () => {
        if (wstatus.current === "open" && transcript !== "" && selectedVoice) {
            chunksRef.current = [];
            chunksPlaybackRef.current = [];
            setTalking(true);
            const ttsdata = {
                transcript,
                chunk_time: 0.1,
                model_id: "upbeat-moon",
                voice: selectedVoice.voice,
            };

            console.log(ttsdata);
            ws.current.send(JSON.stringify({ context_id: uuidv4(), data: ttsdata }));
        }
    };

    async function playSequence(pcmArray) {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        let currentTime = audioContext.currentTime;
        const sampleRate = 44100; // Adjust this to match your PCM data's sample rate

        for (const base64 of pcmArray) {
            if (base64.data) {
                const binaryString = atob(base64.data);
                const len = binaryString.length;
                const buffer = new ArrayBuffer(len);
                const view = new DataView(buffer);
                for (let i = 0; i < len; i++) {
                    view.setUint8(i, binaryString.charCodeAt(i));
                }

                const float32Array = new Float32Array(buffer);
                const audioBuffer = audioContext.createBuffer(1, float32Array.length, sampleRate);
                audioBuffer.copyToChannel(float32Array, 0);

                const source = audioContext.createBufferSource();
                source.buffer = audioBuffer;
                source.connect(audioContext.destination);
                source.start(currentTime);
                currentTime += audioBuffer.duration;
            }
        }
    }

    const playAll = () => {
        chunksRef.current = [...chunksPlaybackRef.current];
        console.log(chunksRef.current.length);

        playSequence(chunksRef.current);

        //setCurrChunk({ ...chunksRef.current[0] });
    };

    return (
        <div className="tester-page flex w-full h-full justify-center overflow-y-auto p-20">
            {/* <div className="test-vid-container">
                <audio ref={audioRef} autoPlay playsInline controls>
                    <source src={audio}></source>
                </audio>
            </div> */}

            <div className="flex flex-col w-3/5 gap-5">
                {wstatus.current === "open" && (
                    <>
                        <div className="flex flex-row gap-3 text-sm py-3 items-center ">
                            {voices.map((voice, v) => (
                                <div
                                    key={v}
                                    className={`cursor-pointer px-5 py-2 ${voice.name === selectedVoice.name ? ` bg-gray-200 rounded-xl` : ``}`}
                                    onClick={() => {
                                        setSelectedVoice(voice);
                                    }}
                                >
                                    {voice.name}
                                </div>
                            ))}
                        </div>

                        <textarea
                            className="min-h-32 rounded-2xl p-4"
                            disabled={talking}
                            value={transcript}
                            onChange={(e) => {
                                setTranscript(e.target.value);
                            }}
                        />
                        <div className="flex flex-0 pb-5 border-b border-b-gray-300 justify-between">
                            <button
                                className="button button-inline "
                                disabled={transcript === "" || talking === true}
                                onClick={() => {
                                    sendTranscript();
                                    setShowReplay(false)

                                   audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
                                }}
                            >
                                send
                            </button>

                            {showReplay && (
                                <button
                                    className="button button-inline"
                                    onClick={() => {
                                        playAll();
                                    }}
                                >
                                    Replay
                                </button>
                            )}
                        </div>

                        <div className="w-full flex flex-1 flex-col gap-4 overflow-y-auto">
                            {chunksPlaybackRef.current.map((chunk, c) => (
                                <div key={c} className="flex  items-center">
                                    <div className="flex pr-5">{c}. </div>
                                    <div className="flex-1">{chunk.data ? chunk.data.substring(0, 8) : ""}...</div>

                                    <div className="flex-1">Step time: {chunk.step_time}</div>
                                    <div className="flex-1">Length: {chunk.length}</div>
                                    <div className="flex ">
                                        <button
                                            className="button button-small"
                                            onClick={() => {
                                                playPCM({ data: chunk.data }, "standalone");
                                            }}
                                        >
                                            play
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
                <div className="flex flex-row ">
                    {wstatus.current === "closed" && (
                        <button
                            className="button button-inline"
                            onClick={() => {
                                initws();
                            }}
                        >
                            start websocket
                        </button>
                    )}
                    {wstatus.current === "open" && (
                        <button
                            className="button button-inline "
                            onClick={() => {
                                ws.current.close();
                                chunksRef.current = [];
                                chunksPlaybackRef.current = [];
                                setTalking(false);
                                setTranscript("");
                            }}
                        >
                            stop websocket
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Tester;
