import { useEffect, useState, useRef } from "react";
import { Route, Redirect, useHistory, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import "./chat.css";

import Main from "src/layouts/main/main";
import iconLoaderSmall from "src/assets/images/icon-loader-small.svg";
import iconXWhite from "src/assets/images/icon-x-white.svg";
import iconXSmall from "src/assets/images/icon-x-small.svg";
import iconExpand from "src/assets/images/icon-expand.svg";

import Menu from "src/components/menu/menu";
import { getModel } from "src/apis/aimodels";
import Button from "src/components/ui/button";
import { createSession, deleteSession, getAiChatByModelid, getLastSession, sendChat, updateNameToSession } from "src/apis/aichats";
import HLSPlayer from "src/components/hls-player";
import VideoPlayer from "src/components/video-player";
import { getStoredUser } from "src/components/auth/auth";
import { dateFormatter, epochToDate } from "src/components/utils";
import { getHistoryByUser, setHistory } from "src/apis/aihistory";
import Preloader from "src/components/preloader/preloader";
import endpoint from "src/apis/endpoints";

import { startSageMaker } from "src/apis/sagemaker";
import TypeText from "src/components/typetext/typetext";
import { llmapi, publish } from "src/apis/utils";
import { checkWebcam } from "src/components/utils";
import AudioRecorderWithLevels from "src/components/audio-recorder/audio-recorder";
import TTS from "src/components/tts/tts";
import VoiceChat from "src/components/voice-chat/voice-chat";
import Pubnuber from "src/components/pubnuber/pubnuber";
import VideoQueuer from "src/components/video-player/video-queuer";
import Iicon from "src/components/iicon/iicon";
import ChatHeader from "./chat-header";

import { addToQueue } from "src/apis/utils";
import imgPlaceholder from "src/assets/images/image-placeholder.svg";
import WebRTC from "src/components/webrtc/webrtc";
import ChatsDefaults from "./chat_defaults";
import iconSpeaker from "src/assets/images/icon-speaker-white.svg";

const Chat = (props) => {
    const navigate = useNavigate();
    let { modelId } = useParams();
    const videoTypes = [
        {
            id: "webrtc",
            name: "WEBRTC",
        },
        {
            id: "mp4",
            name: "MP4",
        },
    ];

    let chatContainerRef = useRef();

    let chatsEndRef = useRef();
    let chatsDivRef = useRef();

    let chatsBoxRef = useRef();
    let chatsRef = useRef([]);
    let chatBoxWrapperRef = useRef();

    const [fetching, setFetching] = useState(false);
    const [model, setModel] = useState();
    const [chatSessions, setChatSessions] = useState([]);
    const [currentSession, setCurrentSession] = useState();
    let currentSessionRef = useRef();

    const [chatInput, setChatInput] = useState("");
    const [chats, setChats] = useState([]);
    const [fetchingResponse, setFetchingResponse] = useState(false);

    const [showSessions, setShowSessions] = useState(true);

    const [fetchingChatbox, setFetchingChatbox] = useState(false);

    const [attachments, setAttachments] = useState([]);
    const [hasWebcam, setHasWebcam] = useState(false);
    const [isRecording, setIsRecording] = useState(false);

    let pictureUploadRef = useRef();
    let aiStartTime = useRef();
    const [chatToAudio, setChatToAudio] = useState();
    let pubMarker = useRef();
    let pubMsgArray = useRef([]);

    const [chatType, setChatType] = useState("chat");
    let chatTypeRef = useRef(chatType);

    const [hasBots, setHasBots] = useState(false);

    let hasBotsTimer = useRef();

    const [videoType, setVideoType] = useState(videoTypes[0]);

    const [pageNum, setPageNum] = useState(1);
    const [msgToPlay, setMsgToPlay] = useState();

    const [botId, setBotId] = useState();
    let botIdRef = useRef();
    const [botState, setBotState] = useState();
    let pingBotRef = useRef();

    const [pubChannels, setPubChannels] = useState([]);
    const [vod, setVod] = useState({ path: null });

    let hasIstemp = useRef(false);

    let vidChunksQueueRef = useRef(0);
    const [vidChunksQueue, setVidChunksQueue] = useState([]);
    const [showNewChatMore, setShowNewChatMore] = useState(false);
    const [voiceTextSent, setVoiceTextSent] = useState({ state: false });

    const [voiceStatusText, setVoiceStatusText] = useState("Start speaking");
    const [msgJoining, setMsgJoining] = useState(false);

    const [webrtcText, setWebrtcText] = useState({ input: "" });

    const msgMarkers = useRef([]);
    const webrtcSaveId = useRef();
    const [webrtcThinking, setWebrtcThinking] = useState(false);

    const [showChatDefault, setShowChatDefault] = useState(false);

    useEffect(() => {
        setHasWebcam(checkWebcam());

        if (window.innerWidth < 980) {
            setShowSessions(false);
        }

        return () => {
            if (pingBotRef.current) {
                handleBot({ opr: "stop" });
                clearInterval(pingBotRef.current);
            }
        };
    }, []);
    useEffect(() => {
        chatTypeRef.current = chatType;
        if (currentSession) {
            handleSession({ opr: "getHistory", session: currentSession, model });
        }
    }, [chatType]);
    useEffect(() => {
        // if (chatBoxWrapperRef.current) {
        //     chatBoxWrapperRef.current.style.height = window.innerHeight - 200 + "px";
        // }
    }, [chatBoxWrapperRef.current]);

    useEffect(() => {
        if (model && model.firstmsg !== "") {
            addFillerResponse(model.firstmsg);
        }
    }, [model]);

    useEffect(() => {
        handleModel(modelId);
    }, [modelId]);

    useEffect(() => {
        if (model) {
            getLastSession({ modelid: model.id }).then((res) => {
                let thesession = res;
                if (res && res.length <= 0) {
                    thesession = null;
                }
                // setCurrentSession(thesession);
            });
        }
        if (model && chatSessions && chatSessions.length <= 0) {
            handleSession({ opr: "create", model });
        }
    }, [chatSessions]);

    useEffect(() => {
        botIdRef.current = botId;
        if (botId && botId !== "") {
            if (videoType && videoType.id === "mp4") {
                pubChannels[1] = botId;
                setPubChannels([...pubChannels]);

                // } else if (videoType && videoType.id === "webrtc") {
                //     pubChannels[1] = "bot-webrtc-client";
                //     setPubChannels([...pubChannels]);
                //     //setPubChannels([pubChannels[0]]);
            }
        }
    }, [botId, videoType]);

    useEffect(() => {
        currentSessionRef.current = currentSession;
        setWebrtcThinking(false);

        if (botId) {
            handleBot({ opr: "stop" });
            clearInterval(pingBotRef.current);
            setBotId(null);
            setMsgToPlay(null);
        }

        setMsgJoining(false);
        setVod({ path: null });
        setHasBots(false);

        setVidChunksQueue([]);
        // setChats([]);
        pubMarker.current = undefined;
        pubMsgArray.current = [];

        if (hasBotsTimer.current) {
            clearTimeout(hasBotsTimer.current);
        }
        console.log("currentSession :: ", currentSession);
        if (currentSession) {
            handleBot({ opr: "check" });
            setPubChannels([currentSession.id + "-text"]);
            handleSession({ opr: "getHistory", session: currentSession, model });
        } else {
            setPubChannels([]);
        }
    }, [currentSession]);

    useEffect(() => {
        chatsRef.current = [...chats];
        hasIstemp.current = chatsRef.current.find((chat) => chat.id === "istemp");
        scrollToBottom("instant");

        if (chatsRef.current.length > 0) {
            setShowChatDefault(false);
        } else {
            setShowChatDefault(true);
        }
    }, [chats]);

    const updateChat = ({ id, updateId, updatedValues, appendText, setText, saveId, callback, updateToSaveId, cantype }) => {
        setChats((prevChats) => {
            return prevChats.map((chat) => {
                if (chat.id === id) {
                    if (appendText) {
                        chat.text = chat.text + appendText;
                    }
                    if (setText) {
                        chat.text = setText;
                    }
                    if (updatedValues) {
                        chat = { ...chat, ...updatedValues };
                    }

                    if (saveId) {
                        chat.saveId = saveId;
                    }

                    if (updateToSaveId && chat.saveId) {
                        chat.id = chat.saveId;
                    }

                    if (cantype) {
                        chat.cantype = cantype;
                    }

                    if (updateId) {
                        chat.id = updateId;
                    }

                    if (callback) {
                        callback(chat);
                    }
                }

                return { ...chat };
            });
        });
    };

    let lastElem = useRef();
    useEffect(() => {
        vidChunksQueueRef.current = vidChunksQueue;
        let lastElemTimer;
        //console.log("vidChunksQueue ::", vidChunksQueueRef.current.length);
        let currentlastElem = vidChunksQueue[vidChunksQueue.length - 1];

        if (currentlastElem) {
            lastElemTimer = setTimeout(() => {
                console.log("reached last mp4");
                setVidChunksQueue([]);
                clearTimeout(lastElemTimer);
            }, 3000);
            if (lastElem?.current?.findex !== currentlastElem.findex) {
                clearTimeout(lastElemTimer);
                setVidChunksQueue([...vidChunksQueue]);
            }
            lastElem.current = currentlastElem;
        }

        if (vidChunksQueue.length >= 1) {
            const updatedChats = chatsRef.current.map((chat) => {
                delete chat.onhold;
                return { ...chat };
            });
            setChats([...updatedChats]);
        }

        return () => {
            clearTimeout(lastElemTimer);
        };
    }, [vidChunksQueue]);

    useEffect(() => {
        // console.log("PUBMARKER ::", pubMarker.current);
        const findTempHasSaveId = chatsRef.current.find((chat) => chat.id === "istemp" && chat.saveId);
        let startMarkerTimeout;
        if (findTempHasSaveId) {
            if (pubMarker.current === "end") {
                clearTimeout(startMarkerTimeout);
                updateChat({
                    id: "istemp",
                    updateToSaveId: true,
                });
                setFetchingResponse(false);
            } else if (pubMarker.current === "start") {
                // add timeout if missing end flag
                startMarkerTimeout = setTimeout(() => {
                    //  console.log("missed end flag");
                    updateChat({
                        id: "istemp",
                        updateToSaveId: true,
                    });
                    clearTimeout(startMarkerTimeout);
                }, 3000);
            }
        }
    }, [pubMarker.current, chatsRef.current]);

    const handlePubnubMessage = async (msg) => {
        //GRAB MP4s
        if (msg.indexOf(`"cache"`) !== -1) {
            let parsedMsg = JSON.parse(msg);
            const received = Date.now();
            let fname = parsedMsg[0].cache.split("/")[parsedMsg[0].cache.split("/").length - 1];

            let findex = parseInt(fname.slice(0, -5));
            //if(fname.indexOf('v.') !== -1){
            console.log(`→ video chunk received(${received}) - ${epochToDate(received)}:`, parsedMsg[0].cache);
            const vidChunk = {
                received,
                findex,
                video: parsedMsg[0].cache,
            };

            // vidChunksQueue.push(vidChunk);
            setVidChunksQueue([...vidChunksQueueRef.current, vidChunk]);

            setMsgToPlay({
                received,
                video: parsedMsg[0].cache,
            });
            //  }
        } else {
            //save msg to calculate if finished
            pubMsgArray.current.push(msg);
        }

        let msgMarker = pubMsgArray.current.filter((msg) => msg === "");

        if (msgMarker.length === 1 && pubMsgArray.current.indexOf("") === 0) {
            pubMarker.current = "start";
            setMsgJoining(false);
        } else if (msgMarker.length === 1 && pubMsgArray.current.indexOf("") !== 0) {
            pubMarker.current = "end";
            pubMsgArray.current = [];
        } else if (msgMarker.length >= 2) {
            pubMarker.current = "end";
            pubMsgArray.current = [];
        }

        if (msg.indexOf(`"cache"`) === -1) {
            console.log(`pubnub msg : ${Date.now()} delay(${(Date.now() - aiStartTime.current) / 1000} sec) : "${msg}"`);
        }

        //GRAB text
        if (pubMarker.current !== undefined) {
            if (msg.indexOf("[") === -1 && msg.indexOf("{") === -1) {
                //  setTextChunks(textChunksRef.current + msg);
                if (!hasIstemp.current && pubMarker.current !== "end") {
                    //
                    let mmsg = {
                        id: "istemp",
                        text: msg,
                        from: model.name,
                        type: "received",
                        onhold: videoType && videoType.id === "mp4" ? true : false,
                    };
                    setChats([...chatsRef.current, mmsg]);
                } else {
                    updateChat({ id: "istemp", appendText: msg });
                }
            } else if (msg.indexOf(`[{"filepaths3"`) !== -1) {
                let parsedMsg = JSON.parse(msg);
                let filepath = parsedMsg[0].filepaths3;

                let imsg = {
                    id: "isimage",
                    genimage: filepath,
                    text: "",
                    from: model.name,
                    isFiller: true,
                    type: "received",
                };

                setChats([...chatsRef.current, imsg]);
            }
        }
    };

    const handleModel = async () => {
        setFetching(true);
        let selectRes = await getModel({ id: modelId });
        console.log("the model :: ", selectRes);

        if (selectRes && selectRes.id) {
            let themodel = selectRes;
            setModel(themodel);

            await handleSession({ opr: "list", model: themodel });
        } else {
            navigate(-1);
        }
        setFetching(false);
    };

    const handleSendChat = async (msg) => {
        setFetchingResponse(true);
        let theSession = currentSession;
        if (chatType === "chat") {
            pubMarker.current = "start";
        }
        // if (msg.text.indexOf("picture") !== -1) {
        //     handleGenImage(msg);
        // }
        if (msg && msg != "") {
            setChats([...chatsRef.current, msg]);
            setChatInput("");

            if (!theSession) {
                const newSession = await handleSession({ opr: "create", model });
                theSession = newSession;
            }

            if (!botIdRef.current && model.modelpath?.indexOf("s3:") !== -1) {
                setMsgJoining(true);
                if (videoType?.id === "webrtc") {
                    setWebrtcThinking(true);
                }
                scrollToBottom();
                await handleBot({ opr: "init", thebot: model.modelpath, session: theSession });
                await new Promise((resolve) => setTimeout(resolve, 50));
            }

            switch (videoType?.id) {
                case "mp4":
                    await handleAiResponse({ message: msg.text, sessionId: theSession.id });
                    break;

                case "webrtc":
                    // setMsgJoining(true);foo
                    //setWebrtcThinking(true)
                    webrtcText.input = msg.text;
                    setWebrtcText({ ...webrtcText });

                    break;

                default:
                    break;
            }
        }
        scrollToBottom();
    };

    const handleAiResponse = async ({ message, sessionId }) => {
        if (currentSessionRef.current) {
            if (message && message !== "") {
                // setFetchingResponse(true);

                let thellm = model.llm === "" ? "gpt-4" : model.llm;
                let theimage = null;

                console.log("attachments to send : ", attachments);
                if (attachments.length > 0) {
                    theimage = attachments[0].src;
                    thellm = "gpt-vision";
                    setAttachments([]);
                }

                let theSessionId = sessionId ? sessionId : currentSession.id;

                try {
                    aiStartTime.current = Date.now();

                    console.log(`message payload: ${aiStartTime.current}`, theSessionId, model.llm, message);
                    let messageRes = await sendChat({ sessionid: theSessionId, llm: thellm, msg: message, image: theimage });
                    console.log(`message response : ${Date.now()} delay(${(Date.now() - aiStartTime.current) / 1000} sec) :`, messageRes);

                    // if (currentSession.isbatch === "false") {
                    //     setFetchingResponse(false);
                    // }

                    messageRes = messageRes[0];
                    if (messageRes && messageRes.id) {
                        const responseTimeout = setTimeout(() => {
                            //  console.log("FORCE RESET ::", fetchingResponse);
                            if (fetchingResponse === true) {
                                setFetchingResponse(false);
                                pubMarker.current = "end";
                                pubMsgArray.current = [];
                            }
                            clearTimeout(responseTimeout);
                        }, 5000);

                        voiceTextSent.state = true; //this is voice chat
                        setVoiceTextSent({ ...voiceTextSent });

                        scrollToBottom();
                        updateChat({
                            id: "istemp",
                            saveId: messageRes.id,
                        });
                        if (attachments.length > 0) {
                            const msgUser = {
                                text: message,
                                from: "You",
                                type: "sent",
                            };
                            setChats([...chatsRef.current, msgUser]);
                            const msgAttch = {
                                id: messageRes.id,
                                image: attachments[0].src,
                                text: messageRes.response,
                                from: model.name,
                                type: "received",
                            };
                            setChats([...chatsRef.current, msgAttch]);
                            await setHistory(messageRes.id, { image: attachments[0].src });
                            setFetchingResponse(false);
                        }
                        await new Promise((resolve) => setTimeout(resolve, 2000));
                    }
                } catch (error) {
                    //await addFillerResponse("I beg your pardon, can you repeat the question?");
                    pubMsgArray.current = [];
                    setAttachments([]);
                    console.log("message bot error : ", error);
                    setFetchingResponse(false);
                }
            } else {
                console.log("message is empty : ", message);
            }
        }
    };

    const handleSpeak = async (type, chat, text) => {
        let sageInput;

        if (type === "nerf") {
            sageInput = {
                avatar_number: model.nerf,
                text: text,
            };
        } else if (type === "fast") {
            // let bucket = model.fast0.split(".")[0];
            // let key = model.fast0.split(".com/")[1];
            // let thePath = `s3://${bucket}/${key}`;

            // sageInput = {
            //     modelid: model.id,
            //     name: model.name,
            //     text: text,
            //     video_url: model.fast0,
            //     //video_url: "s3://staging-sagemaker-output/obama3.mp4"
            //     // video_url: "s3://squadz-dev-bucket-02/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/default/obama3.mp4"
            // };

            sageInput = {
                text: text,
                video_url: model.fast0,
                modelid: model.id,
            };
        }

        updateChat({
            id: chat.id,
            updatedValues: {
                vidurl1: type === "nerf" ? "fetching" : "",
                vidurl: type === "fast" ? "fetching" : "",
            },
        });

        let startTime = Date.now();
        console.log(`wav2lipAsync input (${type}) : ${startTime}`, sageInput);

        try {
            let wav2lipAsyncQueRes = await addToQueue({ key: "lipsynchd", value: JSON.stringify(sageInput) });

            let videoPath = ``;
            if (wav2lipAsyncQueRes && wav2lipAsyncQueRes !== "timedout") {
                const parsedData = JSON.parse(wav2lipAsyncQueRes);

                videoPath = `${parsedData.upload[0].cache}`;
                vod.path = videoPath;
                setVod({ ...vod });
            }

            // let sageMakerRes = await startSageMaker(type, sageInput);
            // console.log(`sagemaker response : ${Date.now()} delay(${(Date.now() - startTime) / 1000} sec) :`, sageMakerRes);
            // let videoPath = ``;
            // if (sageMakerRes && sageMakerRes !== "timedout") {
            //     videoPath = `${sageMakerRes.upload[0].cache}`;
            //     vod.path = videoPath;
            //     setVod({ ...vod });
            // }

            // speak.path = videoPath;
            // setSpeak({ ...speak });

            // startTime = Date.now();
            // console.log(`convert url payload: ${startTime} ${videoPath}`);
            // let convertRes = await convertUrl(videoPath);
            // console.log(`convert url response : ${Date.now()} delay(${(Date.now() - startTime) / 1000} sec) :`, convertRes);

            // speak.path = convertRes.playlist;
            // setSpeak({ ...speak });

            if (type === "nerf") {
                setHistory(chat.id, { vidurl1: videoPath });
            } else if (type === "fast") {
                setHistory(chat.id, { vidurl: videoPath });
            }

            updateChat({
                id: chat.id,
                updatedValues: {
                    vidurl1: type === "nerf" ? videoPath : "",
                    vidurl: type === "fast" ? videoPath : "",
                    playvid: true,
                },
            });
        } catch (error) {
            console.log("wav2lipAsync error: ", error);
        }
    };

    const handleSession = async ({ opr, session, model, append, audioonly }) => {
        switch (opr) {
            case "list":
                let chatSessionsRes = await getAiChatByModelid(model.id);
                //  console.log("chat sessions :: ", chatSessionsRes);

                if (chatSessionsRes && chatSessionsRes.length > 0) {
                    setChatSessions([...chatSessionsRes]);
                } else {
                    setChatSessions([]);
                }

                break;

            case "create":
                setFetchingChatbox(true);

                if (currentSession) {
                    setCurrentSession(null);
                    setChats([]);
                }

                let createSessRes = await createSession({ modelid: model.id, audioonly });

                console.log("created chat session :: ", createSessRes);
                let resdata = createSessRes;

                createSessRes.audioonly = audioonly === "true" ? audioonly : "false";
                setCurrentSession(createSessRes);

                await handleSession({ opr: "list", model });
                setFetchingChatbox(false);

                return resdata;

            case "create-only":
                setFetchingChatbox(true);

                let createOnlySessRes = await createSession({ modelid: model.id });

                console.log("create only chat session :: ", createOnlySessRes);
                let resdataonly = createOnlySessRes.data[0];

                setFetchingChatbox(false);
                return resdataonly;

            case "delete":
                setFetchingChatbox(true);

                await deleteSession(session.id);
                if (session.id === currentSessionRef?.current?.id) {
                    setChats([]);
                    setCurrentSession(null);
                }
                await handleSession({ opr: "list", model });

                setFetchingChatbox(false);
                break;

            case "getHistory":
                if (pageNum && session) {
                    setFetchingResponse(false);
                    // if(!append){
                    //setChats([]);
                    //  }
                    let historyRes = await getHistoryByUser(getStoredUser()?.id, session.id, pageNum);
                    //console.log(`get history res page${pageNum}:`, historyRes);
                    historyRes = historyRes.reverse();
                    let historyChats = [];
                    if (historyRes && historyRes.length > 0) {
                        historyRes.map((history) => {
                            // const findHistory = chatsRef.current.find((chat) => chat.id === history.id);
                            // if (!findHistory) {
                            let msgUser = {
                                createdate: history.createdate,
                                text: history.usertext,
                                from: "You",
                                type: "sent",
                            };
                            historyChats.push(msgUser);
                            // let msgModel = {...history}

                            let msgModel = {
                                createdate: history.createdate,
                                id: history.id,
                                image: history.image,
                                vidurl: history.vidurl,
                                vidurl1: history.vidurl1,
                                text: history.modeltext,
                                from: model.name,
                                ishistory: true,
                                type: "received",
                            };
                            historyChats.push(msgModel);
                            //}
                        });
                        setChats([...historyChats]);

                        // if(!append){
                        //     setChats([...historyChats, ...chats]);
                        // }else{
                        //     setChats([...historyChats])
                        // }
                    }
                }

                break;
            default:
                break;
        }
    };

    const handleAttach = (file) => {
        let fileReader;
        let fileid = uuidv4();
        console.log(file);
        const handleFileRead = async (e) => {
            let filecontent = fileReader.result;

            let thefile = {
                id: fileid,
                type: file.type,
                src: filecontent,
                status: "uploading",
            };
            let theAttachments = [...attachments, { ...thefile }];

            setAttachments([...theAttachments]);

            // UPLOAD
            const formData = new FormData();
            formData.append(`auth`, getStoredUser()?.auth);

            formData.append(`userid`, getStoredUser()?.id);
            formData.append(`directory`, currentSession.id);
            formData.append(`segment`, thefile);
            formData.append(`uploadfile`, file);

            let res = await axios.post(`${endpoint.upload}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            theAttachments.map((attch) => {
                if (attch.id === fileid) {
                    const uploadedsrc = `${res.data[0].filepaths3}`;
                    attch.src = uploadedsrc;
                    attch.status = "uploaded";
                }
                return { ...attch };
            });

            setAttachments([...theAttachments]);
        };
        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsDataURL(file);
    };

    const handleDetach = (file) => {
        let udaptedAttachments = attachments.filter((attch) => attch.src !== file.src);
        setAttachments([...udaptedAttachments]);
        if (pictureUploadRef.current) {
            pictureUploadRef.current.value = "";
        }
    };

    const handleRecordAudio = () => {
        setIsRecording(!isRecording);
    };

    const scrollToBottom = (behavior) => {
        if (chatsDivRef.current) {
            switch (behavior) {
                case "instant":
                    chatsDivRef.current.scrollTop = chatsDivRef.current.scrollHeight;
                    break;

                default:
                    chatsDivRef.current.scrollTo({
                        top: chatsDivRef.current.scrollHeight,
                        behavior: "smooth",
                    });
                    break;
            }
        }
    };

    const handleBot = async ({ opr, botid, thebot, session }) => {
        if (currentSessionRef.current) {
            let headers = {
                "x-api-key": `${endpoint.key}`,
            };

            let theSession = session ? session : currentSession;

            switch (opr) {
                case "init":
                    if (!botId) {
                        setChatInput("");
                        //setFetchingResponse(true);
                        setBotState({ state: "init" });
                        try {
                            let res = await axios({
                                method: "post",
                                url: `${endpoint.restAws}/bot/init/${videoType.id}`,
                                headers,
                            });

                            console.log(`<- response bot/${opr} :`, res.data.botId, theSession);

                            // setBotId(res.data.botId);
                            setBotId("77d3164e-79fc-4c46-b23f-d49249df0f19");

                            // await new Promise((res) => {
                            //     setTimeout(res, 100);
                            // });

                            // handleBot({ opr: "ping", botid: res.data.botId });
                            // pingBotRef.current = setInterval(() => {
                            //     handleBot({ opr: "ping", botid: res.data.botId });
                            // }, 5000);

                            // await new Promise((res) => {
                            //     setTimeout(res, 100);
                            // });

                            // await handleBot({ opr: "update", botid: botIdRef.current, thebot, session: theSession });
                            // //setBotState({ state: "updated" });

                            // await new Promise((res) => {
                            //     setTimeout(res, 100);
                            // });
                            // await handleBot({ opr: "start", botid: botIdRef.current, session: theSession });
                        } catch (error) {
                            setFetchingResponse(false);
                        }
                    }
                    break;

                case "start":
                    if (botid && botid !== "") {
                        try {
                            let res = await axios({
                                method: "post",
                                headers,
                                url: `${endpoint.restAws}/bot/${botid}/start`,
                            });
                            console.log(`<- response bot/${opr} :`, res);
                            setBotState({ state: "started" });
                        } catch (error) {
                            console.log(`<- ERROR bot/${opr} :`, error);
                        }
                        // setFetchingResponse(false);
                    }

                    break;

                case "stop":
                    if (botIdRef.current && botIdRef.current !== "") {
                        try {
                            let res = await axios({
                                method: "post",
                                headers,
                                url: `${endpoint.restAws}/bot/${botIdRef.current}/stop`,
                            });
                            console.log(`<- response bot/${opr} :`, res);
                            setBotState({ state: "stopped" });
                        } catch (error) {
                            console.log(`<- ERROR bot/${opr} :`, error);
                            setFetchingResponse(false);
                        }
                    }

                    break;

                case "update":
                    if (botid && botid !== "" && session) {
                        const upData = {
                            session: {
                                id: session.id,
                                rawPath: thebot,
                                modelPath: thebot, // include video and face data !IMPORTANT THIS NEEDS TO BE UPDATED
                                // audioOnly: currentSession.audioonly === "true" ? true : false,
                                voiceId: model.voice,
                                voiceData: model.voice2,
                                // voiceData: JSON.stringify([
                                //     0.024352876469492912, -0.0795929953455925, 0.09651323407888412, 0.03234654664993286, -0.02505425550043583, 0.07800919562578201,
                                //     0.11714059114456177, 0.10571622103452682, -0.026193693280220032, 0.024262161925435066, 0.013086672872304916, 0.02118777297437191,
                                //     0.0709088072180748, 0.1108522117137909, 0.06322606652975082, 0.10885848104953766, -0.018606051802635193, 0.05857429280877113,
                                //     -0.011790460906922817, 0.043195534497499466, 0.040501028299331665, 0.12793415784835815, 0.00020398122433107346, -0.029653161764144897,
                                //     0.06058279052376747, -0.03470339626073837, 0.08462663739919662, -0.01330393087118864, -0.043978139758110046, -0.08744227886199951,
                                //     -0.06721945852041245, -0.02459062822163105, 0.06829594820737839, -0.011258203536272049, 0.0587615929543972, 0.020174769684672356,
                                //     0.10410122573375702, 0.014195152558386326, -0.07415774464607239, -0.057497091591358185, -0.04045764356851578, -0.1175219938158989,
                                //     0.09912519156932831, -0.025091715157032013, -0.08495934307575226, 0.07120278477668762, 0.020037813112139702, 0.013993991538882256,
                                //     0.08040254563093185, 0.07516029477119446, -0.06237556040287018, -0.00560593931004405, 0.07415476441383362, -0.042556032538414,
                                //     0.19655944406986237, -0.08408293128013611, -0.06329617649316788, 0.035477131605148315, 0.08541905134916306, -0.026727451011538506,
                                //     0.03694029152393341, 0.05398166552186012, -0.062430694699287415, -0.10512454807758331, 0.024901170283555984, -0.01751178875565529,
                                //     0.12893955409526825, 0.06317213922739029, -0.016771996393799782, -0.0376984179019928, 0.07714028656482697, -0.042599648237228394,
                                //     0.05628872290253639, -0.006058503407984972, 0.05198816955089569, 0.04198359698057175, 0.06838256865739822, -0.012141373939812183,
                                //     -0.06955272704362869, -0.05334799736738205, 0.009628304280340672, 0.006844992283731699, -0.03861363232135773, 0.12991999089717865,
                                //     -0.033123042434453964, -0.10982740670442581, 0.038719430565834045, 0.02944677136838436, 0.04701204597949982, 0.08395223319530487,
                                //     -0.034323256462812424, -0.03583408519625664, -0.015480583533644676, 0.0048130168579518795, 0.053233109414577484, -0.058359917253255844,
                                //     0.061929166316986084, 0.021432990208268166, -0.05069312825798988, 0.0930444598197937, 0.020872557535767555, -0.00022156168415676802,
                                //     0.12341200560331345, 0.09808707982301712, 0.03577093407511711, 0.08244865387678146, -0.03701452910900116, -0.07328739762306213,
                                //     0.12374996393918991, 0.007199258077889681, -0.025999702513217926, 0.015358216129243374, -0.010024159215390682, -0.10960175096988678,
                                //     -0.029997417703270912, 0.18420837819576263, 0.04174439236521721, -0.057178277522325516, 0.0486086830496788, 0.14042185246944427,
                                //     0.10544780641794205, 0.06063293665647507, 0.01796002686023712, -0.024557411670684814, 0.02406846359372139, 0.08915075659751892,
                                //     0.020250685513019562, -0.038184087723493576, -0.158383309841156, -0.11263561248779297, -0.12466248869895935, -0.04619666561484337,
                                //     -0.06934709846973419, 0.09849907457828522, 0.010112756863236427, -0.06861189752817154, -0.045648589730262756, -0.0861363559961319,
                                //     -0.007480570115149021, 0.12407255172729492, 0.13764475286006927, 0.0913444310426712, -0.008387972600758076, 0.0793902575969696,
                                //     0.06182701885700226, 0.009881002828478813, 0.061830755323171616, 0.06501966714859009, -0.09335026144981384, -0.08211749792098999,
                                //     0.03628707677125931, 0.050376828759908676, -0.037136875092983246, 0.06907516717910767, -0.08581896871328354, -0.04460665211081505,
                                //     0.04782356321811676, 0.07025742530822754, 0.08545660972595215, 0.025687983259558678, 0.01982387900352478, -0.22168968617916107,
                                //     -0.0530119352042675, -0.03377775102853775, 0.03418970853090286, 0.2024589627981186, 0.13130110502243042, -0.059311360120773315,
                                //     0.025126352906227112, -0.032431021332740784, -0.076346255838871, -0.07956558465957642, 0.015459401533007622, 0.052444279193878174,
                                //     0.03238639608025551, -0.16298791766166687, 0.09801552444696426, -0.019003702327609062, 0.01793990470468998, -0.046171557158231735,
                                //     0.1564195454120636, -0.0228763185441494, -0.07749677449464798, -0.03802260756492615, 0.051283419132232666, -0.06379225850105286,
                                //     -0.0475638248026371, -0.02648727037012577, -0.03971964493393898, -0.05802498385310173, -0.06638197600841522, -0.004638445097953081,
                                // ])
                            },
                        };

                        if (session.audioonly === "true") {
                            upData.session.audioOnly = true;
                        }

                        console.log("-> sending update bot :", upData);
                        headers["x-squadz-user-id"] = getStoredUser()?.id;

                        try {
                            let res = await axios({
                                method: "put",
                                headers,
                                url: `${endpoint.restAws}/bot/${botid}`,
                                data: upData,
                            });
                            console.log(`<- response bot/${opr} :`, res);
                            // return res;
                            // setBotState({ state: "updated" });
                        } catch (error) {
                            console.log(`<- ERROR bot/${opr} :`, error);
                            setFetchingResponse(false);
                        }
                    }
                    break;

                case "ping":
                    if (botid && botid !== "") {
                        try {
                            let res = await axios({
                                method: "post",
                                headers,
                                url: `${endpoint.restAws}/bot/${botid}/ping`,
                            });
                            // console.log(`ping ${botid}`);
                        } catch (error) {
                            console.log(`<- ERROR bot/${opr} :`, error);
                        }
                    }
                    break;

                case "status":
                    if (botid && botid !== "") {
                        try {
                            let res = await axios({
                                method: "get",
                                headers,
                                url: `${endpoint.restAws}/bot/${botid}`,
                            });
                            console.log(`<- response bot/${opr} :`, res);
                        } catch (error) {
                            console.log(`<- ERROR bot/${opr} :`, error);
                        }
                    }
                    break;

                case "check":
                    setHasBots(true);
                    if (currentSession) {
                        try {
                            let res = await axios({
                                method: "get",
                                headers,
                                url: `${endpoint.restAws}/bot/session/${currentSession?.id}`,
                            });
                            console.log(`check has bots ${currentSession?.id} : ${res.data.bots.length}`);
                            if (res.data.bots.length === 0) {
                                setHasBots(false);
                            } else {
                                hasBotsTimer.current = setTimeout(() => {
                                    clearTimeout(hasBotsTimer.current);
                                    handleBot({ opr: "check" });
                                }, 5000);
                            }
                        } catch (error) {
                            console.log(`<- ERROR bot/${opr} :`, error);
                        }
                    }

                    break;

                default:
                    break;
            }
        }
    };

    const addFillerResponse = async (text) => {
        let fillertimer;
        return new Promise((resolve) => {
            fillertimer = setTimeout(() => {
                let responsemsg = {
                    id: "000",
                    text: text,
                    //text: "Give me a second to really think this through.Give me a second to really think this through.Give me a second to really think this through.Give me a second to really think this through.Give me a second to really think this through.",
                    from: model.name,
                    isFiller: true,
                    type: "received",
                };

                setChats([...chatsRef.current, responsemsg]);

                resolve(true);
                clearTimeout(fillertimer);
            }, 800);
        });
    };

    return (
        <>
            {pubChannels && videoType.id === "mp4" && <Pubnuber channels={pubChannels} OnMessage={(msg) => handlePubnubMessage(msg)} />}
            {model && (
                <TTS
                    voiceid={model.voice}
                    chat={chatToAudio}
                    OnState={(chat, audState) => {
                        let updatedChats = chats.map((thechat) => {
                            delete thechat.voiceStatus;
                            if (thechat.id === chat.id) {
                                thechat.voiceStatus = audState;
                                thechat.voiceUrl = chat.voiceUrl;
                            }
                            return { ...thechat };
                        });
                        setChats([...updatedChats]);
                    }}
                />
            )}

            {fetching && <Preloader type={"full"} />}
            <Main noHeader={true}>
                {model && (
                    <div className="chat-container" ref={chatContainerRef}>
                        {/* {fetchingChatbox && <Preloader type={"full-relative"} text={"Please wait..."} />} */}

                        <div className="chatbox-container">
                            <>
                                <div className="bg-blur ">
                                    <img src={model.image === "" ? imgPlaceholder : model.image} />
                                </div>
                                <ChatHeader
                                    model={model}
                                    botId={botId}
                                    botState={botState}
                                    currentSession={currentSession}
                                    videoTypes={videoTypes}
                                    videoType={videoType}
                                    OnHandleBot={(val) => {
                                        handleBot(val);
                                    }}
                                    OnShowSessisons={(val) => {
                                        setShowSessions(val);
                                    }}
                                    OnSelectVideoType={(val) => {
                                        setVideoType(val);
                                    }}
                                    OnSet={(model) => {
                                        setModel(model);
                                    }}
                                />

                                {/*  -----------  CHAT BOX  -----------  */}
                                <div className="chats-box w-3/4 mx-auto" ref={chatsBoxRef}>
                                    {vod.path && (
                                        <div className={`chat-video-on-demand slideFromRight ${vod.expand ? "is-expanded" : ""}`}>
                                            <div className="content">
                                                <div className="actions">
                                                    <div
                                                        className="item"
                                                        onClick={() => {
                                                            setVod({ path: null });
                                                        }}
                                                    >
                                                        <img src={iconXSmall} />
                                                    </div>
                                                    <div
                                                        className="item"
                                                        onClick={() => {
                                                            vod.expand = !vod.expand;
                                                            setVod({ ...vod });
                                                        }}
                                                    >
                                                        <img src={iconExpand} />
                                                    </div>
                                                </div>
                                                {vod.path.indexOf(".mp4") !== -1 ? (
                                                    <VideoPlayer src={vod.path} />
                                                ) : (
                                                    <img className="object-contain w-full h-full" src={vod.path} />
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <div className="chats-box-video-container aspect-square">
                                        <div className="chats-box-video">
                                            {currentSession?.audioonly === "true" && (
                                                <div className="absolute z-10 w-full h-full flex items-center justify-center">
                                                    <div className="absolute z-0 w-full h-full bg-gray-800 left-0 top-0 opacity-30"></div>
                                                    <img className="relative z-10 w-full h-full w-32 h-32 opacity-70" src={iconSpeaker} />
                                                </div>
                                            )}

                                            <img className="m-image aspect-video" src={model.image === "" ? imgPlaceholder : model.image} />

                                            <div className={`flex flex-1 w-full h-full ${currentSession?.audioonly === "true" ? "opacity-0" : ""}`}>
                                                {botId && videoType?.id === "mp4" && (
                                                    <VideoQueuer
                                                        vidChunksQueue={vidChunksQueue}
                                                        //toQueue={msgToPlay}
                                                        silence={model.silence === "" || model.silence === "[{}]" ? model.static : model.silence}
                                                    />
                                                )}
                                                {botId && videoType?.id === "webrtc" && (
                                                    <WebRTC
                                                        model={model}
                                                        webrtcText={webrtcText}
                                                        // currentSession={currentSession}
                                                        botId={botId}
                                                        currentSession={{ id: "2aeac2e1-2a57-4775-921d-c2d40657cbab" }}
                                                        //botId={"77d3164e-79fc-4c46-b23f-d49249df0f19"}

                                                        silence={model.silence === "" || model.silence === "[{}]" ? model.static : model.silence}
                                                        OnSent={() => {
                                                            voiceTextSent.state = true; //this is voice chat
                                                            webrtcSaveId.current = null;
                                                            setVoiceTextSent({ ...voiceTextSent });
                                                        }}
                                                        OnResponse={(response) => {
                                                            setWebrtcThinking(false);
                                                            // console.log(`msgMarkers.current :: ${msgMarkers.current.length}`);

                                                            setMsgJoining(false);
                                                            let mmsg;

                                                            if (!response.chatid) {
                                                                if (msgMarkers.current.length <= 0) {
                                                                    mmsg = {
                                                                        id: "istemp",
                                                                        text: response.data,
                                                                        from: model.name,
                                                                        type: "received",
                                                                        onhold: false,
                                                                    };
                                                                    setChats([...chatsRef.current, mmsg]);
                                                                } else {
                                                                    updateChat({ id: "istemp", appendText: response.data });
                                                                }

                                                                if (response.data === "") {
                                                                    msgMarkers.current.push(response);
                                                                }
                                                                setFetchingResponse(false);
                                                            }

                                                            if (response.chatid) {
                                                                webrtcSaveId.current = response.chatid;
                                                                if (msgMarkers.current.length >= 2) {
                                                                    //REMOVING TEMP
                                                                    updateChat({
                                                                        id: "istemp",
                                                                        updateId: response.chatid,
                                                                    });
                                                                    msgMarkers.current = [];
                                                                    setFetchingResponse(false);
                                                                } else {
                                                                    updateChat({
                                                                        id: "istemp",
                                                                        saveId: response.chatid,
                                                                    });
                                                                }
                                                            }

                                                            if (msgMarkers.current.length >= 2 && webrtcSaveId.current) {
                                                                //REMOVING TEMP
                                                                updateChat({
                                                                    id: "istemp",
                                                                    updateToSaveId: true,
                                                                });
                                                                msgMarkers.current = [];
                                                                setFetchingResponse(false);
                                                            }
                                                        }}
                                                        OnWebrtcStatus={(status) => {
                                                            switch (status) {
                                                                case "disconnected":
                                                                    msgMarkers.current = [];
                                                                    setMsgJoining(false);
                                                                    setFetchingResponse(false);
                                                                    break;

                                                                default:
                                                                    break;
                                                            }
                                                        }}
                                                        OnClose={(state) => {
                                                            msgMarkers.current = [];
                                                            setMsgJoining(false);
                                                            setFetchingResponse(false);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* 
                                        {firstMsg && <div className="firstMessage slideFromBot">
                                            <div className="content">{firstMsg}</div>
                                        </div>} */}

                                    {chatType === "chat" && (
                                        <>
                                            <div className="chats pb-0" ref={chatsDivRef}>
                                                {showChatDefault &&
                                                    !msgJoining &&
                                                    !fetchingResponse &&
                                                    chatsRef.current.length <= 0 &&
                                                    !hasBots &&
                                                    model.modelpath.indexOf("s3:") !== -1 && (
                                                        <ChatsDefaults
                                                            OnSelect={async (message) => {
                                                                const msg = {
                                                                    text: message.text,
                                                                    from: "You",
                                                                    type: "sent",
                                                                };

                                                                setShowChatDefault(false);
                                                                await handleSendChat(msg);
                                                            }}
                                                        />
                                                    )}

                                                {chats.length > 0 &&
                                                    chats.map((chat, c) => {
                                                        return (
                                                            <div
                                                                key={c}
                                                                className={`chat ${chat.cantype ? "cantype" : ""} chat-${chat.type} chat-${
                                                                    chat.id ? chat.id : "you"
                                                                } `}
                                                            >
                                                                {!chat.onhold ? (
                                                                    <>
                                                                        <div className="pt-2 px-2">
                                                                            {chat.genimage && chat.genimage !== "" && (
                                                                                <div className="genimage">
                                                                                    <img src={chat.genimage} />
                                                                                </div>
                                                                            )}

                                                                            <div className="flex flex-row ">
                                                                                {chat.type === "received" && (
                                                                                    <div className="model-image mr-3">
                                                                                        <img src={model.image === "" ? imgPlaceholder : model.image} />
                                                                                    </div>
                                                                                )}

                                                                                <div className="flex flex-col">
                                                                                    <div className="flex flex-row justify-content-between items-center">
                                                                                        <small className="opacity-50 mb-1">{chat.from}</small>
                                                                                    </div>

                                                                                    <div className="leading-5">
                                                                                        {chat.type === "received" && !chat.ishistory ? (
                                                                                            <TypeText
                                                                                                // isCompleted={chat.pubstatus}
                                                                                                isCompleted={pubMarker.current === "end" ? true : false}
                                                                                                text={chat.text}
                                                                                                OnType={() => {
                                                                                                    scrollToBottom();
                                                                                                }}
                                                                                                OnFinished={() => {
                                                                                                    console.log("(!) finished typing text");
                                                                                                    scrollToBottom();
                                                                                                }}
                                                                                            />
                                                                                        ) : (
                                                                                            chat.text
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {chat.voiceUrl && chat.playvoice && (
                                                                            <VideoPlayer isAudio={true} src={chat.voiceUrl} play={chat.playvoice} />
                                                                        )}
                                                                        {chat.from !== "You" && videoType.id === "mp4" && (
                                                                            <div className="chat-actions fadeIn min-h-7">
                                                                                {chat.id && chat.id.indexOf("istemp") === -1 && !chat.isFiller && (
                                                                                    <>
                                                                                        {chat.image && chat.image.indexOf("http") !== -1 && (
                                                                                            <div
                                                                                                className="item pointer"
                                                                                                onClick={() => {
                                                                                                    vod.path = chat.image;
                                                                                                    setVod({ ...vod });
                                                                                                }}
                                                                                            >
                                                                                                <div className="aspect-square w-6 h-6 rounded-md bg-black relative overflow-hidden">
                                                                                                    <img
                                                                                                        src={chat.image}
                                                                                                        className="object-cover w-full h-full"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        )}

                                                                                        {model.fast0 && (
                                                                                            <div
                                                                                                className={`item `}
                                                                                                onClick={async () => {
                                                                                                    if (chat.vidurl && chat.vidurl.indexOf("http") !== -1) {
                                                                                                        if (chat.vidurl === vod.path) {
                                                                                                            vod.path = null;
                                                                                                        } else {
                                                                                                            vod.path = chat.vidurl;
                                                                                                        }

                                                                                                        setVod({ ...vod });
                                                                                                    } else {
                                                                                                        await handleSpeak("fast", chat, chat.text);
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                {chat.vidurl === "fetching" ? (
                                                                                                    <i className={"iicon iicon-loader-small spinning"} />
                                                                                                ) : (
                                                                                                    <i className="iicon iicon-play-small link link-icon"></i>
                                                                                                    // <img className={"icon"} src={iconPlaySmall} />
                                                                                                )}
                                                                                            </div>
                                                                                        )}

                                                                                        {model.voice !== "" && (
                                                                                            <div className="item">
                                                                                                {chat.voiceStatus === "playing" && (
                                                                                                    <i className={"iicon iicon-audio-small"} />
                                                                                                )}

                                                                                                {chat.voiceStatus === "fetching" && (
                                                                                                    <i className={"iicon iicon-loader-small spinning"} />
                                                                                                )}

                                                                                                {chat.voiceStatus !== "fetching" &&
                                                                                                    chat.voiceStatus !== "playing" && (
                                                                                                        <i
                                                                                                            className="iicon iicon-audio-small link link-icon"
                                                                                                            onClick={() => {
                                                                                                                setChatToAudio({ ...chat });
                                                                                                            }}
                                                                                                        ></i>
                                                                                                    )}
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {botIdRef.current && (
                                                                            <div className="fetching-response opacity-70 p-3  slideFromTop loading">
                                                                                {model.name} is thinking
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        );
                                                    })}

                                                {chats.length === 0 && <div></div>}

                                                {msgJoining && (
                                                    <div className="opacity-70 p-3  chat chat-joining text-left loading">{model.name} is joining</div>
                                                )}

                                                {webrtcThinking && !msgJoining && (
                                                    <div className="opacity-70 p-3 pb-0 chat chat-joining text-left loading">{model.name} is thinking</div>
                                                )}
                                            </div>

                                            <div ref={chatsEndRef}></div>
                                        </>
                                    )}

                                    {chatType === "voice" && (
                                        <div
                                            className={`flex justify-center p-5  relative opacity-70 voice-status-text slideFromTop ${
                                                voiceStatusText.indexOf("thinking") !== -1 ? "loading" : ""
                                            }`}
                                        >
                                            {voiceStatusText}
                                        </div>
                                    )}
                                </div>

                                {/*  -----------  CHAT INPUT -----------  */}

                                {!hasBots && !msgJoining ? (
                                    <div className="flex py-7  w-3/4 mx-auto" style={{ minHeight: "56px" }}>
                                        {chatType === "chat" &&
                                            model &&
                                            model.modelpath.indexOf("s3:") !== -1 &&
                                            model.static !== "" &&
                                            model.static !== "[{}]" && (
                                                <div className="flex-1 flex flex-row md:items-center gap-3">
                                                    <div className="chat-input flex-col justify-start flex-1 p-5 rounded-2xl">
                                                        <>
                                                            {isRecording && (
                                                                <AudioRecorderWithLevels
                                                                    session={currentSession}
                                                                    OnTranscribe={async (text) => {
                                                                        setIsRecording(false);
                                                                        let msg = {
                                                                            text: text,
                                                                            from: "You",
                                                                            type: "sent",
                                                                        };
                                                                        await handleSendChat(msg);
                                                                    }}
                                                                    OnCancel={() => {
                                                                        setIsRecording(false);
                                                                    }}
                                                                />
                                                            )}
                                                            {!isRecording && (
                                                                <div className="flex flex-1 flex-col relative">
                                                                    {/* <Preloader type="full-relative" textOnly={'transcribing audio, please wait...'}/> */}
                                                                    <div className="flex flex-1  slideFromTop text-base md:text-xs">
                                                                        <input
                                                                            className="p-0"
                                                                            type="text"
                                                                            placeholder={`${
                                                                                attachments.length > 0
                                                                                    ? "What do want to do with your attached image..."
                                                                                    : "Say something nice..."
                                                                            }`}
                                                                            value={chatInput}
                                                                            onChange={(e) => {
                                                                                setChatInput(e.target.value);
                                                                            }}
                                                                            onKeyDown={async (e) => {
                                                                                if (e.key === "Enter" && e.target.value !== "" && !fetchingResponse) {
                                                                                    let msg = {
                                                                                        text: e.target.value,
                                                                                        from: "You",
                                                                                        type: "sent",
                                                                                    };

                                                                                    await handleSendChat(msg);
                                                                                }
                                                                            }}
                                                                        />
                                                                        <div className="chat-input-actions">
                                                                            <div className="flex flex-1">
                                                                                {videoType.id === "mp4" && (
                                                                                    <div className="transition-all item hidden  md:flex">
                                                                                        {attachments.length === 0 && (
                                                                                            <i className={`iicon iicon-picture link link-icon`}>
                                                                                                <input
                                                                                                    ref={pictureUploadRef}
                                                                                                    type="file"
                                                                                                    accept="image/*"
                                                                                                    className="input-file"
                                                                                                    onChange={(e) => {
                                                                                                        handleAttach(e.target.files[0]);
                                                                                                    }}
                                                                                                />
                                                                                            </i>
                                                                                        )}
                                                                                    </div>
                                                                                )}

                                                                                {/* <div className="item">
                                                                                    {hasWebcam && (
                                                                                        <i
                                                                                            className="iicon iicon-mic link link-icon"
                                                                                            onClick={() => {
                                                                                                handleRecordAudio();
                                                                                            }}
                                                                                        ></i>
                                                                                    )}
                                                                                </div> */}
                                                                            </div>

                                                                            {fetchingResponse === false && (
                                                                                <div className="item">
                                                                                    <div
                                                                                        className="pl-4 pr-0  "
                                                                                        onClick={async () => {
                                                                                            if (chatInput !== "") {
                                                                                                let msg = {
                                                                                                    text: chatInput,
                                                                                                    from: "You",
                                                                                                    type: "sent",
                                                                                                };
                                                                                                await handleSendChat(msg);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <Iicon
                                                                                            icon={"send"}
                                                                                            className={`link link-icon ${chatInput === "" ? "opacity-25" : ""}`}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {attachments.length > 0 && (
                                                                        <div className="flex gap-2 chat-input-attachments p-0 pt-4">
                                                                            {attachments.map((attachment, a) => (
                                                                                <div key={a} className="item m-0">
                                                                                    {/* {attachment.status !== 'uploading' &&  */}
                                                                                    <div
                                                                                        className="button-x"
                                                                                        onClick={() => {
                                                                                            handleDetach(attachment);
                                                                                        }}
                                                                                    >
                                                                                        <img src={iconXWhite} />
                                                                                    </div>
                                                                                    {/* }  */}
                                                                                    {attachment.status === "uploading" && (
                                                                                        <div className="loader">
                                                                                            <i className="iicon iicon-loader-small-white spinning" />
                                                                                            {/* <img className={"icon spinning"} src={iconLoaderSmall} /> */}
                                                                                        </div>
                                                                                    )}
                                                                                    <div className="attachment">
                                                                                        <img src={attachment.src} />
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </>
                                                    </div>
                                                    {hasWebcam && (
                                                        <div className="flex p-5 bg-lightgray rounded-2xl items-center transition-all">
                                                            <i
                                                                className="iicon iicon-mic link link-icon"
                                                                onClick={() => {
                                                                    setChatType("voice");
                                                                }}
                                                            ></i>
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                        {chatType === "voice" && (
                                            <VoiceChat
                                                //pc={pc}
                                                model={model}
                                                currentSession={currentSession}
                                                voiceTextSent={voiceTextSent}
                                                OnTextPrompt={async (text) => {
                                                    setVoiceStatusText(`${model.name} is thinking`);
                                                    const msg = {
                                                        text: text,
                                                        from: "You",
                                                        type: "sent",
                                                    };
                                                    await handleSendChat(msg);

                                                    voiceTextSent.state = false;
                                                    setVoiceTextSent({ ...voiceTextSent });
                                                    setVoiceStatusText("Start speaking");
                                                }}
                                                OnSetChatType={(val) => {
                                                    setChatType(val);
                                                }}
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <div className="flex py-7  w-3/4 mx-auto text-center items-center justify-center" style={{ minHeight: "56px" }}>
                                        <div className="chat-input flex-col justify-start flex-1 p-5 rounded-2xl">
                                            <span className="loading opacity-40">Preparing session. Please wait</span>
                                        </div>
                                    </div>
                                )}
                            </>
                        </div>

                        {/*  -----------  CHAT SESSIONS  -----------  */}

                        <div className={`chat-sessions ` + (!showSessions ? "chat-sessions-hide" : "")}>
                            <div className="model-profile-box">
                                {model && model.userid === getStoredUser()?.id && (
                                    <div className="model-actions">
                                        <Iicon icon="more">
                                            <div
                                                className="link flex gap-2"
                                                onClick={() => {
                                                    navigate(`/character/${model.id}`, { state: { mainTab: "Configure" } });
                                                }}
                                            >
                                                <Iicon icon={"edit"} />
                                                <div>Edit</div>
                                            </div>

                                            {/* <div className="link">Hide</div> */}
                                        </Iicon>
                                    </div>
                                )}

                                <div className="model-image  mb-5">
                                    {/* {msgToPlay && chatType === "chat" ? <VideoQueuer toQueue={msgToPlay} /> : <img src={model.image} />} */}
                                    <img src={model.image === "" ? imgPlaceholder : model.image} />
                                </div>

                                <div className="model-name text-base mb-5">
                                    <div className="flex-1 font-semibold" title={model.name}>
                                        {model.name.length > 30 ? `${model.name.substring(0, 30)} ...` : model.name}
                                    </div>
                                    {/* <div className="opacity-40 text-sm">Created by: {model.userid}</div> */}
                                </div>

                                <div className="model-desc opacity-40 leading-5" title={model.description}>
                                    {model.description.length > 180 ? `${model.description.substring(0, 180)} ...` : model.description}
                                </div>
                                {model.tags !== "" && (
                                    <div className="model-tags">
                                        {model.tags.split(",").map((tag, t) => (
                                            <span key={t}>{tag.trim()}</span>
                                        ))}
                                    </div>
                                )}

                                {/* <div className="model-caps mt-3">
                                    {model.fast0 !== "" && <i className="iicon iicon-video" title="Can reply with video"></i>}
                                    {model.voice !== "" && <i className="iicon iicon-speaker" title="Can reply with audio"></i>} 
                                    <i className="iicon iicon-txt2img" title="Can provide image"></i>
                                </div> */}
                            </div>

                            <div className="bg-light-gray flex flex-col rounded-2xl flex-1 m-4 overflow-hidden relative">
                                <div className="chat-sessions-wrapper">
                                    <div className="chat-sessions-list">
                                        <div className="small opacity-25 mb-2 ps-2">Recent Chats </div>

                                        {chatSessions &&
                                            chatSessions.length > 0 &&
                                            chatSessions.map((session, s) => (
                                                <div
                                                    key={s}
                                                    data-sessionid={session.id}
                                                    className={`item ` + (currentSession?.id === session.id ? "item-selected" : "")}
                                                >
                                                    <div className="flex flex-row flex-1">
                                                        <div
                                                            className="flex flex-col flex-1 mr-2"
                                                            onClick={() => {
                                                                setChats([]);

                                                                if (session.id === currentSession?.id) {
                                                                    setCurrentSession(null);
                                                                } else {
                                                                    setCurrentSession(session);
                                                                }
                                                            }}
                                                        >
                                                            <div className="flex gap-4">
                                                                <div className="mt-1">
                                                                    {session.audioonly === "true" ? <Iicon icon={"speaker"} /> : <Iicon icon={"video"} />}
                                                                </div>
                                                                <div>
                                                                    <div className="name font-semibold">
                                                                        {session.name !== ""
                                                                            ? session.name.length > 20
                                                                                ? `${session.name?.substring(0, 80)}...`
                                                                                : session.name
                                                                            : session.summary !== ""
                                                                            ? `${session.summary.substring(0, 80)}...`
                                                                            : "Untitled"}
                                                                    </div>
                                                                    <div className="small text-xs opacity-40 mt-1">{dateFormatter(session.createdate)}</div>
                                                                    {/* <div className="small opacity-25">{session.id}</div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="item-actions flex flex-0 items-center">
                                                            <i
                                                                className="iicon iicon-trash"
                                                                onClick={() => {
                                                                    handleSession({ opr: "delete", session, model });
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                        {chatSessions && chatSessions.length <= 0 && <div className="p-2 opacity-50">You don't have any recent chats.</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="chat-sessions-actions pt-0">
                                <button className="button flex flex-1 p-0">
                                    {showNewChatMore && (
                                        <div className="button-more bg-black flex flex-col slideMore">
                                            <div
                                                className="flex justify-between p-5 border-b border-b-gray-600"
                                                onClick={() => {
                                                    handleSession({ opr: "create", model });
                                                    setShowNewChatMore(false);
                                                }}
                                            >
                                                <div>Video & Audio</div>
                                                <Iicon icon={"video-white"} />
                                            </div>
                                            <div
                                                className="flex justify-between p-5  border-b border-b-gray-600"
                                                onClick={() => {
                                                    handleSession({ opr: "create", model, audioonly: "true" });
                                                    setShowNewChatMore(false);
                                                }}
                                            >
                                                <div>Audio Only</div>
                                                <Iicon icon={"speaker-white"} />
                                            </div>

                                            <div
                                                className="flex justify-between p-5"
                                                onClick={() => {
                                                    setShowNewChatMore(false);
                                                    // handleSession({ opr: "create", model });
                                                }}
                                            >
                                                <div>Cancel</div>
                                                <Iicon icon={"x-white"} />
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        className="flex flex-1 p-4"
                                        onClick={() => {
                                            setShowNewChatMore(true);
                                            // handleSession({ opr: "create", model });
                                        }}
                                    >
                                        <div className="flex-1">New Chat</div>
                                        <Iicon icon={"plus-white"} className={""} />
                                    </div>
                                </button>
                                {/* <div
                                        className="item flex-0 item-icon flex items-center"
                                        onClick={() => {
                                            setShowSessions(false);
                                        }}
                                    >
                                        <i className="iicon iicon-collapse" />
                                    </div> */}
                            </div>
                        </div>
                    </div>
                )}
            </Main>
        </>
    );
};

export default Chat;
