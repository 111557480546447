import React, { useRef, useEffect } from "react";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js'

const MicWaveform = ({ audioUrl }) => {
    const waveformRef = useRef(null);

  

    useEffect(() => {
        const wavesurfer = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: "#332668",
            progressColor: "#9147CA",
            cursorColor: "NAN",
            barWidth: 2,
            barGap: 4,
            barHeight:5,
         
            height: 30,
            width:"100%",
            autoplay: true,
        });

        const record = wavesurfer.registerPlugin(RecordPlugin.create({ scrollingWaveform:false, renderRecordedAudio: false }))

        record.startRecording()

        // wavesurfer.on("interaction", () => {
        //     wavesurfer.play();
        // });

        return () => wavesurfer.destroy();
    }, []);

    return <div ref={waveformRef} />;
};

export default MicWaveform;




