import React, { useEffect, useState, useRef } from "react";
import iconPlay from "src/assets/images/icon-play.svg";
import iconPause from "src/assets/images/icon-pause.svg";

import "./video-player.css";
import { epochToDate, isJsonString, groupVideosByDirectory } from "../utils";
import VideoSilent from "./video-silent";
import VideoVoice from "./video-voice";

const VideoQueuer = (props) => {
    const { silence } = props;
    let vidRef = useRef();
    let vCounter = useRef([]);
    const [vari, setVari] = useState(0);
    const [silentLoaded, setSilentLoaded] = useState(false);
    const [varData, setVarData] = useState([]);

    const [showVoice, setShowVoice] = useState(false);
    const [showSilent, setShowSilent] = useState(true);

    // const varData = [
    //     { url: "https://squadz-dev-bucket-02.s3.amazonaws.com/4b07b6f7-40fc-4521-b6a6-ec4d986f0369_3/0000_10/", clips: 10, color: "red" },
    //     { url: "https://squadz-dev-bucket-02.s3.amazonaws.com/4b07b6f7-40fc-4521-b6a6-ec4d986f0369_3/0001_10/", clips: 10, color: "green" },
    //     { url: "https://squadz-dev-bucket-02.s3.amazonaws.com/4b07b6f7-40fc-4521-b6a6-ec4d986f0369_3/0002_3/", clips: 3, color: "blue" },
    // ];

    useEffect(() => {
        if (showVoice) {
            const rand = Math.floor(Math.random() * varData.length);
            setVari(rand);
        }
    }, [showVoice]);

    useEffect(() => {
        if (silence && isJsonString(silence)) {
            let sparsed = JSON.parse(silence);
            let grouped = groupVideosByDirectory(sparsed);
            console.log(grouped);
            setVarData([...grouped]);
        }
    }, [silence]);


    return (
        <>
            {/* <video ref={vidRef} style={{ width: 0, height: 0, position: "absolute", opacity: 0 }} /> */}
            <div className="video-player-wrapper">
                <div className="video-player-players relative">
                    
                    {showSilent && varData.length > 0 && (
                        <div className={`silent`}>
                            {/* <div
                                className={`bg-${varData[vari].color} opacity-10 `}
                                style={{
                                    backgroundColor: varData[vari].color,
                                    position: "absolute",
                                    left: 0,
                                    top: 0,
                                    zIndex: 999,
                                    width: "100%",
                                    height: "100%",
                                }}
                            ></div> */}
                            <VideoSilent
                                vdata={varData[vari]}
                                // OnLoaded={()=>{setShowVoice(false)}}
                            />
                        </div>
                    )}

                    <div className={`voice ${showVoice ? "opacity-100" : "opacity-0"}`}>
                        <VideoVoice
                            {...props}
                            showVoice={showVoice}
                            OnLoaded={() => {
                                setShowVoice(true);
                                setShowSilent(false);
                            }}
                            OnPrepSilent={() => {
                                setShowSilent(true);
                            }}
                            OnFinished={() => {
                                //setShowSilent(true);
                                setShowVoice(false);
                            }}
                            OnPlaying={(vidObj)=>{
                                if(props.OnPlaying){
                                   
                                    props.OnPlaying(vidObj)
                                }
                            }}

                        />
                    </div>

                </div>
            </div>
        </>
    );
};

export default VideoQueuer;
