let recognition = null;
let lastAddedFinalResultIndex = -1;
let state;

export function startDictation(receivedEventsCallback) {
    recognition = null
    if (state !== "started") {
      //  console.log("start dictation");

        recognition = new window.webkitSpeechRecognition();
        recognition.continuous = true;
        recognition.interimResults = true;
        recognition.lang = "en-US";

        recognition.onerror = (error) => {
            console.log("error", error);
        };

        recognition.onresult = (event) => {
            const newMessages = [];
            Array.from(event.results).forEach((result, resultIndex) => {
                if (result.isFinal && resultIndex > lastAddedFinalResultIndex) {
                    // console.log(result[0].transcript);
                    lastAddedFinalResultIndex = resultIndex;
                    if (result[0].transcript && result[0].transcript !== "") {
                        newMessages.push(result[0].transcript);
                    }
                }
            });

            if (newMessages.length > 0) {
                const transcription = newMessages.join(" ").trim();
                receivedEventsCallback(transcription);
            }
        };

        recognition.start();

        recognition.onstart = () => {
            state = "started";
        };

        recognition.onsoundend = () => {
           // console.log("soundend");
        };
        recognition.onsoundstart = (event) => {
          //  console.log("soundstart");
        };
        recognition.onspeechend = () => {
           // console.log("speechend");
        };
        recognition.onspeechstart = (event) => {
          //  console.log("speechstart");
        };
    }
}

export function restartDictation() {
    recognition && recognition.stop();
  //  console.log("stop dictation, for restart...");

    if(recognition){
        recognition.onend = (event) => {
           // console.log("ended, restarting...");
            try {
               // console.log("restart dictation");
                lastAddedFinalResultIndex = -1;
                recognition && recognition.start();
            } catch (error) {
                console.log("error on restart :", error);
            }
        };
    }
}

export function stopDictation() {
    //console.log("stop dictation");
    state = "stopped";
  //  recognition && recognition.abort();
    recognition && recognition.stop();
    recognition = null
}
