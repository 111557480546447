import { useEffect } from "react";


import iconBot from 'src/assets/images/icon-bot.svg'
import iconMan from 'src/assets/images/icon-man.svg'

import Header from "src/layouts/header/header";
import Main from "src/layouts/main/main";
import Footer from "src/layouts/footer";
import {Section, SectionItem} from "src/layouts/section";
import Button from "src/components/ui/button";


const About = () => {
    useEffect(()=>{
        console.log('About')
    })


    return (
    <div className="page">
        <Header />
        <Main>


            <Section sectionStyle={`hero`}>
                <SectionItem>
                    <div className="ps-5">
                    <h2 className="mb-4">Avatarz is where you connect with your<br />favorite stars</h2>
                    <p className="mb-5">Access thousands of celebrities and request a personalized video message for any occasion.</p>
                    <Button inline={true}>Browse avatarz</Button>
                    </div>
                </SectionItem>

                <SectionItem>
                    <div className="card-avatar" style={{height:400}}>
                        <img src={iconBot} className='default-img' />
                    </div>
                </SectionItem>
            </Section>

        
            <h3 className="section-title text-center">How it works</h3>
        
            
            <Section>
                <SectionItem>
                    <div className="card-avatar" style={{height:400}}>
                        <img src={iconBot} className='default-img' />
                    </div>
                </SectionItem>

                <SectionItem>
                    <div className="section-num">1</div>
                    <div className="position-relative ps-5">
                        <p className="blue text-uppercase">Search for an avatar</p>
                        <h3 className="mb-4">Find the right avatar for <br />any occasion</h3>
                        <p className="mb-5">Birthdays, milestones, or even a well-deserved roast, the perfect celebrity<br />is only a search away. Find yours and request them.</p>
                        <Button inline={true}>Find an avatar</Button>
                    </div>

                </SectionItem>
            </Section>



           <Section> 
                <SectionItem>
                    <div className="section-num">2</div>
                    <div className="position-relative  ps-5">
                        <p className="blue text-uppercase">Request an avatar</p>
                        <h3 className="mb-4">Get your personalized<br />video message</h3>
                        <p className="mb-5">Include all the important details in your request form. After it's submitted, stars have up to 7 days to complete it. Choose our 24hr delivery option if you need it sooner.</p>
                        <Button inline={true}>Learn more</Button>
                    </div>

                </SectionItem>

                <SectionItem>
                    <div className="card-avatar" style={{height:400}}>
                        <img src={iconBot} className='default-img' />
                    </div>
                </SectionItem>
           </Section>




           <Section> 
            
                <SectionItem>
                    <div className="card-avatar" style={{height:400}}>
                        <img src={iconBot} className='default-img' />
                    </div>
                </SectionItem>

                <SectionItem>
                    <div className="section-num">3</div>
                    <div className="position-relative  ps-5">
                        <p className="blue text-uppercase">Share the magic</p>
                        <h3 className="mb-4">Capture the WOW</h3>
                        <p className="mb-5">Magical moments deserve to be shared. Whether you're giving one or receiving a personalized video, we want to see your reaction. Bonus points if you tag us.</p>
                        <Button inline={true}>Get Started</Button>
                    </div>
                </SectionItem>


           </Section>

           




            

            

        </Main>

        <Footer></Footer>
    </div>

    );
}
 
export default About;