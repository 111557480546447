import { useEffect, useState } from "react";

import "./select-multi.css";

const SelectMulti = (props) => {
    const [theOptions, setTheOptions] = useState([]);

    useEffect(() => {
        let valArray = props.value.split(",");
        let updatedOptions = props.options.map((option) => {
            let findSelected = valArray.find((val) => option.name === val);
            if (findSelected) {
                option.selected = true;
            }
            return option;
        });

        setTheOptions(updatedOptions);
    }, [props.value, props.options]);

    const handleSelect = (option) => {
        option.selected = !option.selected;
        setTheOptions([...theOptions]);

        const selectedOptions = theOptions.filter((option) => option.selected);
        if (props.OnSelect) {
            props.OnSelect(selectedOptions);
        }
    };

    return (
        <div className="form-item-input form-item-select-multi">
            {theOptions.map((option, o) => {
                return (
                    <span
                        key={o}
                        className={`item item-${option.selected ? "selected" : ""}`}
                        onClick={() => {
                            handleSelect(option);
                        }}
                    >
                        {option.name}
                    </span>
                );
            })}
        </div>
    );
};

export default SelectMulti;
