import Header from "src/layouts/header/header";
import Main from "src/layouts/main/main";

import Menu from "src/components/menu/menu";
import { plans } from "src/data/plans";
import Button from "src/components/ui/button";
import { useNavigate } from "react-router-dom";

import "./pricing.css";

import iconCheckCircle from "src/assets/images/icon-check-circle.svg";

const Pricing = (props) => {
    const navigate = useNavigate();
    return (
        <Main>
            <div className="box flex flex-1 flex-col">
                <div className="flex items-center my-1">
                    <div
                        className="navi navi-back mr-2"
                        onClick={() => {
                            navigate("/avatars");
                        }}
                    >
                        <i className="iicon iicon-nav-back"></i>
                    </div>
                    <div className="text-base mr-3 font-semibold">Choose your plan</div>
                </div>

                <div className="flex flex-1 items-center">
                    <div className="plans-container slideFromBot">
                        {plans.map((plan, p) => (
                            <div key={p} className="plan">
                                <div className="title mb-4">{plan.name}</div>
                                <div className="desc leading-5">{plan.desc}</div>
                                <div className="price">
                                    {plan.price}
                                    <span>/ month</span>
                                </div>

                                <div className="inclusions">
                                    {plan.inclusions.map((inc, i) => (
                                        <div  key={i} className="item">
                                            {inc.icon && <img src={inc.icon} className="icon" />}
                                            <span>{inc.text}</span>
                                        </div>
                                    ))}
                                </div>

                                <Button>Subscribe</Button>
                            </div>
                        ))}
                    </div>

                    <div className="flex flex-col pricing-sidebar">
                        <h3 className="">
                            Set your
                            <br />
                            companions Free
                        </h3>
                        <div className="mt-5">
                            <div className="pb-3 flex">
                                <img src={iconCheckCircle} className="me-2" /> <span>Access premium characters</span>
                            </div>
                            <div className="pb-3 flex">
                                <img src={iconCheckCircle} className="me-2" /> <span>Priority access to chat servers </span>
                            </div>
                            <div className="pb-3 flex">
                                <img src={iconCheckCircle} className="me-2" /> <span>Resume conversations with long-term memory</span>
                            </div>
                            <div className="pb-3 flex">
                                <img src={iconCheckCircle} className="me-2" /> <span>Access to private AI companion community hub</span>
                            </div>
                            <div className="pb-3 flex">
                                <img src={iconCheckCircle} className="me-2" /> <span>Share conversations with public chats</span>
                            </div>
                            <div className="pb-3 flex">
                                <img src={iconCheckCircle} className="me-2" /> <span>Early access to future features</span>
                            </div>
                        </div>

                        <p className=" mt-5">Money back guarantee</p>
                        <p className="opacity-40 mt-2 leading-5">
                            If you pay on web by card, we reserve the amount when you place your order but only charge once you have received the video.
                        </p>

                        <p className="opacity-40 mt-2 leading-5">
                            If you book a video on web with another payment method, we will always provide a full refund if the celebrity doesn't respond.
                        </p>
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default Pricing;
