import { useNavigate } from "react-router-dom";
import { ReactLenis, useLenis } from "@studio-freight/react-lenis";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useGSAP } from "@gsap/react";

import checkCirc from "src/assets/images/icon-check-circle-big.svg";
import person1 from "src/assets/images/person-1.png";
import person2 from "src/assets/images/person-2.png";
import person3 from "src/assets/images/person-3.png";
import person4 from "src/assets/images/person-4.png";
import person5 from "src/assets/images/person-5.png";
import person6 from "src/assets/images/person-6.png";
import person7 from "src/assets/images/person-7.png";
import person8 from "src/assets/images/person-8.png";
import person9 from "src/assets/images/person-9.png";
import person10 from "src/assets/images/person-10.png";

import person11 from "src/assets/images/person-11.png";
import person12 from "src/assets/images/person-12.png";
import person13 from "src/assets/images/person-13.png";
import person14 from "src/assets/images/person-14.png";
import person15 from "src/assets/images/person-15.png";

import personCreator from "src/assets/images/person-creator.png";
import personInfluencers from "src/assets/images/person-influencers.png";

import graphicMagnifyingGlass from "src/assets/images/graphic-magnifying-glass.svg";
import graphicGift from "src/assets/images/graphic-gift.svg";
import graphicSend from "src/assets/images/graphic-send.svg";

import graphicPlay from "src/assets/images/graphic-play.svg";
import graphicLike from "src/assets/images/graphic-like.svg";

import graphicHashtag from "src/assets/images/graphic-hashtag.svg";
import graphicNotiHeart from "src/assets/images/graphic-noti-heart.svg";

import "src/pages/marketing/marketing.css";
import "./for-users.css";
import Iicon from "src/components/iicon/iicon";
import { useRef } from "react";
import MarketingFooter from "../footer/footer";
import Header from "src/layouts/header/header";
import Menu from "src/components/menu/menu";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, useGSAP);

const ForUsers = (props) => {
    const navigate = useNavigate();
    const main = useRef();
    const lenis = useLenis(({ velocity }) => {
        // gsap.to(".m-header", {
        //     skewY: velocity / 20,
        //     y: -Math.abs(velocity * 2),
        // });
        // gsap.to(".gsap-title", {
        //     skewY: velocity / 10,
        //     y: velocity * 4,
        // });
        // const sliders = gsap.utils.toArray(".slider");
        // sliders.forEach((slider) => {
        //     const sliderItems = gsap.utils.toArray(".item", slider);
        //     sliderItems.forEach((item, i) => {
        //         gsap.to(item, {
        //             opacity: 1,
        //             skewY: velocity / 20,
        //             y: velocity * i,
        //         });
        //     });
        // });
        // const boxHows = gsap.utils.toArray(".box-how");
        // boxHows.forEach((box, b) => {
        //     gsap.to(box, {
        //         opacity: 1,
        //         skewY: velocity / 10,
        //         y: velocity * 2 * b,
        //     });
        // });
        // const boxCis = gsap.utils.toArray(".box-ci");
        // boxCis.forEach((box, b) => {
        //     gsap.to(box, {
        //         opacity: 1,
        //         // skewY: velocity / 10,
        //         y: velocity * 5 * b,
        //     });
        // });
    });

    useGSAP(
        () => {
            gsap.fromTo(
                ".m-header",
                { y: -50, opacity: 0, skewY: 1 },
                {
                    y: 0,
                    opacity: 1,
                    skewY: 0,
                }
            );

            // gsap.to('.bg-violet', {
            //     translateX: '25%',

            //     ease: "power4.out",

            //     scrollTrigger: {
            //         trigger: '.box-creators',
            //         start: `top bottom`,
            //         end: "bottom bottom",
            //         toggleActions: "reverse restart reverse reverse",
            //         scrub: 1,
            //         //markers:true
            //     },
            // });

            // gsap.to('.bg-torq', {
            //     translateX: '-20%',

            //     ease: "power4.out",

            //     scrollTrigger: {
            //         trigger: '.box-creators',
            //         start: `top bottom`,
            //         end: "bottom bottom",
            //         toggleActions: "reverse restart reverse reverse",
            //         scrub: 1,
            //         //markers:true
            //     },
            // });

            // const sliderItems = gsap.utils.toArray(".slider .item");
            // sliderItems.forEach((item) => {
            //     gsap.to(item, {
            //         opacity: 1,
            //         skewY: 0,
            //         scale: 1,

            //         scrollTrigger: {
            //             trigger: item,
            //             start: "100px bottom",
            //             end: "bottom 20%",
            //             toggleActions: "restart restart reverse",
            //             //markers:true
            //             scrub: 1,
            //         },
            //     });
            // });

            // const gsapTitles = gsap.utils.toArray(".gsap-title");
            // gsapTitles.forEach((title) => {
            //     gsap.to(title, {
            //         opacity: 1,
            //         skewY: 0,
            //         scale: 1,

            //         scrollTrigger: {
            //             trigger: title,
            //             start: "100px bottom",
            //             end: "bottom 20%",
            //             toggleActions: "restart restart reverse",
            //             //markers:true
            //             scrub: 1,
            //         },
            //     });
            // });

            // const tlhow = gsap.timeline({ delay: 5 });
            // const boxHows = gsap.utils.toArray(".box-how");
            // boxHows.forEach((box, b) => {
            //     tlhow.to(box, {
            //         translateY: 0,
            //         translateX: 0,
            //         skewY: 0,
            //         scale: 1,
            //         opacity: 1,
            //         ease: "power4.out",

            //         scrollTrigger: {
            //             trigger: box,
            //             start: `${b * 100} bottom`,
            //             end: "bottom 20%",
            //             toggleActions: "reverse restart reverse reverse",
            //             scrub: b,
            //             //markers:true
            //         },
            //     });
            // });
        },
        { scope: main }
    );

    const persons = [
        { img: person1, name: "Alice Johnson", msg: "Hello, Rex! It's a pleasure to see you today." },
        { img: person2, name: "Diana Smith", msg: "Hi there, Bob! I hope you're having a fantastic day." },
        { img: person3, name: "Charlie Williams", msg: "Greetings, Jane! Wishing you a wonderful and productive day ahead." },

        { img: person4, name: "Eve Davis", msg: "Nice to see you, Peter! I trust you're enjoying the moment." },

        { img: person5, name: "Bob Anderson", msg: "Hey, Diana! Your presence brightens up the room. Welcome!" },
        { img: person6, name: "Grace Taylor", msg: "Salutations, Quinn! May your day be filled with joy and success." },
        { img: person7, name: "Frank Miller", msg: "Howdy, Greg! Your positive energy is contagious. Keep shining!" },

        { img: person8, name: "Shiela Miller", msg: "Hello, Henry! It's a pleasure to have you here. Let's make it a great day!" },
        { img: person9, name: "Ivy Wilson", msg: "Hey, Joshua! Your presence adds so much warmth. Welcome to our gathering." },
        { img: person10, name: "Jack Robinson", msg: "Hi, Carl! Wishing you a day filled with exciting opportunities and happiness." },
    ];

    const Slider = () => {
        return (
            <div
                className="slider flex-1 scale-90 md:scale-100"
                onClick={() => {
                    navigate("/avatars");
                }}
            >
                <div className="slider-track">
                    {persons.map((person, p) => (
                        <div key={p} className="item">
                            <img src={person.img} className="s-img" />
                            <div className="chat">
                                <div className="text-xs opacity-50 mb-2">{person.name}</div>
                                <div className="mb-3">{person.msg}</div>
                                <div className="flex gap-2">
                                    <Iicon icon={"video-white"} />
                                    <Iicon icon={"speaker"} />
                                </div>
                            </div>
                        </div>
                    ))}
                    {persons.map((person, p) => (
                        <div key={p} className="item">
                            <img src={person.img} className="s-img" />
                            <div className="chat">
                                <div className="text-xs opacity-50 mb-2">{person.name}</div>
                                <div className="mb-3">{person.msg}</div>
                                <div className="flex gap-2">
                                    <Iicon icon={"video-white"} />
                                    <Iicon icon={"speaker"} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <ReactLenis root>
            <div className="md:hidden">
                <Menu market={true} />
            </div>
            <div className="pgm mx-auto flex flex-col gap-32 md:gap-52" ref={main}>
                <div className="bg-floating opacity-40">
                    <div className="bg-violet"></div>
                    <div className="bg-torq"></div>
                </div>
                <div className="m-header">
                    <div className="box box-60 m-width mx-auto  flex items-center md:mt-5 p-3 p-3 md:px-10 rounded-2xl overflow-hidden">
                        <Header menu={`market`} />
                    </div>
                </div>

                {/* --------------------------------------- SECTION HERO  --------------------------------------- */}
                <div className="m-section m-section-hero">
                    <div className="flex flex-1 flex-col gap-10 items-center justify-center ">
                        <div className="flex items-center justify-center text-center">
                            <div className="a-text leading-tight text-4xl md:text-7xl font-alt-extrabold ">
                                Chat with your favorite
                                <div className="leading-normal">
                                    <span className="rounded-3xl px-4 " style={{ background: "#62E1BB" }}>
                                        influencers
                                    </span>{" "}
                                    and
                                    <span className="rounded-3xl px-4 ml-2 " style={{ background: "#FFB3CA" }}>
                                        creators
                                    </span>
                                    !
                                </div>
                            </div>
                        </div>

                        <div className="flex flex gap-8 items-center justify-start text-center">
                            <a href="/create-character" className="button button-inline text-md md:text-lg font-alt-bold">
                                Try now for free
                            </a>
                            {/* <div className="button button-bordered button-inline text-base font-alt-bold">
                                Learn more
                            </div> */}
                            <div
                                className="link text-md md:text-lg font-alt-bold link-black learn-more"
                                onClick={() => {
                                    lenis.scrollTo(".m-section-how");
                                }}
                            >
                                Learn more
                            </div>
                        </div>
                    </div>
                    <Slider />
                </div>

                {/* --------------------------------------- SECTION  --------------------------------------- */}

                <div className="m-section m-section-how m-width mx-auto flex-1 flex flex-col overflow-hidden gap-10 md:gap-20">
                    <div className="m-section-title flex items-center justify-center text-center w-full px-5  md:w-3/4 mx-auto">
                        <div className="font-alt-extrabold gsap-title leading-tight text-3xl md:text-6xl md:leading-tight">
                            Access{" "}
                            <span className="rounded-3xl px-5" style={{ background: "#C4A8FF" }}>
                                thousands
                            </span>{" "}
                            of celebrities and request a{" "}
                            <span className="rounded-3xl px-4" style={{ background: "#F0C780" }}>
                                personalized
                            </span>{" "}
                            video for any occasion.
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row gap-8 flex-1 md:grid md:grid-cols-3 p-5 overflow-hidden h-full">
                        <div className="box box-how box-how-1 box-60  p-10 flex flex-col overflow-visible flex-1 gap-3 md:gap-10">
                            <h4 className="font-alt-extrabold text-xl leading-tight md:text-3xl md:leading-tight text-center">
                                Search for your Favorite star.
                            </h4>
                            <div className="flex-1 m-graphic m-graphic-1">
                                <div className="box box-1">
                                    <img src={person11} />
                                </div>
                                <div className="box box-2">
                                    <img src={person12} />
                                </div>
                                <div className="box box-3">
                                    <img src={person13} />
                                </div>
                                <img src={graphicMagnifyingGlass} className="icon" />
                            </div>
                            <div className="opacity-50">
                                Birthdays, milestones, or even a well-deserved roast, the perfect celebrity is only a search away. Find yours and request them.
                            </div>
                        </div>

                        <div className="box box-how box-how-2  box-skew box-60  p-10 flex flex-col flex-1 gap-3 md:gap-10">
                            <h4 className="font-alt-extrabold text-xl leading-tight md:text-3xl md:leading-tight text-center">
                                Request a personalized message.
                            </h4>
                            <div className="flex-1 m-graphic m-graphic-2">
                                <div className="box box-1">
                                    <img src={person14} />
                                </div>
                                <div className="box box-2 font-alt-bold">"happy birthday jane! hope you have a wonderful day..."</div>
                                <img src={graphicGift} className="icon" />
                            </div>
                            <div className="opacity-50">
                                Include all the important details in your request form. After it's submitted, stars have up to 7 days to complete it. Choose our
                                24hr delivery option if you need it sooner.
                            </div>
                        </div>

                        <div className="box box-how box-how-3 box-skew p-10 flex  box-60 flex-col flex-1 overflow-hidden gap-3 md:gap-10">
                            <h4 className="font-alt-extrabold text-xl leading-tight md:text-3xl md:leading-tight text-center">Share & capture the magic.</h4>
                            <div className="flex-1 m-graphic m-graphic-3">
                                <div className="box box-1">
                                    <img src={person15} />
                                </div>
                                <img src={graphicSend} className="icon" />
                            </div>
                            <div className="opacity-50">
                                Magical moments deserve to be shared. Whether you're giving one or receiving a personalized video, we want to see your reaction.
                                Bonus points if you tag us.
                            </div>
                        </div>
                    </div>
                </div>

                {/* --------------------------------------- SECTION  --------------------------------------- */}

                <div className="m-section m-width mx-auto flex flex-col gap-5 md:gap-20">
                    <div className="flex flex-col md:flex-row items-center justify-center px-5 gap-10 gsap-title">
                        <h3 className="font-alt-extrabold text-center md:text-right leading-snug">
                            Are you a{" "}
                            <span className="rounded-3xl px-4 ml-2 " style={{ background: "#FFB3CA" }}>
                                Creator
                            </span>{" "}
                            or an{" "}
                            <span className="rounded-3xl px-4 " style={{ background: "#62E1BB" }}>
                                Influncer
                            </span>
                            ?
                        </h3>
                        <div className="text-base pl-5 leading-7 text-center md:text-right">
                            Earn a recurring income by accepting monthly subscriptions for exclusive behind-the-scenes content that your fans will love.
                        </div>
                        <div className="px-5">
                            <a href="/for-creators" className="button button-inline text-base font-alt-bold text-md md:text-lg">
                                Learn more
                            </a>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row gap-10 flex-1 pb-20 md:grid md:grid-cols-2 mt-20 p-5">
                        <div className="box box-ci box-creators box-60 px-10  flex flex-col pt-30 md:pt-72">
                            <img src={graphicPlay} className="icon-1" />
                            <img src={graphicLike} className="icon-2" />
                            <div className="box-avatar">
                                <img src={personCreator} />
                            </div>

                            <h4 className="font-alt-extrabold mb-5">Content Creators</h4>

                            <div className="opacity-50 leading-6 pb-5">
                                Enhance your online presence with an interactive, personalized experience built to pair perfectly with your podcast, book,
                                videos, or course.
                            </div>

                            <div className="flex flex-col gap-6 p-0 pt-5 md:p-10 font-semibold">
                                <div className="flex items-start">
                                    <img src={checkCirc} className="mr-5" /> Answer unlimited audience questions, all of the time.
                                </div>

                                <div className="flex items-start">
                                    <img src={checkCirc} className="mr-5" />
                                    Offer your audience exclusive access to your insights — free or paid.
                                </div>

                                <div className="flex items-start">
                                    <img src={checkCirc} className="mr-5" />
                                    Utilize analytics to refine your content strategy.
                                </div>

                                <div className="flex items-start">
                                    <img src={checkCirc} className="mr-5" />
                                    Boost discoverability by having your content be more prevalent.
                                </div>

                                <div className="flex items-start">
                                    <img src={checkCirc} className="mr-5" />
                                    Monetize exclusive content, earn on affiliate recommendations, and premium access to your clone.
                                </div>
                            </div>
                        </div>

                        <div className="box  box-ci  box-influencers  box-60   p-10 flex flex-col pb-80 md:pb-64">
                            <h4 className="font-alt-extrabold mb-5">Influencers</h4>
                            <div className="opacity-50 leading-6 pb-5">
                                Enhance your online presence with an interactive, personalized experience built to pair perfectly with your podcast, book,
                                videos, or course.
                            </div>

                            <div className="flex flex-col gap-6 p-0 pt-5 md:p-10  font-semibold">
                                <div className="flex items-start">
                                    <img src={checkCirc} className=" mr-5" />
                                    Answer unlimited audience questions, all of the time.
                                </div>

                                <div className="flex items-start">
                                    <img src={checkCirc} className="mr-5" />
                                    Offer your audience exclusive access to your insights — free or paid.
                                </div>

                                <div className="flex items-start">
                                    <img src={checkCirc} className="mr-5" />
                                    Utilize analytics to refine your content strategy.
                                </div>

                                <div className="flex items-start">
                                    <img src={checkCirc} className="mr-5" />
                                    Boost discoverability by having your content be more prevalent.
                                </div>

                                <div className="flex items-start">
                                    <img src={checkCirc} className="mr-5" />
                                    Monetize exclusive content, earn on affiliate recommendations, and premium access to your clone.
                                </div>
                            </div>

                            <img src={graphicHashtag} className="icon-1 " />
                            <img src={graphicNotiHeart} className="icon-2" />
                            <div className="box-avatar">
                                <img src={personInfluencers} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* --------------------------------------- SECTION   --------------------------------------- */}
                <div className="m-section min-h-4 flex flex-col gap-10 md:gap-20">
                    <div className="flex items-center justify-center mb-4">
                        <div className="flex flex-col md:flex-row items-center justify-center p-5 gap-10 m-width mx-auto  gsap-title">
                            <h3 className="font-alt-extrabold text-center md:text-right leading-snug text-3xl md:text-5xl md:leading-snug">
                                Chat with your favorite{" "}
                                <span className="rounded-3xl px-4 " style={{ background: "#62E1BB" }}>
                                    influencers
                                </span>{" "}
                                and
                                <span className="rounded-3xl px-4 ml-2 " style={{ background: "#FFB3CA" }}>
                                    creators
                                </span>
                                !
                            </h3>
                            <div className="text-base pl-5  leading-7 text-center md:text-right">
                                Earn a recurring income by accepting monthly subscriptions for exclusive behind-the-scenes content that your fans will love.
                            </div>
                            <div className="px-5">
                                <a href="/for-creators" className="button button-inline text-base font-alt-bold ">
                                    Learn more
                                </a>
                            </div>
                        </div>
                    </div>
                    <Slider />
                    <MarketingFooter />
                </div>

                {/* --------------------------------------- SECTION   --------------------------------------- */}
            </div>
        </ReactLenis>
    );
};

export default ForUsers;
