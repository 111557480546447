import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export const timelineHero = () => {
    let mheight = document.querySelector(".m-section").offsetHeight;

    let tp = gsap.timeline();

    let aphrases = gsap.utils.toArray(".m-section-hero .aphrase");
    aphrases.forEach((aphrase) => {
        let atexts = gsap.utils.toArray(".aword", aphrase);
        atexts.forEach((atext, i) => {
            tp.from(
                atext,
                {
                    duration: .3,
                    y: 90,
                    // opacity: 0,

                },
            );
        });
    });

    tp.from(".m-section-hero .b-text",{
        duration:.3,
        y:-30,
        opacity:0
    })

    ScrollTrigger.create({
        animation: tp,
        trigger: ".m-section-hero",
        //  markers:true
    });

    let tl = gsap.timeline();
    tl.addLabel("init")
        .delay(1)
        .from(
            ".m-section-floater .graphic1",
            {
                duration: 1,
                x: -100,
                y: 100,
                rotate: -10,
                scale: 0,
                opacity: 0,
                ease: "elastic.out",
            },
            "<"
        )
        .from(
            ".m-section-floater .graphic2",
            {
                duration: 1,
                x: 100,
                y: -100,
                rotate: -5,
                scale: 0,
                ease: "elastic.out",
            },
            0.3
        )
        .from(
            ".m-section-floater .graphic3",
            {
                duration: 1,
                x: -100,
                y: -100,
                rotate: 10,
                scale: 0,
                ease: "elastic.out",
            },
            0.7
        )
        .from(
            ".m-section-floater .box1",
            {
                duration: 0.5,
                x: -400,
                y: -mheight,
                rotate: -225,
                scale: 1.5,
                
            },
            "<"
        )
        .from(
            ".m-section-floater .box2",
            {
                duration: 0.5,
                x: 200,
                y: mheight,
                rotate: 210,
                scale: 2,
            },
            "<"
        )
        .from(
            ".m-section-floater .box3",
            {
                duration: 0.5,
                x: 500,
                y: -mheight,
                rotate: 215,
                scale: 1.5,
            },
            "<"
        )

        // .addPause("move")
        // .addLabel("move")

        // .to(
        //     ".m-section-floater .graphic1",
        //     {
        //         duration: 2,
        //         y: 400,
        //     },
        //     "move"
        // );

    // tl.tweenFromTo(0, 2)
    ScrollTrigger.create({
        animation: tl,
        trigger: ".m-section-hero",
        // toggleActions:"play reverse reverse  reverse",
        // start: "0% 0%",
        //markers: true,
        //scrub:true
    });



    // ScrollTrigger.create({
    //     animation: tl,
    //     trigger: ".m-section-hero",
    //     // toggleActions:"play reverse reverse  reverse",
    //     // start: "0% 0%",
    //     //markers: true,
    //     scrub:true
    // });
    // let tlscrub = gsap.timeline()
    // tlscrub.to(
    //     ".m-section-floater .graphic1",
    //     {
    //         duration: 0.5,
    //         x: 600,
    //         y: - mheight,
    //         rotate: -350,
    //         scale: 0.7,
    //     },
    //     "<"
    // );

    // ScrollTrigger.create({
    //     animation: tl.reverse(),
    //     trigger: ".m-section-how",
    //     start: "0% 10%",
    //     //scrub:3,

    // });

    //tl.scrollTrigger.labelToScroll("myLabel")
};

export const timelineHow = () => {
    let tl = gsap.timeline();
    tl.to('.m-section-floater',{
        duration:0.4,
        opacity:0,
        scale:1.5
    })
    ScrollTrigger.create({
        animation:tl,
        trigger: ".m-section-how",
        scrub: 2,
        pin: true,
        //  markers:true
    });
};

export const timelineClone = () => {
    let tl = gsap.timeline();
    tl.from(
        ".m-section-clone .box1",
        {
            duration: 1,
            x: 200,
            opacity: 0,
        },
        "<"
    );
    tl.from(".m-section-clone .box2", {
        duration: 1,
        x: -200,
        opacity: 0,
    });

    // tl.from(".m-dark-clone-more", { duration: 3, xPercent: 100 });
    ScrollTrigger.create({
        animation: tl,
        trigger: ".m-section-clone",
        // scrub: 3,
        // pin: true,

        toggleActions: "play pause resume reverse",
        //toggleActions: "restart pause reverse reverse",
        start: "top 50%",
    
        ease: "power3.out",
        // markers:true
    });
};

export const timelineCloneMore = () => {
    let tl = gsap.timeline();

    tl.from(".m-section-clone-more .box1", {
        x: -800,
        // skewX: 30,
        opacity: 0,
    });

    let glist = gsap.utils.toArray(".m-section-clone-more .glist");
    glist.forEach((gitem) => {
        tl.from(gitem, {
            x: 400,
            opacity: 0,
        });
    });

    ScrollTrigger.create({
        animation: tl,
        trigger: ".m-section-clone-more",
        // start: "top top",
        // scrub: 4,
        // pin: true,

        toggleActions: "play pause resume reverse",
        start: "top 50%",
        ease: "power3.out",
        //markers: true,
    });
};

export const timelineMessage = () => {
    let mheight = document.querySelector(".m-section").offsetHeight;
    let tl = gsap.timeline();

    tl.from(
        ".m-section-message .box1",
        {
            duration: 1,
            y: -(mheight*2),
            rotate: 80,
            scale: 1.5,
        },
        "<"
    );

    tl.from(
        ".m-section-message .box2",
        {
            duration: 0.5,
            y: 200,
            opacity: 0,
        },
        0.5
    );
    tl.from(
        ".m-section-message .graphic1",
        {
            duration: 0.3,
            rotate: 25,
            x: -100,
            scale: 0,
            opacity: 0,
        },
        0.5
    );

    // tl.from(".m-dark-clone-more", { duration: 3, xPercent: 100 });
    ScrollTrigger.create({
        animation: tl,
        trigger: ".m-section-message",
        // start: "top top",
        // end: "20% bottom",
        // scrub: 3,
        // pin: true,
        //markers:true


        toggleActions: "play pause resume reverse",
        start: "top 50%",
        ease: "power3.out",
    });
};

export const timelineMessageMore = () => {
    let tl = gsap.timeline();
    let mheight = document.querySelector(".m-section").offsetHeight;

    tl.from(".m-section-message-more .box1", {
        y: -mheight,
        // opacity:0s
    });

    let glist = gsap.utils.toArray(".m-section-message-more .glist");
    glist.forEach((gitem) => {
        tl.from(gitem, {
            y: 200,
            opacity: 0,
        });
    });

    ScrollTrigger.create({
        animation: tl,
        trigger: ".m-section-message-more",
        // start: "top top",
        // scrub: 2,
        // pin: true,
        //markers: true,


        toggleActions: "play pause resume reverse",
        start: "top 50%",
        ease: "power3.out",
    });
};

export const timelineGrow = () => {
    let mheight = document.querySelector(".m-section").offsetHeight;
    let tl = gsap.timeline();

    tl.from(
        ".m-section-grow .box1",
        {
            duration: 1,
            y: mheight,
            scale: 1.6,
            rotate: 90,
        },
        "<"
    );
    tl.from(
        ".m-section-grow .graphic1",
        {
            duration: 0.5,
            y: 200,
            opacity: 0,
        },
        "<"
    );

    tl.from(
        ".m-section-grow .graphic2",
        {
            duration: 0.5,
            y: 100,
            opacity: 0,
        },
        0.3
    );

    tl.from(
        ".m-section-grow .graphic3",
        {
            duration: 0.5,
            x: 10,
            opacity: 0,
            scale: 0,
        },
        0.5
    );

    // tl.from(".m-dark-clone-more", { duration: 3, xPercent: 100 });
    ScrollTrigger.create({
        animation: tl,
        trigger: ".m-section-grow",
        // start: "top top",
        // end: "20% bottom",
        // scrub: 3,
        // pin: true,
        //markers:true

        toggleActions: "play pause resume reverse",
        start: "top 50%",
        ease: "power3.out",
    });
};

export const timelineGrowMore = () => {
    let tl = gsap.timeline();

    tl.from(".m-section-grow-more .box1", {
        x: 800,
        // skewX: 30,
        opacity: 0,
    });

    let glist = gsap.utils.toArray(".m-section-grow-more .glist");
    glist.forEach((gitem) => {
        tl.from(gitem, {
            x: -400,
            opacity: 0,
        });
    });

    ScrollTrigger.create({
        animation: tl,
        trigger: ".m-section-grow-more",
        // start: "top top",
        // scrub: 4,
        // pin: true,
        //markers: true,


        toggleActions: "play pause resume reverse",
        start: "top 50%",
        ease: "power3.out",
    });
};

export const timelineFaq = () => {
    // ScrollTrigger.create({
    //     animation: tl,
    //     trigger: ".m-section-faq",
    //     start: "top top",
    //     scrub: 4,
    //     pin: true,
    //     //markers: true,
    // });
};

export const timelineTrending = () => {
    // ScrollTrigger.create({
    //     trigger: ".m-section-trending",
    //     start: "top top",
    //     scrub: 4,
    //     pin: true,
    //     //markers: true,
    // });
};

export const timelineCreate = () => {
    let mheight = document.querySelector(".m-section").offsetHeight;

    let tl = gsap.timeline();

    tl.from(
        ".m-section-create .graphic1",
        {
            duration: 0.5,
            x: 600,
            y: -mheight,
            rotate: -350,
            scale: 0.7,
        },
        "<"
    );

    tl.from(
        ".m-section-create .graphic2",
        {
            duration: 0.5,
            x: -620,
            y: -mheight,
            rotate: -345,
            scale: 0.7,
        },
        "<"
    );
    tl.from(
        ".m-section-create .graphic3",
        {
            duration: 1,
            x: 600,
            y: mheight,
            rotate: 350,
            scale: 0.7,
        },
        "<"
    );
    tl.from(
        ".m-section-create .box1",
        {
            duration: 1,
            x: -400,
            y: -mheight,
            rotate: -25,
            scale: 1.5,
        },
        "<"
    );

    tl.from(
        ".m-section-create .box2",
        {
            duration: 1,
            x: 200,
            y: mheight,
            rotate: 10,
            scale: 2,
        },
        "<"
    );
    tl.from(
        ".m-section-create .box3",
        {
            duration: 1,
            x: 500,
            y: -mheight,
            rotate: 15,
            scale: 1.5,
        },
        "<"
    );

    ScrollTrigger.create({
        animation: tl,
        trigger: ".m-section-create",
        toggleActions: "restart pause reverse reverse",
        start: "top 50%",
        ease: "power3.out",
        // scrub: 3,
        //pin: true,
        //markers: true,
    });
};
