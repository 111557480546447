import React, { useState, useEffect, useRef } from "react";

const TypeText = (props) => {
    const [displayText, setDisplayText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);

    let timeoutRef = useRef();

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, []);

    useEffect(() => {
        //  console.log('TT :' ,props.isCompleted ,currentIndex, props.text.length)
        if (props.text && props.isCompleted && currentIndex === props.text.length) {
            props.OnFinished(true);
        }
    }, [props.isCompleted, props.text, currentIndex]);

    useEffect(() => {
        if (displayText && displayText !== "") {
            props.OnType(displayText);
        }
    }, [displayText]);

    useEffect(() => {
        if (props.text) {
            const typeText = async () => {
                if (currentIndex <= props.text.length - 1) {
                    const currentChar = props.text.charAt(currentIndex);
                    let delay = 40;
                    timeoutRef.current = setTimeout(() => {
                        setDisplayText(displayText + `${currentChar}`);
                        setCurrentIndex(currentIndex + 1);
                    }, delay);
                }
            };

            typeText();
        }
    }, [currentIndex, props.text]);

    return <>{displayText}</>;
};

export default TypeText;
