import { useEffect } from "react";

import Card from "src/components/card/card";
import './profile.css'


import Grid from "src/layouts/grid/grid";
import Header from "src/layouts/header/header";
import Main from "src/layouts/main/main";
import Footer from "src/layouts/footer";
import Sidebar from "src/layouts/sidebar";
import Menu from "src/components/menu/menu";


const Profile = () => {
    useEffect(()=>{
        console.log('Profile')
    })


    return (
    <div className="page">
   

            <Menu />
            <Main>
                <Header />
                <div className="flex flex-row">
                    <div className="flex flex-col flex-1">

                        <div className="profile-details">
                            <Card profile={true} />
                        </div>
                    
                        <Grid>
                            <Card navigate={'/chat'}/>
                            <Card navigate={'/chat'}/>
                            <Card navigate={'/chat'}/>
                            <Card navigate={'/chat'}/>
                            <Card navigate={'/chat'}/>
                        
                        
                        </Grid>
                    </div>
                    
                    <Sidebar />
                </div>

            </Main>

           
       

       
    </div>

    );
}
 
export default Profile;