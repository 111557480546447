import { useEffect, useState, useRef } from "react";
import { startDictation, restartDictation, stopDictation } from "./dictation";
import { sendChat } from "src/apis/aichats";
import "./voice-chat.css";
import { handleToSpeech } from "./tospeech";
import TTS from "../tts/tts";
import TTSWebsocket from "../tts/tts-ws";
import { makeOpenAIRequest } from "./openai";
import TypeText from "../typetext/typetext";
import VideoPlayer from "../video-player/";
import axios from "axios";
import endpoint from "src/apis/endpoints";
import { getStoredUser } from "../auth/auth";
import VideoQueuer from "../video-player/video-queuer";
import MicWaveform from "./wave";
import Preloader from "../preloader/preloader";

const VoiceChat = (props) => {
    const [model, setModel] = useState();

    const [recState, setRecState] = useState("");
    let recStateRef = useRef();
    const [textToAudio, setTextToAudio] = useState(null);

    let stream = useRef();
    let isDictating = useRef(false);
    let unsentMessages = useRef([]);
    let inTransit = useRef(false);
    let newMessagesWatcherInterval = useRef();
    let ttsPlayerRef = useRef();

    const [statusText, setStatusText] = useState('Start speaking...')

    useEffect(() => {
        inTransit.current = false;
        return () => {
            stop();
        };
    }, []);

    useEffect(() => {
        if (model) {
            start();
        }
    }, [model]);

    useEffect(() => {
        recStateRef.current = recState;
    }, [recState]);

    useEffect(() => {
        setModel({ ...props.model });
    }, [props.model]);

    useEffect(() => {
        console.log('props.voiceTextSent ::', props.voiceTextSent)
        if (props.voiceTextSent && props.voiceTextSent.state === true ) {
            restartDictation();
            inTransit.current = false;
            setStatusText('Start speaking')
        }
    }, [props.voiceTextSent]);

    // useEffect(()=>{
    //     console.log('recState:', recState)
    // },[recState])

    const dictationEventHandler = (message) => {
        console.log("inTransit.current is -> ", inTransit.current);
        if (message) {
            message = message.trim();
            unsentMessages.current.push(message);
        }

        if (!inTransit.current) {
            inTransit.current = true;
            const textPrompt = unsentMessages.current.join(" ");
            unsentMessages.current = [];

            if (textPrompt && textPrompt !== "") {
                let thellm = model.llm === "" ? "gpt-4" : model.llm;
                console.log("text ::", textPrompt);
                setStatusText(`${model.name} is thinking`)
                props.OnTextPrompt(textPrompt);

                // sendChat({ sessionid: props.currentSession.id, llm: thellm, msg: textPrompt })
                //     .then((result) => {
                //         // stop();
                //         // start();

                //         restartDictation();
                //         //restartDictation();

                //         inTransit.current = false;
                //         setStatusText('Start speaking...')
                //         // let text = result[0].response.replace(/[^\w\s]|_/g, "");
                //         // text = text.replace(/[\r\n]+/g, " ");
                //         // console.log(text);

                //         // let voiceid = model.voice;

                //         // if (voiceid !== "") {
                //         //     setTextToAudio(text);
                //         //     handleToSpeech(text, voiceid).then((res) => {
                //         //         setTextToAudio(res);
                //         //     });
                //         // } else {
                //         //     let utterance = new SpeechSynthesisUtterance(text);

                //         //     speechSynthesis.speak(utterance);
                //         //     utterance.onend = () => {
                //         //         setYourText("");
                //         //         if (recState !== "stopped") {
                //         //             restartDictation();
                //         //             inTransit.current = false;
                //         //         }
                //         //     };
                //         // }
                //     })

                //     .catch((error) => {
                //         console.error("Error occurred:", error);
                //         inTransit.current = false;
                //         //restartDictation();
                //     });
            }
        }
    };

    const newMessagesWatcher = () => {
        if (!inTransit.current && unsentMessages.current.length > 0) {
            dictationEventHandler();
        }
    };

    const start = async () => {
        inTransit.current = false;
        isDictating.current = true;
        stream.current = await navigator.mediaDevices.getUserMedia({ audio: true });
        
        if (recStateRef.current === "") {
            startDictation(dictationEventHandler);
        } else {
            restartDictation();
        }
        newMessagesWatcherInterval.current = window.setInterval(() => {
            newMessagesWatcher();
        }, 100);

        setRecState("started");
    };

    const stop = () => {
        if (stream.current) {
            const tracks = stream.current.getTracks();
            tracks.forEach(function (track) {
                track.stop();
            });
            stream.current = null;

            stopDictation();
            newMessagesWatcherInterval.current && clearInterval(newMessagesWatcherInterval.current);
            unsentMessages.current = [];
            inTransit.current = false;
            setRecState("stopped");
        }
    };

    return (
        <>
            {model && (
                <>
                    <div className="transition-all flex-1  flex flex-row  gap-3  items-start">
                        <div className="chat-input flex-row justify-center items-start flex-1 flex-1 p-8 py-3 rounded-2xl relative overflow-hidden ">
                        {/* {inTransit.current && <div className="absolute top-0 left-0 w-full h-full z-10 flex items-center justify-center transition-all backdrop-blur-sm">Please wait</div>} */}
                        
                            {recState === "started" ? (
                                <div className="flex flex-1 gap-3 justify-center flex-1 items-center">
                                    <div className="flex-1 text-center">
                                        {/* {statusText} */}
                                        <MicWaveform />
                                    </div>
                                    <i
                                        className="iicon iicon-record blipping icon-link"
                                        onClick={() => {
                                            // stop();
                                        }}
                                    ></i>
                                </div>
                            ) : (
                                <div
                                    className="flex flex-1 gap-3  items-center justify-center  cursor-pointer"
                                    onClick={() => {
                                        start();
                                    }}
                                >   <div className="flex flex-row gap-3 h-full">
                                        <div className="flex loading">Initializing voice chat</div>
                                        {/* <i className="iicon iicon-talk icon-link"></i> */}
                                    </div>
                                </div>
                            )}

                            {/* 

                                <div
                                    className="flex flex-0 items-center"
                                    onClick={() => {
                                        start();
                                    }}
                                >
                                    <div className="loader"></div>
                                </div> */}
                        </div>
                        <div className="p-5 bg-lightgray relative rounded-2xl items-center transition-all">
                            <i
                                className="iicon iicon-tts link link-icon"
                                onClick={() => {
                                    props.OnSetChatType("chat");
                                }}
                            ></i>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default VoiceChat;
