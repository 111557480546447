import Header from "src/layouts/header/header";
import Main from "src/layouts/main/main";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import iconPlay from "src/assets/images/icon-play.svg";
import iconPause from "src/assets/images/icon-pause.svg";
import iconSave from "src/assets/images/icon-save.svg";
import iconLink from "src/assets/images/icon-link.svg";

import "./tts.css";

import Button from "src/components/ui/button";
import { useEffect, useState, useRef } from "react";
import FormInput from "src/components/form/input";
import iconBot from "src/assets/images/icon-bot.svg";
import axios from "axios";

import Menu from "src/components/menu/menu";
import { models } from "src/data/dummy-nerf-models";
import { listRecentTts } from "src/apis/aimodels";
import { dateFormatter } from "src/components/utils";
import Preloader from "src/components/preloader/preloader";
import endpoint from "src/apis/endpoints";

const TTSCreate = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const theUser = () => {
        let storedUser = localStorage.getItem("AVATARZ_user");
        let currentUser = storedUser && storedUser !== "undefined" ? JSON.parse(storedUser) : null;
        if (currentUser == null) {
            currentUser = { id: "5c4c6b88-fc88-4fb9-8fe9-8de502f14e74" };
        }

        return currentUser;
    };
    const [textToSpeak, setTextToSpeak] = useState("");
    const [model, setModel] = useState();
    const [speakPath, setSpeakPath] = useState({ path: null });
    const [vidState, setVidState] = useState("paused");
    const [fetching, setFetching] = useState(true);

    const [pathKey, setPathKey] = useState();
    const [saves, setSaves] = useState([]);

    let videoRef = useRef();

    useEffect(() => {
      
        let findModel = models.find((model) => model.id === params.modelId);
        if (findModel) {
            setModel(findModel);
            setPathKey(findModel.key);

            speakPath.path = findModel.firstmsg;
            setSpeakPath({ ...speakPath });
            handleRecentSaves();
        }
    
    }, []);

    // useEffect(() => {
    //     saves
    // }, [vidState]);

    useEffect(() => {
        if (videoRef && videoRef.current ) {
            videoRef.current.addEventListener("canplaythrough", (event) => {
                setFetching(false);
            });

            videoRef.current.addEventListener("playing", (event) => {
                setVidState("playing");
            });
            videoRef.current.addEventListener("pause", (event) => {
                setVidState("paused");
                let updatedSaves = saves.map((sve) => {
                    delete sve.status;
                    return { ...sve };
                });
                setSaves([...updatedSaves]);
            });
        }
    }, [videoRef, fetching]);

    const handleSpeak = async () => {
        if (speakPath.path && speakPath.path !== "" && params.modelId && !fetching) {
            setFetching(true);
            let thedata = {
                text: textToSpeak,
                avatar_number: params.modelId,
            };
            try {
                let speakRes = await axios.post(`https://132.145.174.192:8000/run_nerf`, thedata);
                // let speakRes = await axios.post(`/run_nerf`, thedata);
                console.log(speakRes);

                if (speakRes && speakRes.data.api_response_body) {
                    let theSpeakPath = `${endpoint.download}/${speakRes.data.api_response_body[0].key}`;
                    speakPath.path = theSpeakPath;
                    setSpeakPath({ ...speakPath });

                    setPathKey(speakRes.data.api_response_body[0].key);
                }
                setFetching(false);

                handleRecentSaves();
            } catch (error) {
                console.log(error);
                setFetching(false);
            }
        }
    };

    const handleRecentSaves = async () => {
        let savesRes = await listRecentTts(params.modelId);
        console.log(savesRes);
        setSaves([...savesRes]);
    };

    return (
        <div className="container-fluid flex flex-row p-0">
            <Menu />
            <Main>
                <Header />

                <div className="h-100 flex align-items-center align-content-center justify-content-center flex-col">
                    <div className="flex align-items-center">
                        <div className="flex-1">
                            <h2>
                                <span className="opacity-25">Model : </span>
                                {model && model.name}
                            </h2>
                        </div>
                    </div>

                    <div className="flex flex-row">
                        {/* <div className="panel panel-bg me-3" style={{ minWidth: 200 }}>
                            <h5 className="mb-4">Recent Saves</h5>
                            <div className="playlist">
                                {saves.map((save, s) => (
                                    <div key={s} className={`slideFromRight item ${save.status ? `item-${save.status}` : ""}`}>
                                        <div className="text">
                                            <span className="opacity-50 me-2">{s + 1}. </span>
                                            <div>
                                                {save.directory}
                                                <div className="opacty-25" style={{ fontSize: "10px" }}>
                                                    {dateFormatter(save.createdate)}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="actions">
                                            <i
                                                onClick={async () => {
                                                    setFetching(true)
                                                    let updatedSaves = saves.map((sve) => {
                                                        if (sve.id === save.id && !sve.status) {
                                                            sve.status = "selected";
                                                        } else {
                                                            delete sve.status;
                                                        }
                                                        return { ...sve };
                                                    });
                                                    setSaves([...updatedSaves]);

                                                    if (save.status === "selected") {
                                                        speakPath.path = `https://squadz.ngrok.app/download/${save.key}`;
                                                        setSpeakPath({ ...speakPath });
                                                        setPathKey(save.key);
                                                    } else {
                                                        speakPath.path = model.firstmsg;
                                                        setSpeakPath({ ...speakPath });
                                                        setPathKey(model.pathkey);
                                                    }
                                                }}
                                            >
                                                {save.status === "selected" ? <img src={iconPause} /> : <img src={iconPlay} />}
                                            </i>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div> */}

                        <div className="model-form slideFromBot">
                            <div className="panel">
                                <div className="model-preview video-wrapper">
                                    {fetching && <Preloader />}
                                    {/* {!fetching &&  ( */}
                                        <>
                                            <video
                                                
                                                src={speakPath.path}
                                                autoPlay
                                                ref={videoRef}

                                                // ref={(el) => {
                                                //     if (el) {
                                                //         videoRef.current = el;

                                                //         el.addEventListener("playing", (event) => {
                                                //             setVidState("playing");
                                                //         });
                                                //         el.addEventListener("pause", (event) => {
                                                //             setVidState("paused");
                                                //         });
                                                //     }
                                                // }}
                                            />

                                            <div className="video-wrapper-actions">
                                                {vidState === "playing" && (
                                                    <div
                                                        className="button-action"
                                                        onClick={() => {
                                                            videoRef.current.pause();
                                                        }}
                                                    >
                                                        <img src={iconPause} />
                                                    </div>
                                                )}

                                                {vidState === "paused" && (
                                                    <div
                                                        className="button-action"
                                                        onClick={() => {
                                                            videoRef.current.play();
                                                        }}
                                                    >
                                                        <img src={iconPlay} />
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    {/* )} */}
                                </div>
                            </div>

                            <div className="panel">
                                <div className="flex-1 flex flex-col mb-3">
                                    <div className="tts-input flex-1 ">
                                        <FormInput
                                            label={`Text to Speech :`}
                                            type={"textarea"}
                                            placeholder={`eg. Hello world`}
                                            value={textToSpeak}
                                            onChange={(e) => {
                                                setTextToSpeak(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className=" mb-3">
                                        <div>
                                            <span className="opacity-50"> --- : Long pause</span>
                                        </div>
                                    </div>

                                    <Button
                                        disabled={fetching}
                                        onClick={() => {
                                            handleSpeak();
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </div>
                                <div className="mt-5 flex flex-row">
                                    <div className="me-3 flex-1">
                                        <a href={speakPath.path} target="_blank" className="button button-trans">
                                            <img src={iconSave} className="icon me-2" />
                                            Download video
                                        </a>
                                    </div>

                                    <div className="ms-3 flex-1">
                                        <a href={`/preview-tts/${pathKey}/${params.modelId}`} target="_blank" className="button button-trans">
                                            {/* <Button buttonStyle={"trans"} onClick={() => { navigate(`/preview-tts/${pathKey}/${params.modelId}`)}}> */}
                                            <img src={iconLink} className="icon me-2" />
                                            Preview
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Main>
        </div>
    );
};

export default TTSCreate;
