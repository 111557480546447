import iconHome from 'src/assets/images/icon-home.svg';
import iconAi from 'src/assets/images/icon-character.svg';
import iconAiMine from 'src/assets/images/icon-character-box.svg';
import iconPricing from 'src/assets/images/icon-pricing.svg';
import iconGift from 'src/assets/images/icon-gift.svg';
import iconBlog from 'src/assets/images/icon-blog.svg';
import iconArrow from 'src/assets/images/icon-arrow-circ.svg';
import iconTTS from 'src/assets/images/icon-tts.svg';


import iconHomeDark from 'src/assets/images/icon-home-dark.svg';
import iconAiDark from 'src/assets/images/icon-character-dark.svg';
import iconPricingDark from 'src/assets/images/icon-pricing-dark.svg';
import iconGiftDark from 'src/assets/images/icon-gift-dark.svg';
import iconBlogDark from 'src/assets/images/icon-blog-dark.svg';
import iconArrowDark from 'src/assets/images/icon-arrow-circ-dark.svg';
import iconTTSDark from 'src/assets/images/icon-tts-dark.svg';



export const mainMenu = [
    // {
    //     label:'Avatars',
    //     path:'/avatars'
       
    // },
    {
        label:'For Creators',
        path:'/for-creators'
       
    },

    {
        label:'Subscription',
        path:'/pricing',
        icon:iconPricing,
        iconDark:iconPricingDark
    },
    {
        label:'Earn Free Coins',
        path:'/free-coins',
        icon: iconGift,
        iconDark: iconGiftDark
    },
    // {
    //     label:'Subscription',
    //     path:'/pricing',
    //     icon:iconPricing,
    //     iconDark:iconPricingDark
    // },
    // {
    //     label:'Earn Free Coins',
    //     path:'/free-coins',
    //     icon: iconGift,
    //     iconDark: iconGiftDark
    // },
]
export const marketMenu = [
    {
        label:'Avatars',
        path:'/avatars'
       
    },
    {
        label:'For Creators',
        path:'/for-creators'
       
    },
    // {
    //     label:'Subscription',
    //     path:'/pricing',
    //     icon:iconPricing,
    //     iconDark:iconPricingDark
    // },
    // {
    //     label:'Earn Free Coins',
    //     path:'/free-coins',
    //     icon: iconGift,
    //     iconDark: iconGiftDark
    // },
]


export const discover = [
    {
        label:'Avatars',
        path:'/avatars',
        icon: iconAi,
        iconDark: iconAiDark
    },
    {
        label:'My Avatars',
        path:'/my-avatars',
        icon:iconAiMine,
        iconDark: iconAiMine
    },
    {
        label:'My Chats',
        path:'/my-chats',
        icon:iconTTS,
        iconDark:iconTTSDark
    },
    // {
    //     label:'Text to Speech',
    //     path:'/tts',
    //     icon:iconTTS,
    //     iconDark:iconTTSDark
    // },
    // {
    //     label:'Search',
    //     path:'/search'
    // },
    // {
    //     label:'Account',
    //     path:'/account'
    // },
  
    // {
    //     label:'Identities',
    //     path:'/identities',
    //     icon:iconAi
    // },

    // {
    //     label:'Subscription',
    //     path:'/pricing',
    //     icon:iconPricing,
    //     iconDark:iconPricingDark
    // },
    // {
    //     label:'Earn Free Coins',
    //     path:'/free-coins',
    //     icon: iconGift,
    //     iconDark: iconGiftDark
    // },
    // {
    //     label:'Blog',
    //     path:'/blog', 
    //     icon: iconBlog,
    //     iconDark: iconBlogDark
    // },
]



export const categories = [
    {
        label:'Dominant',
        path:'/category/dominant',
        icon: iconArrow,
        iconDark: iconArrowDark
    },
    {
        label:'Real',
        path:'/category/real',
        icon: iconArrow,
        iconDark: iconArrowDark
    },
    {
        label:'Fictional',
        path:'/category/fictional',
        icon: iconArrow,
        iconDark: iconArrowDark
    },
    {
        label:'Villain',
        path:'/category/villain',
        icon: iconArrow,
        iconDark: iconArrowDark
    },
    {
        label:'Male',
        path:'/category/male',
        icon: iconArrow,
        iconDark: iconArrowDark
    },
    {
        label:'Female',
        path:'/category/female',
        icon: iconArrow,
        iconDark: iconArrowDark
    },
    {
        label:'Non-human',
        path:'/category/non-human',
        icon: iconArrow,
        iconDark: iconArrowDark
    },
    {
        label:'Anime',
        path:'/category/anime',
        icon: iconArrow,
        iconDark: iconArrowDark
    },
    {
        label:'Scenario',
        path:'/scenario',
        icon: iconArrow,
        iconDark: iconArrowDark
    }
]



// {
//     label:'Categories',
//     children:[
//         {label:'Actors'},
//         {label:'Athletes'},
//         {label:'Comedians'},
//         {label:'Creators'},
//         {label:'Musicians'},
//         {label:' Reality TV'},
//     ]
// },
// {
//     label:'Events',
//     children:[
//         {label:'Birthday'},
//         {label:'Baptism'},
//     ]
// },
// {
//     label:'For Kids',
//     children:[
//         {label:'School'},
//         {label:'Birthday'},
//         {label:'Baptism'},
//     ]
// },
// {
//     label:'For Business',
//     children:[
//         {label:'Conference'},
//         {label:'Meeting'},
//     ]
// },
// {
//     label:'How it works',
//     path:'/about'
    
// }