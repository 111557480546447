import { useState, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import Menu from "src/components/menu/menu";
import { getStoredUser, logout } from "src/components/auth/auth";

import "./header.css";
import UI_SearchBar from "src/components/ui/search-bar";
import Button from "src/components/ui/button";
import iconMoon from "src/assets/images/icon-moon.svg";
import iconSun from "src/assets/images/icon-sun.svg";
import defaultPic from "src/assets/images/default_pic_small.png";
import iconMobileMenu from "src/assets/images/icon-hamb.svg";
import iconMobileMenuDark from "src/assets/images/icon-hamb-dark.svg";
import { mainMenu, marketMenu } from "src/data/menu";

const Header = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [scrolled, setScrolled] = useState();
    const [user, setUser] = useState();
    const [currentTheme, setCurrentTheme] = useState("light");
    const [theMenu, setTheMenu] = useState(mainMenu);

    useEffect(()=>{
        if(props.menu === 'market'){
            setTheMenu(marketMenu)
        }
    },[props.menu])

    useEffect(() => {
        //handleTheme("get");
        let body = document.querySelector("body");
        if (body.classList.contains("theme-dark")) {
            setCurrentTheme("dark");
        } else {
            setCurrentTheme("light");
        }

        let theUser = getStoredUser();
        if (theUser && theUser.id) {
            setUser(theUser);
        }

        const onScroll = () => {
            setScrolled(window.pageYOffset);
        };

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    useEffect(() => {
        if (currentTheme && currentTheme !== "") {
            let body = document.querySelector("body");
            body.classList.remove(`theme-dark`);
            body.classList.remove(`theme-light`);

            body.classList.add(`theme-${currentTheme}`);
        }
    }, [currentTheme]);

    // useEffect(() => {
    //     let mainMenu = document.getElementById("main-menu");
    //     const handleShowMenu = () => {
    //         let isShow = mainMenu.classList.contains("menu-mobile-show");
    //         if (!isShow) {
    //             mainMenu.classList.add("menu-mobile-show");
    //         } else {
    //             mainMenu.classList.remove("menu-mobile-show");
    //         }
    //     };
    //     mainMenu.addEventListener("click", handleShowMenu);

    //     return () => {
    //         mainMenu.removeEventListener("click", handleShowMenu);
    //     };
    // }, []);

    const handleMenuToggle = () => {
        let mainMenu = document.getElementById("main-menu");
        let isShow = mainMenu.classList.contains("menu-mobile-show");
        if (!isShow) {
            mainMenu.classList.add("menu-mobile-show");
        } else {
            mainMenu.classList.remove("menu-mobile-show");
        }
    };

    const handleTheme = (opr) => {
        const themeName = "AVATARZ_theme";
        const currentTheme = localStorage.getItem(themeName);
        switch (opr) {
            case "get":
                if (currentTheme && currentTheme !== "") {
                    setCurrentTheme(currentTheme);
                    localStorage.setItem(themeName, currentTheme);
                } else {
                    setCurrentTheme("light");
                    localStorage.setItem(themeName, "light");
                }
                break;

            case "select":
                if (currentTheme && currentTheme !== "" && currentTheme === "light") {
                    setCurrentTheme("dark");
                    localStorage.setItem(themeName, "dark");
                } else {
                    localStorage.setItem(themeName, "light");
                    setCurrentTheme("light");
                }
                // navigate(window.location.href)
                break;

            default:
                break;
        }
    };

    return (
        <div className="flex flex-row items-center flex-1">
            <img
                src={iconMobileMenu}
                className="mobile-menu-icon mr-3"
                onClick={() => {
                    handleMenuToggle();
                }}
            />

            <div
                className="logo ml-0 md:ml-5 md:pl-0 flex-1 "
                onClick={() => {
                    navigate("/");
                }}
            >
                fanlynk
            </div>

            <div className="flex flex-1 gap-20 font-semibold mobile-hide">
                {theMenu.map((menu, m) => (
                    <a key={m} href={menu.path} className="link link-black">
                        {menu.label}
                    </a>
                ))}
            </div>

            <div className="flex-0 items-end mobile-hide">
                <UI_SearchBar />
            </div>

            <div className="mr-3 flex ">
                {/* <Button className={"mobile-hide"} navigate={"/character/create"}>
                    Create a Character
                </Button> */}

                {/* <div className="mx-3 header-actions">
                        <Button
                            buttonStyle="icon"
                            onClick={() => {
                                handleTheme("select");
                            }}
                        >
                            {currentTheme === "light" && <img src={iconMoon} className="icon" />}
                            {currentTheme === "dark" && <img src={iconSun} className="icon" />}
                        </Button>
                    </div> */}
            </div>

            {user && user.id ? (
                <div className="user-avatar pointer ml-3">
                    <div
                        className="user-image"
                        onClick={() => {
                            navigate("/account");
                        }}
                    >
                        {user.tkey !== "" ? <img src={`https://${user.tbucket}.s3.amazonaws.com/${user.tkey}`} /> : <img src={defaultPic} />}
                    </div>

                    <div
                        className="ms-3 pointer"
                        onClick={() => {
                            logout();
                            if (location.pathname === "/") {
                                window.location.reload(true);
                            }
                            navigate('/');
                        }}
                    >
                        Logout
                    </div>
                </div>
            ) : (
                <div
                    className="ps-3 "
                    onClick={() => {
                        navigate("/login");
                    }}
                >
                    <Button>Login</Button>
                </div>
            )}
        </div>
    );
};

export default Header;
