import { useEffect, useState } from "react";
import DynamicInput from "src/components/dynamic-input";
import ModalCharacterKnowledge from "./modal-knowledge";
import { deleteKnowledge, listKnowledge } from "src/apis/utils";
import { dateFormatter } from "src/components/utils";
import Preloader from "src/components/preloader/preloader";

const CharacterAppearance = (props) => {
    const [showAddKnowledge, setShowAddKnowledge] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [knowledges, setKnowledges] = useState([]);

    const [tabs, setTabs] = useState([
        {
            id: "knowledge",
            name: "Knowledge",
            desc: "This will be used to add knowledge to the model.",
        },
        {
            id: "appearance",
            name: "Appearance",
            desc: "This will be used to train the how the character will look. (Accepts only images)",
        },
    ]);


    const handleKnowledge = async ({ opr, knowledge }) => {
        setFetching(true);
        switch (opr) {
            case "list":
                let listRes = await listKnowledge({ modelid: props.model.id, type: 'appearance' });

                console.log("knowledges : ", listRes);
                setKnowledges(listRes);
                break;

            case "delete":
                let delRes = await deleteKnowledge(knowledge.id);
                console.log("deleted : ", delRes);
                await handleKnowledge({ opr: "list" });
                break;
            default:
                break;
        }

        setFetching(false);
    };

    return (
        <>
            {showAddKnowledge && (
                <ModalCharacterKnowledge
                    {...props}
                    type={'appearance'}
                    desc={`This will be used to train the how the avatar will look. (Accepts only images)`}
                    OnHide={() => {
                        setShowAddKnowledge(false);
                        handleKnowledge({ opr: "list" });
                    }}
                />
            )}

            <div className="flex tab-content tab-content-details">
                <div className="flex flex-1 flex-row gap-6  w-1/2 mx-auto p-10 ">
                    {/* <div className="flex-1">
                        <div className="mb-3 font-semibold text-sm mt-2s">Character Appearance</div>
                        <div className="leading-5 opacity-40 w-1/2">This will be used to train the how the character will look. (Accepts only images)</div>
                    </div> */}
                    <div className="flex flex-col  flex-1 gap-6">
                        <div className="flex-1">
                        {knowledges.length <= 0 && (
                            <div className="flex p-5 justify-content-center align-items-center list list-bordered ">
                                You have not added any appearances yet.
                            </div>
                        )}

                        {knowledges.length > 0 && (
                            <div className="flex list list-bordered overflow-hidden">
                                {fetching && <Preloader type={"full-relative"} />}
                                {knowledges.map((data, d) => (
                                    <div key={d} className="item flex flex-row items-center">
                                        <div className="flex type pe-4">
                                            <div className="icon-dtype">{data.type}</div>
                                        </div>

                                        <div className="flex flex-col flex-1">
                                            <div className="name">{data.url}</div>
                                            <div className="opacity-25">{dateFormatter(data.createdate)}</div>
                                        </div>
                                        <div className="flex">
                                            {/* <i className="iicon iicon-edit link link-icon"></i> */}
                                            <i
                                                className="iicon iicon-trash ms-3 link link-icon"
                                                onClick={() => {
                                                    handleKnowledge({ opr: "delete", knowledge: data });
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        </div>
                        <button
                            className="button"
                            onClick={() => {
                                setShowAddKnowledge(true);
                            }}
                        >
                            Add Appearance
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CharacterAppearance;
