import { useEffect, useState, useRef } from "react";
import iconPlay from "src/assets/images/icon-play.svg";
import iconPause from "src/assets/images/icon-pause.svg";

import "./video-player.css";

const VideoPlayer = (props) => {
    let videoRef = useRef();

    const [vidState, setVidState] = useState("paused");
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
        if (videoRef && videoRef.current) {
            if (props.autoplay === false) {
                videoRef.current.pause();
            }
            videoRef.current.addEventListener("canplaythrough", (event) => {
                setHasLoaded(true);
            });
            videoRef.current.addEventListener("playing", (event) => {
                setVidState("playing");
            });
            videoRef.current.addEventListener("pause", (event) => {
                setVidState("paused");
            });
        }
    }, [videoRef]);

    return (
        <>
            {props.isAudio ? (
                <audio src={props.src} autoPlay={props.autoplay ? props.autoplay : true} playsInline ref={videoRef} />
            ) : (
                <div className={`video-player-wrapper ${props.size ? `video-player-${props.size}` : ""}`}>
                    {/* <div
                        style={{
                            position: "absolute",
                            left: "0",
                            top: "0",
                        }}
                    >
                        {props.src}
                    </div> */}
                    <div className="video-player-players">
                        <video
                            className="video-player-1"
                            src={props.src}
                            autoPlay={props.autoplay ? props.autoplay : true}
                            playsInline
                            ref={videoRef}
                            //onEnded={handleVideoEnded}
                        />
                    </div>

                    {!props.hideActions && (
                        <div className="video-wrapper-actions">
                            {vidState === "playing" && (
                                <div
                                    className="button-action"
                                    onClick={() => {
                                        videoRef.current.pause();
                                    }}
                                >
                                    <img src={iconPause} />
                                </div>
                            )}

                            {vidState === "paused" && (
                                <div
                                    className="button-action"
                                    onClick={() => {
                                        videoRef.current.play();
                                    }}
                                >
                                    <img src={iconPlay} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default VideoPlayer;
