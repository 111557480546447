import { Routes, Route, Navigate, useParams, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

import "src/assets/styles/font.css";

import "src/assets/styles/app.css";
import "src/assets/styles/theme-light.css";
import "src/assets/styles/animate.css";
import "src/assets/styles/mobile.css";

import Login from "src/pages/login/login";
import Home from "src/pages/home/home";
import Profile from "src/pages/profile/";
import Chat from "src/pages/chat/chat";
import About from "src/pages/about";
import Search from "./pages/search/search";
import Pricing from "./pages/pricing/pricing";
import Character from "./pages/character/character";
import MyAvatarz from "./pages/my-avatars/my-avatars";
import Account from "./pages/account/account";
import FreeCoins from "./pages/free-coins/free-coins";
import Blog from "./pages/blog";
import Category from "./pages/category/category";

import TTSHome from "./pages/tts";
import TTSCreate from "./pages/tts/create";
import TTSPreview from "./pages/tts/preview";
import Identities from "./pages/identities";

import ProtectedRoute from "./components/protected-route";
import MyChats from "./pages/my-chats/my-chats";
import Tester from "./pages/tester/testerCARTESIA";
import TesterBot from "./pages/tester/tester-bot";
import ForUsers from "./pages/marketing/for-users/for-users";
import ForCreators from "./pages/marketing/for-creators/for-creators";
import JoinCreators from "./pages/marketing/join-creators/join-creators";

const App = (props) => {
    // useEffect(()=>{
    //         const themeName = "AVATARZ_theme";
    //         const currentTheme = localStorage.getItem(themeName);
    //         console.log(currentTheme)
    //         let body = document.querySelector("body");
    //         body.classList.remove(`theme-dark`);
    //         body.classList.remove(`theme-light`);

    //         body.classList.add(`theme-${currentTheme}`);

    // },[])

    // const handleTheme = (opr) => {
    //         const themeName = "AVATARZ_theme";
    //         const currentTheme = localStorage.getItem(themeName);

    //         switch (opr) {
    //             case "get":
    //                 if (currentTheme && currentTheme !== "") {
    //                     setCurrentTheme(currentTheme);
    //                     localStorage.setItem(themeName, currentTheme);
    //                 } else {
    //                     setCurrentTheme("light");
    //                     localStorage.setItem(themeName, "light");
    //                 }
    //                 break;

    //             case "select":
    //                 if (currentTheme && currentTheme !== "" && currentTheme === "light") {
    //                     setCurrentTheme("dark");
    //                     localStorage.setItem(themeName, "dark");
    //                 } else {
    //                     localStorage.setItem(themeName, "light");
    //                     setCurrentTheme("light");
    //                 }
    //                 // navigate(window.location.href)
    //                 break;

    //             default:
    //                 break;
    //         }
    //     };

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
{/*             
            <div className="bg-floating">
                <div className="bg-violet"></div>
                <div className="bg-torq"></div>
            </div> */}
            <Routes>
                <Route path="/" element={<ForUsers />} />

                <Route exact path="/my-chats" element={<ProtectedRoute component={MyChats} />} />

                <Route exact path="/search" element={<Search />} />

                <Route exact path="/identities" element={<Identities />} />

                <Route exact path="/pricing" element={<Pricing />} />

                <Route exact path="/free-coins" element={<FreeCoins />} />

                <Route exact path="/blog" element={<Blog />} />

                <Route exact path="/category/:catname" element={<Category />} />

                <Route exact path="/avatars" element={<Home />} />

                <Route exact path="/login" element={<Login />} />

                <Route exact path="/profile" element={<Profile />} />

                <Route exact path="/character/:characterId" element={<ProtectedRoute component={Character} />} />

                <Route exact path="/account" element={<ProtectedRoute component={Account} />} />

                <Route exact path="/my-avatars" element={<ProtectedRoute component={MyAvatarz} />} />

                <Route exact path="/chat/:modelId" element={<ProtectedRoute component={Chat} />} />

                {/* <ProtectedRoute
                    exact
                    path='/chat/:modelId'
                    ProtectedRoute={Chat} 
                 
                />  */}

                <Route exact path="/about" element={<About />} />

                <Route exact path="/tts" element={<TTSHome />} />

                <Route exact path="/create-tts/:modelId" element={<TTSCreate />} />

                <Route exact path="/preview-tts/:key/:avatarId" element={<TTSPreview />} />

                <Route exact path="/tester" element={<Tester />} />
                <Route exact path="/tester-bot" element={<TesterBot />} />

                <Route exact path="/for-users" element={<ForUsers />} />
                <Route exact path="/for-creators" element={<ForCreators />} />
                <Route exact path="/join-creators" element={<JoinCreators />} />

                <Route path="*" element={<Home />} />
            </Routes>
        </>
    );
};

export default App;
