import { useEffect, useState, useRef } from "react";
import FormInput from "src/components/form/input";
import { useParams } from "react-router-dom";
import SelectMulti from "src/components/form/select-multi/select-multi";

const CharacterScenario = (props) => {
    const params = useParams();
    const sampleScenarios = [
        {
            id: "001",
            title: "Seeking Advice",
            scenario: `User: Hey, I've been feeling really overwhelmed with work and life lately. Any tips on how to manage this stress?
            Bot: It sounds like you're carrying a lot on your shoulders. Have you tried setting aside a little time each day just for yourself, maybe for a walk or to enjoy a cup of tea?
            User: That's a good idea, but I find it hard to find even a moment for myself.
            Bot: I understand, it can be really challenging. What about starting with just five minutes? Sometimes the smallest break can make a big difference.
            User: I guess I could try that. Thanks for the suggestion!
            Bot: Anytime! Remember, taking care of yourself isn't a luxury, it's a necessity. Let me know how it goes!`,
        },
        {
            id: "002",
            title: "Favorite Book",
            scenario: `User: I just finished reading "The Alchemist" and it was amazing. Have you read it?
            Bot: "The Alchemist" is such a treasure, isn't it? Paulo Coelho's way of weaving magic and realism always leaves me pondering life's journey. What part stuck with you the most?
            User: I loved the part about following your personal legend. It made me think about my own dreams.
            Bot: That's a powerful takeaway. It's inspiring how Santiago's story encourages us to pursue our passions fearlessly. Do you have a dream you're chasing?
            User: I've always wanted to travel more and explore new cultures.
            Bot: That sounds wonderful! Traveling opens up so many doors to understanding and adventure. I hope you get to embark on that journey soon.`,
        },
        {
            id: "003",
            title: "About Cooking",
            scenario: `User: I'm trying to cook more at home. Got any favorite recipes?
            Bot: Cooking at home is such a rewarding experience! I recently came across a simple yet delicious pasta aglio e olio recipe. It's just pasta, garlic, olive oil, and a bit of chili flakes.
            User: Sounds simple enough. I'm not the best cook, though. Think I can handle it?
            Bot: Absolutely! It's a great recipe to start with, and it's all about the love you put into it. Plus, the aroma of garlic and olive oil is unbeatable.
            User: Okay, I'll give it a try. Wish me luck!
            Bot: Best of luck! I'm sure it'll turn out great. Cooking is all about experimenting and enjoying the process. Let me know how it goes!`,
        },
    ];

    const { character, baseLLMList, categoriesList } = props;
    const [fetching, setFetching] = useState(false);

    const [selectedSampleScenario, setSelectedSampleScenario] = useState(null);
    useEffect(() => {
        // if (params && params.characterId === "create") {
        //     setSelectedSampleScenario(sampleScenarios[0]);
        //     character.scenario = sampleScenarios[0].scenario
        // }
    }, [params]);

    // useEffect(() => {
    //     if (selectedSampleScenario) {
    //         character.scenario = selectedSampleScenario.prompt;
    //     }
    // }, [selectedSampleScenario]);

    return (
        <div className="flex tab-content tab-content-details">
            <div className="flex flex-col gap-6 mx-auto p-4 md:p-10 w-full md:w-1/2">
                {/* <div className="flex-1">
                    <div className="mb-3 font-semibold text-sm mt-2">More Information</div>
                    <div className="leading-5 opacity-40 w-1/2">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </div>
                </div> */}
                <div className="flex flex-1 flex-col gap-6">
                    <FormInput
                        type={"textarea"}
                        label={`First Message :`}
                        placeholder={`Your avatar's first message`}
                        value={character.firstmsg}
                        onChange={(e) => {
                            character.firstmsg = e.target.value;
                            props.OnSet(character);
                        }}
                    />
                    <div className="relative">
                        <FormInput
                            type={"textarea"}
                            label={`Scenario :`}
                            placeholder={`The scenario your avatar is in`}
                            value={character.scenario}
                            onChange={(e) => {
                                character.scenario = e.target.value;
                                props.OnSet(character);
                            }}
                        />

                        <div className="p-5 rounded-xl info-floating">
                            <div className="mb-3">Sample Scenarios : </div>
                            <div className="flex flex-col gap-3 flex-wrap">
                                {sampleScenarios.map((scenario, p) => (
                                    <button
                                        key={p}
                                        onClick={() => {
                                            
                                            if (selectedSampleScenario?.id === scenario.id) {
                                                setSelectedSampleScenario(null);
                                                character.scenario = "";
                                            } else {
                                                setSelectedSampleScenario(scenario);
                                                character.scenario = scenario.scenario;
                                            }

                                            props.OnSet(character);
                                        }}
                                        className={`button px-5 py-2 ${selectedSampleScenario?.id === scenario.id ? "" : "button-trans"}`}
                                    >
                                        {scenario.title}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>

                    <FormInput
                        type={"textarea"}
                        label={`Example Conversation :`}
                        placeholder={`Your avatar's conversation`}
                        value={character.exampleconvo}
                        onChange={(e) => {
                            character.exampleconvo = e.target.value;
                            props.OnSet(character);
                        }}
                    />

                    <FormInput
                        label={`Personality :`}
                        placeholder={`What is your avatar's personality`}
                        value={character.personality}
                        onChange={(e) => {
                            character.personality = e.target.value;
                            props.OnSet(character);
                        }}
                    />

                    <FormInput
                        label={`Tags :`}
                        placeholder={`Your avatar's tags`}
                        value={character.tags}
                        note={`Separate each tag by comma. eg. person,cartoon,politics`}
                        onChange={(e) => {
                            character.tags = e.target.value;
                            props.OnSet(character);
                        }}
                    />

                    <FormInput
                        label={`Meta :`}
                        value={character.meta}
                        onChange={(e) => {
                            character.meta = e.target.value;
                            props.OnSet(character);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
export default CharacterScenario;
