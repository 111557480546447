
import { logoutUser } from "src/apis/user";

export const getStoredUser = () => {
    let storedUser = localStorage.getItem("AVATARZ_user")
    let currentUser =(storedUser && storedUser !== 'undefined')? JSON.parse(storedUser) : null;

    let storedAuth = localStorage.getItem("AVATARZ_auth")
    let currentAuth = (storedAuth && storedAuth !== 'undefined')? storedAuth : null;

    if(currentUser && currentUser.id && currentAuth){
        currentUser.auth = currentAuth
    }
  
    return currentUser;
};



export const updateStoredUser = (userdata) => {
    localStorage.setItem("AVATARZ_auth", userdata)
}

export const logout = () =>{
    localStorage.removeItem("AVATARZ_user");
    localStorage.removeItem("AVATARZ_auth");
    //await logoutUser()
}