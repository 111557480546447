export const dateFormatter = (dateString) => {
    // Input date string
    var t = dateString.split(/[- :]/);

    // Apply each element to the Date function
    var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
    // Create a Date object from the input string
    const dateObj = new Date(d);

    // Define an array for months to convert the numeric month to a string
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    // Extract date components
    const year = dateObj.getFullYear();
    const month = months[dateObj.getMonth()];
    const day = dateObj.getDate();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const seconds = dateObj.getSeconds();
    const timezone = dateObj.getTimezoneOffset();

    // Format the date and time
    const formattedDate = `${month.toString().substring(0, 3)} ${day}, ${year.toString().slice(-2)}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    const formattedTimezone = `GMT${timezone >= 0 ? "-" : "+"}${Math.abs(timezone / 60)
        .toString()
        .padStart(2, "0")}:${Math.abs(timezone % 60)
        .toString()
        .padStart(2, "0")}`;

    // Combine all the formatted components
    const formattedDateTime = `${formattedDate}, ${formattedTime}`;

    // Output the formatted date and time
    return formattedDateTime;
};

export const epochToDate = (epochTimestamp) => {
 

    // Convert epoch timestamp to a Date object
    const date = new Date(epochTimestamp); // Multiply by 1000 as JavaScript uses milliseconds

    // Get the various date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 as month is zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Create a readable date string

    const formattedDate = `${month.toString().substring(0, 3)}/${day}/${year.toString().slice(-2)}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    const readableDate = `${formattedDate},${formattedTime}`;

    return readableDate;
};

export const isValidImageUrl = (url) => {
    // Regular expression to match common image file extensions
    const imageExtensions = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;

    // Regular expression pattern to match a valid URL
    const urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    );

    // Test if the URL matches both patterns: valid URL format and image file extension
    return urlPattern.test(url) && imageExtensions.test(url);
};

export const isValidUrl = (url) => {
    // Regular expression pattern to match a valid URL
    const urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    );

    // Test if the URL matches both patterns: valid URL format and image file extension
    return urlPattern.test(url);
};

export const checkWebcam = async () => {
    try {
        // Check if the browser supports mediaDevices and enumerateDevices
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            console.log("MediaDevices API not supported");
            return;
        }

        // Get the list of available media devices
        const devices = await navigator.mediaDevices.enumerateDevices();

        // Check if any device is a video input (webcam)
        const hasWebcam = devices.some((device) => device.kind === "videoinput");

        // Update state based on webcam availability
        return hasWebcam;
    } catch (error) {
        console.error("Error checking webcam availability:", error);
    }
};


export const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}



export const groupVideosByDirectory = (videos)=> {
    const groupedVideos = [];
    const directories = {};

    // Iterate through each video
    Object.keys(videos[0]).forEach((videoKey) => {
        const videoUrl = videos[0][videoKey];
        const directory = videoUrl.split("/")[0] + "/" + videoUrl.split("/")[1] + "/" + videoUrl.split("/")[2] + "/"; // Extract directory name

        if (!directories[directory]) {
            directories[directory] = 0;
        }

        directories[directory]++;
    });

    // Convert the directories object to an array of objects
    Object.keys(directories).forEach((directory) => {
        // groupedVideos.push({ url: 'https://squadz-dev-bucket-02.s3.amazonaws.com/' + directory, clips: directories[directory] });
        groupedVideos.push({ url: "https://svc.staging.squadz.live/download/" + directory, clips: directories[directory] });
    });

    return groupedVideos;
}
