
import { useState, useEffect } from "react";
import Popup from "src/layouts/popup/popup";
import Button from "src/components/ui/button";
import FormInput from "src/components/form/input";
import { createModel } from "src/apis/aichats";

const ModalCharacterModel = (props) => {

    const [model, setModel] = useState({
        name: "",
        model: "",
        description:""
    });

    const handleCreateModel = async () => {
     
        let createRes = await createModel(model);
        console.log(createRes)
        if(createRes.indexOf('successfully') !== -1){
            props.OnHide(true);
        }
        
    };

    return (
        <Popup
            OnHide={() => {
                props.OnHide(true);
            }}
        >
            <div className="p-5 ">
                <h3>Create Model</h3>
                <div className="">
                    <FormInput
                        label={`Model :`}
                        placeholder={`Add model`}
                        value={model.model}
                        onChange={(e) => {
                            model.model = e.target.value;
                            setModel({ ...model });
                        }}
                        required={true}
                    />

                     <FormInput
                        label={`Name :`}
                        placeholder={`Add name`}
                        value={model.name}
                        onChange={(e) => {
                            model.name = e.target.value;
                            setModel({ ...model });
                        }}
                        required={true}
                    />


                    <FormInput
                        label={`Description :`}
                        type={"textarea"}
                        placeholder={`Prompt for your model`}
                        value={model.description}
                        onChange={(e) => {
                            model.description = e.target.value;
                            setModel({ ...model });
                        }}
                    />
                   

                    <Button disabled={(model.name ==='' || model.model ==='')? true : false} onClick={() => { handleCreateModel()}}> Create Model</Button>
                </div>
            </div>
        </Popup>
    );
};

export default ModalCharacterModel;
