import { useEffect, useState, useRef } from "react";
import FormInput from "src/components/form/input";
import Iicon from "src/components/iicon/iicon";
import Preloader from "src/components/preloader/preloader";
import VideoPlayer from "src/components/video-player";

const CharacterModel = (props) => {
    const { character } = props;
    const [fetching, setFetching] = useState(false);
    return (
        <>
            {fetching && <Preloader type={"full-relative"} />}
            <div className="tab-content tab-content-uploadvid">
                <div className="flex flex-col flex-1 mx-auto gap-8 p-4 md:p-10 w-full md:w-1/2">
                    {/* <div className="flex flex-1 flex-col">
                        <div className="flex-1">
                            <div className="mb-3 font-semibold text-sm mt-5">Your Character Model</div>
                            <div className="leading-5 opacity-40 w-1/2">Upload a video that will serve as your character model.</div>
                        </div>
                        <div className="leading-5 opacity-40 flex flex-row items-center mb-2">
                            <Iicon icon={"info"} className="mr-2" />
                            <div>To get best results, upload a steady video and have atleast a 5 seconds of mouth closed.</div>
                        </div>
                    </div> */}
                    <div className="flex gap-8 justify-between">
                        <div className="flex-1">
                            <FormInput
                                label={`Avatar Name :`}
                                placeholder={`Your avatar name`}
                                value={character.name}
                                onChange={(e) => {
                                    character.name = e.target.value;
                                    props.OnSet(character);
                                }}
                                required={true}
                            />

                            {props.nameIsUnique === false && (
                                <div className="text-red-600 px-3 pt-3"> "{character.name}" is already taken. Please select a different name</div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="form-item-label">
                            <div>Avatar Video : </div>
                            <span className="required-icon">*</span>
                        </div>

                        <div className="upload-box relative md:aspect-video flex-1 h-full  gap-4">
                            <div className="flex flex-col flex-1 items-center justify-center w-full h-full">
                                {character.fast0 === "" && (
                                    <div className="flex flex-1 items-center  justify-center  flex-col w-full h-full">
                                        <div>Please select a video to upload :</div>
                                        <div className="text-center mt-3">
                                            <FormInput
                                                label={`Select video to upload`}
                                                placeholder={`Select video to upload`}
                                                placeholderSelected={"Select a another video"}
                                                directory={character.id}
                                                value={character.fast0}
                                                type={"file"}
                                                accept={"video/*, .ts"}
                                                requiredToUpload={character.id}
                                                OnSelect={(file) => {
                                                    character.fast0 = file;
                                                    character.fast1 = URL.createObjectURL(file);
                                                    props.OnSet(character);
                                                }}
                                                OnUpload={() => {
                                                    setFetching(true);
                                                }}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                )}

                                {character && character.fast1.indexOf("http") !== -1 && (
                                    <div className="previewer-box flex-1  w-full">
                                        <button
                                            className="button button-small button-white"
                                            onClick={() => {
                                                character.fast1 = "";
                                                character.fast0 = "";
                                                character.modelpath = "";
                                                character.silence = "";
                                                props.OnSet(character);
                                                // setChar({ ...character });
                                            }}
                                        >
                                            clear
                                        </button>
                                        <VideoPlayer src={character.fast1} autoplay={false} />
                                    </div>
                                )}
                            </div>

                            <div className="p-0 md:p-5 rounded-xl info-floating flex flex-row md:flex-col gap-3">
                                <div>
                                    <Iicon icon="info" />
                                </div>
                                <div className="flex flex-col gap-3 flex-wrap md:mt-3">
                                    To get better results please upload a video that has atleast 10 sec of silent frames.
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="flex flex-row gap-5 items-center justify-between">
                        <div className="flex flex-col gap-1">
                            <div>Fine-tune: </div>
                            <div className="opacity-40">Improves quality but takes time to generate.</div>
                        </div>
                        {character.id === null ? (
                            <FormInput
                                type={"slider"}
                                value={props.fineTune}
                                onChange={(value) => {
                                    if (!props.OnFineTune) return;
                                    props.OnFineTune(value);
                                    // character.audioonly = value.toString();
                                    // props.OnSet(character);
                                }}
                            />
                        ) : (
                            <div>{character.mouth === "" ? "Disabled" : "Enabled"}</div>
                        )}
                    </div> */}

                    {/* <div className="flex flex-row gap-5 items-center justify-between">
                        
                        <div className="flex flex-col gap-1">
                            <div>Audio Only: </div>
                            <div className="opacity-40">Generate avatar with just audio.</div>
                        </div>
                        <FormInput
                            // label={`Audio Only :`}
                            type={"slider"}
                            value={character.audioonly === 'true' ? true : false}
                            onChange={(value) => {
                                character.audioonly = value.toString();
                                props.OnSet(character);
                            }}
                        />
                    </div> */}

                    {/* <div className="flex-1">
                        <div className="form-item-label">
                            <div>Avatar Image : </div>
                        </div>
                        <div className="upload-box relative aspect-video">
                            <div className="p-5 rounded-xl info-floating">
                                <Iicon icon="info" />
                                <div className="flex flex-col gap-3 flex-wrap mt-3">
                                    To get better results please upload a video that has atleast 10 sec of silent frames.
                                </div>
                            </div>

                            {character.image === "" && (
                                <div className="flex items-center flex-col">
                                    <div>Please select an image to upload :</div>
                                    <div className="text-center mt-3">
                                        <FormInput
                                            label={`Select image to upload`}
                                            placeholder={`Select image to upload`}
                                            placeholderSelected={"Select a another image"}
                                            value={character.image}
                                            type={"file"}
                                            accept={"image/*"}
                                            OnSelect={(file) => {
                                                character.image = file;
                                                props.OnSet(character);
                                            }}
                                            OnUpload={() => {
                                                setFetching(true);
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {character && character.image && (
                                <div className="previewer-box">
                                    <button
                                        className="button button-small button-white"
                                        onClick={() => {
                                            character.image = "";
                                            props.OnSet(character);
                                        }}
                                    >
                                        clear
                                    </button>
                                    <img src={character.image.name ? URL.createObjectURL(character.image) : character.image} className="preview" />
                                </div>
                            )}
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default CharacterModel;
