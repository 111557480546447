import { ReactLenis, useLenis } from "@studio-freight/react-lenis";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import Iicon from "src/components/iicon/iicon";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "src/pages/marketing/marketing.css";
import "src/pages/marketing/for-creators/for-creators.css";

import Header from "src/layouts/header/header";

import person1 from "src/assets/images/person-1.png";
import person2 from "src/assets/images/person-2.png";
import person3 from "src/assets/images/person-3.png";
import person4 from "src/assets/images/person-4.png";
import person5 from "src/assets/images/person-5.png";
import person6 from "src/assets/images/person-6.png";
import person7 from "src/assets/images/person-7.png";
import person8 from "src/assets/images/person-8.png";
import person9 from "src/assets/images/person-9.png";
import person10 from "src/assets/images/person-10.png";

import person11 from "src/assets/images/person-11.png";
import person12 from "src/assets/images/person-12.png";
import person13 from "src/assets/images/person-13.png";
import person14 from "src/assets/images/person-14.png";
import person15 from "src/assets/images/person-15.png";
import person16 from "src/assets/images/person-16.png";

import graphicChat from "src/pages/marketing/images/graphic-chat.svg";
import graphicFire from "src/pages/marketing/images/graphic-fire.svg";
import graphicMusic from "src/pages/marketing/images/graphic-music.svg";
import graphicToggle from "src/pages/marketing/images/graphic-toggle.svg";
import graphicThumb from "src/pages/marketing/images/graphic-thumb.svg";
import graphicFollowers from "src/pages/marketing/images/graphic-followers.svg";
import graphicPic from "src/pages/marketing/images/graphic-pic.svg";

import graphicHeart from "src/pages/marketing/images/graphic-noti-heart.svg";
import graphicHash from "src/pages/marketing/images/graphic-hashtag.svg";

import MarketingFooter from "../footer/footer";
import Menu from "src/components/menu/menu";

const JoinCreators = () => {
    const navigate = useNavigate();
    const main = useRef();

    useGSAP(
        () => {
            gsap.fromTo(
                ".m-header",
                { y: -50, opacity: 0, skewY: 1 },
                {
                    y: 0,
                    opacity: 1,
                    skewY: 0,
                }
            );
        },
        { scope: main }
    );

    const lenis = useLenis(({ velocity }) => {
        // gsap.to(".m-header", {
        //     skewY: velocity / 20,
        //     y: -Math.abs(velocity * 2),
        // });
        // gsap.to(".a-text", {
        //     skewY: velocity / 10,
        //     y: velocity * 4,
        // });
    });

    return (
        <ReactLenis root>
             <div className="md:hidden"><Menu market={true}/></div>
            <div className="pgm mx-auto" ref={main}>
                <div className="bg-floating opacity-40">
                    <div className="bg-violet"></div>
                    <div className="bg-torq"></div>
                </div>
                <div className="m-header">
                    <div className="box box-60 m-width mx-auto flex items-center mt-5 p-3 px-10 rounded-2xl overflow-hidden">
                        <Header menu={`market`} />
                    </div>
                </div>

                <div className="m-section-floater">
                    <div className="m-width mx-auto relative">
                        <div className="box box1 overflow-hidden ml-10 hidden">
                            <img src={person6} className="img-cover" />
                        </div>
                        {/* <div className="box box2 overflow-hidden">
                            <img src={person15} className="img-cover" />
                        </div> */}
                        <div className="box box3 overflow-hidden">
                            <img src={person8} className="img-cover" />
                        </div>
                        <img src={graphicHeart} className="graphic1 ml-40" />
                        <img src={graphicThumb} className="graphic2" />
                        <img src={graphicHash} className="graphic3 ml-10" />
                    </div>
                </div>

                <div className="m-section m-section-join-creator m-width flex flex-col mx-auto items-center mb-0">
                    <div className="a-text font-alt-extrabold  text-center text-5xl leading-tight md:text-7xl md:leading-tight  p-5 ">
                        <div className="aphrase">
                            <div className="aword">
                                Get
                                <span className="rounded-3xl px-4 mx-2" style={{ background: "#62E1BB" }}>
                                    paid
                                </span>
                                to
                            </div>
                        </div>
                        <div className="aphrase">
                            <div className="aword"> connect with fans.</div>
                        </div>
                    </div>

                    <div className="opacity-40 my-10 text-base font-alt-semibold text-center">Record personalized videos from your phone and start earning.</div>

                    <div className="flex flex-col md:w-1/2 mx-auto my-20 p-5">
                        <div className="form-item flex-1">
                            <div className="mb-4 ml-2 font-alt-semibold">Full name : </div>
                            <input type="text" placeholder="Your full name" />
                        </div>
                        <div className="form-item flex-1">
                            <div className="mb-4 ml-2  font-alt-semibold">Display name : </div>
                            <input type="text" placeholder="This appears on your profile" />
                        </div>

                        <div className="form-item flex-1">
                            <div className="ml-2 font-alt-semibold">Username : </div>
                            <div className="ml-2 opacity-40 text-xs mb-4">Use only letters, numbers, underscores, hyphens, and periods</div>
                            <input type="text" placeholder="Your username" />
                        </div>

                        <div className="form-item flex-1">
                            <div className="ml-2 font-alt-semibold">Email : </div>
                            <div className="ml-2 opacity-40 text-xs mb-4">Verify your email to continue</div>
                            <input type="text" placeholder="Your email" />
                        </div>

                        <div className="form-item flex-1">
                            <div className="ml-2 font-alt-semibold mb-4">Date of birth : </div>
                            <div className="flex flex-row gap-5 items-center">
                                <input type="text" placeholder="MM" />
                                <span>/</span>
                                <input type="text" placeholder="DD" />
                                <span>/</span>
                                <input type="text" placeholder="YYYY" />
                            </div>
                        </div>

                        <div className="form-item flex-1">
                            <div className="ml-2 font-alt-semibold">Phone number : </div>

                            <input type="text" placeholder="Your Phone Number" />
                        </div>

                        <div className="form-item flex-1">
                            <div className="ml-2 font-alt-semibold">Country : </div>
                            <input type="text" placeholder="Country" />
                        </div>

                        <div className="form-item flex-1">
                            <div className="ml-2 font-alt-semibold">Where is your largest following : </div>
                            <select>
                                <option>Facebook</option>
                                <option>Instagram</option>
                                <option>Tiktok</option>
                                <option>Twitter</option>
                                <option>Youtube</option>
                            </select>
                        </div>

                        <div className="form-item flex-1">
                            <div className="ml-2 mb-4 font-alt-semibold">Referral code (optional) : </div>
                            <input type="text" placeholder="Country" />
                        </div>

                        <div className="form-item flex-1">
                            <div className="ml-2 mb-4 font-alt-semibold">Anything else we should know about you? : </div>
                            <textarea rows={8}></textarea>
                        </div>

                        <div className="mt-5 flex flex-col">
                            <button className="button button-inline text-lg font-alt-bold px-10 flex-1">Submit</button>
                            <div className="mt-10">
                                By applying to enroll as talent on Fanlynk, you agree to Fanlynk's Talent Terms of Service and Privacy Policy
                            </div>
                        </div>
                    </div>
                </div>

                <MarketingFooter />
            </div>
        </ReactLenis>
    );
};

export default JoinCreators;
