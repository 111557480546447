import iconCheck from 'src/assets/images/icon-check.svg'
import iconNotAllowed from 'src/assets/images/icon-not-allowed.svg'

export const plans = [
    {
        name:'Basic',
        desc:'Just the basics.',
        price:'$5',
        inclusions:[
            {
                text:'2500 messages / month',
                icon: iconCheck
            },
            {
                text:'Chat history',
                icon: iconCheck
            },
            // {
            //     text:'No NSFW',
            //     icon: iconNotAllowed
            // }
        ]
    },

    {
        name:'Premium',
        desc:`Premium models and chat history. All the fun at the price of your GF's next Starbucks order.`,
        price:'$10',
        inclusions:[
            {
                text:'5000 messages / month',
                icon: iconCheck
            },
            {
                text:'Chat history',
                icon: iconCheck
            },
            {
                text:'Premium Models',
                icon: iconCheck
            },
            // {
            //     text:'NSFW',
            //     icon: iconCheck
            // }
        ]
    },

    {
        name:'Deluxe',
        desc:'All-access - Premium + uncensored NSFW chats. Bestseller for a reason.',
        price:'$35',
        inclusions:[
            {
                text:'2500 messages / month',
                icon: iconCheck
            },
            {
                text:'Chat history',
                icon: iconCheck
            },
            // {
            //     text:'NSFW',
            //     icon: iconCheck
            // },
            {
                text:'Voice',
                icon: iconCheck
            },
            {
                text:'Selfies',
                icon: iconCheck
            }
        ]
    }

    
]