import React, {useEffect, useState } from 'react';
import './filter.css'
import iconDropdown from  'src/assets/images/arrow-down.svg'
import { listAiStyle } from 'src/apis/aistyle';


const Filter = (props) => {
    // const [filters, setFilters] = useState([
    //     {
    //         label:'Sort By',
    //         options:[
    //             {label:'Featured'},
    //             {label:'Price: high to low'},
    //             {label:'Price: low to high'},
    //             {label:'Newest'},
    //             {label:'Alphabetical'}
    //         ]
    //     },
    //     {
    //         label: 'Price',
    //         options:[
    //             {label:'₱0 - ₱25'},
    //             {label:'₱25 - ₱50'},
    //             {label:'₱50 - ₱75'},
    //             {label:'₱75 - ₱100'},
    //             {label:'₱100+'},
    //             {label:'Custom'}
    //         ]
    //     }
    // ])



    // const [filters, setFilters] = useState([
    //     {
    //         label:'Sort By',
    //         options:[
    //             {label:'Featured'},
    //             {label:'Price: high to low'},
    //             {label:'Price: low to high'},
    //             {label:'Newest'},
    //             {label:'Alphabetical'}
    //         ]
    //     },
    //     {
    //         label: 'Price',
    //         options:[
    //             {label:'₱0 - ₱25'},
    //             {label:'₱25 - ₱50'},
    //             {label:'₱50 - ₱75'},
    //             {label:'₱75 - ₱100'},
    //             {label:'₱100+'},
    //             {label:'Custom'}
    //         ]
    //     }
    // ])

    const [tags, setTags] = useState([]);

    useEffect(()=>{
        listAiStyle("filter", true).then(res=>{
            setTags(res)
        })
    },[])
   
    

    // const [tags, setTags] = useState([
    //     {name:'Trending'},
    //     {name:'New'},
    //     {name:'Top Rated'}
    // ]);

    const [selectedTag, setSelectedTag] = useState('Trending');

    return ( 
        <div className="filter-bar">

            <div className='filter-tags'>
                {tags && tags.map((tag,t)=>(
                    <span key={t} 
                        className={'tag font-semibold ' + 
                            ((selectedTag === tag.name)? 'tag-selected' : '')
                        }
                        onClick={()=>{
                            setSelectedTag(tag.name)
                        }}
                    >{tag.name}</span>
                ))}
            </div>

            {/* <div>
                {filters.map(((filter,findex)=>(
                    <div key={findex} className={`filter-item`}> 
                        <div className='filter-item-label'>
                            <span>{filter.label}</span>
                            <img className='filter-item-icon-dropdown'  src={iconDropdown} /> 
                        </div>
                    </div>
                )))}
            </div> */}
        </div>
     );
}
 
export default Filter;