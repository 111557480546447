import React, { useEffect, useRef, useState } from "react";


const TTS = (props) => {
    let ttsPlayerRef = useRef();
    let objectURL = useRef();
    const [textToAudio, setTextToAudio] = useState(null);
    const [audState, setAudState] = useState('paused');

    useEffect(() => {
        if (props.chat && props.chat.text && props.chat.text !== "") {
            if (props.chat.voiceUrl) {
                setTextToAudio(props.chat.voiceUrl);
                ttsPlayerRef?.current?.play();
            } else {
                let cleanupText = props.chat.text.replaceAll(`"`,'')
                handleToVoice(cleanupText);
            }
        }

        ttsPlayerRef.current.addEventListener("playing", (event) => {
            setAudState("playing");
        });
        ttsPlayerRef.current.addEventListener("pause", (event) => {
            setAudState("paused");
        });

    }, [props.chat, ttsPlayerRef]);

    useEffect(()=>{
        if(props.OnState && props.chat){
            props.OnState(props.chat, audState)
        }
       
    },[audState])

    

    const handleToVoice = async (text) => {
        setAudState('fetching')
        let vStartTime = Date.now();
        console.log(`tovoice request: ${vStartTime}`, text);
        const options = {
            method: "POST",
            headers: {
                "xi-api-key": "99603c183a64c376379a33d37b06d7d8",
                "Content-Type": "application/json",
            },
            body: `{"text":"${text.replace(/[\x22[^\x20-\x7E]]/gmi, "")}","model_id":"eleven_multilingual_v2","voice_settings":{"similarity_boost":1,"stability":1,"style":0,"use_speaker_boost":true}}`,
        };


        fetch(`https://api.elevenlabs.io/v1/text-to-speech/${props.voiceid}/stream`, options)
            .then((response) => response.blob())
            .then((blobData) => {
                console.log(`tovoice response : ${Date.now()} delay(${(Date.now() - vStartTime) / 1000} sec)`);

                objectURL.current = URL.createObjectURL(blobData);
                console.log("Object URL:", objectURL.current);

                setTextToAudio(objectURL.current);
                props.chat.voiceUrl = objectURL.current
                setAudState('finished')
                
                
                // Release the object URL when done (if it's no longer needed)
                // URL.revokeObjectURL(objectURL);
            })
            .catch((err) => console.error(err));
    };

    return (
        <audio
            ref={ttsPlayerRef}
            autoPlay
            playsInline
            controls
            src={textToAudio}
            style={{ position: "absolute", top: 0, left: 0, zIndex: 999, visibility: "hidden" }}
        />
    );
};

export default TTS;
