import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import Iicon from "src/components/iicon/iicon";
import TypeText from "src/components/typetext/typetext";
import { epochToDate } from "src/components/utils";
import AudioRecorderWithLevels from "src/components/audio-recorder/audio-recorder";
import { sendChat, createSession, getAiChat, sendCreatorChat } from "src/apis/aichats";
import { getHistoryByUser } from "src/apis/aihistory";

import { getStoredUser } from "src/components/auth/auth";
import endpoint from "src/apis/endpoints";
import Pubnuber from "src/components/pubnuber/pubnuber";

import imgPlaceholder from "src/assets/images/image-placeholder.svg";
import { getModel } from "src/apis/aimodels";
import VideoPlayer from "src/components/video-player";
import CharacterSummary from "./tab-summary";

import { updateModel } from "src/apis/aimodels";

const CharacterAiCreate = (props) => {
    const { character } = props;
    let chatsEndRef = useRef();
    let chatsDivRef = useRef();
    let chatsBoxRef = useRef();
    let chatsRef = useRef([]);
    let aiStartTime = useRef();
    let pubMarker = useRef();
    let pubMsgArray = useRef([]);
    let pictureUploadRef = useRef();

    const [attachments, setAttachments] = useState([]);
    const [msgToPlay, setMsgToPlay] = useState();
    const [model, setModel] = useState();
    const [currentSession, setCurrentSession] = useState();

    const [chatInput, setChatInput] = useState("");
    const [chats, setChats] = useState([]);
    const [fetchingResponse, setFetchingResponse] = useState(false);
    const [fetchingPubResponse, setFetchingPubResponse] = useState(false);
    const [hasWebcam, setHasWebcam] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [pubChannels, setPubChannels] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [checkChange, setCheckChange] = useState({ state: null });

    const creatorId = "51fc3e0d-caac-4eb0-a516-90c8dd84a4e4";

    useEffect(() => {
        getModel({ id: creatorId })
            .then((res) => {
                setModel(res);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        //console.log("character :", character);
        if (character && character.id && character.session === "") {
            console.log("no creator session , creating...");
            createSession({ modelid: creatorId })
                .then((res) => {
                    if (res && res.id) {
                        character.session = res.id;
                        // props.OnCreateSession(character);
                        props.OnSet(character);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [character]);

    useEffect(() => {
        //console.log("character :", character);
        if (props.OnFetchingResponse) {
            props.OnFetchingResponse(fetchingResponse);
        }
    }, [fetchingResponse]);

    useEffect(() => {
        if (character.session) {
            getAiChat({ id: character.session, modelid: creatorId })
                .then((res) => {
                    console.log("creator session :", res);
                    setCurrentSession({ ...res });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [character.session]);

    useEffect(() => {
        if (character.name === "" && model) {
            // handleSendChat({
            //     text: "Hello, i want to start creating my avatar",
            //     from: "You",
            //     type: "sent",
            // });
        }
    }, [character, model]);

    useEffect(() => {
        if (currentSession && currentSession.id && model) {
            setPubChannels([currentSession.id + "-text"]);
            getChatHistory({ session: currentSession });

            // getHistoryByUser(getStoredUser()?.id, currentSession.id, 1).then((res) => {
            //     if (res && res.length <= 0) {
            //         handleSendChat({
            //             text: "Hello, i want to start creating my avatar",
            //             from: "You",
            //             type: "sent",
            //         });
            //     }
            // });
        }
    }, [currentSession, model]);

    useEffect(() => {
        chatsRef.current = [...chats];
        const hasIstemp = chatsRef.current.find((chat) => chat.id === "istemp");
        if (!hasIstemp) {
            setFetchingResponse(false);
        } else {
            setFetchingResponse(true);
        }

        scrollToBottom();
    }, [chats]);

    const updateChat = ({ id, updateId, updatedValues, appendText, setText, saveId, callback }) => {
        setChats((prevChats) => {
            return prevChats.map((chat) => {
                if (chat.id === id) {
                    if (appendText) {
                        chat.text = chat.text + appendText;
                    }
                    if (setText) {
                        chat.text = setText;
                    }
                    if (updatedValues) {
                        chat = { ...chat, ...updatedValues };
                    }

                    if (saveId) {
                        chat.saveId = saveId;
                    }

                    if (updateId) {
                        chat.id = updateId;
                    }

                    if (callback) {
                        callback(chat);
                    }
                }

                return { ...chat };
            });
        });
    };

    const getChatHistory = async ({ session }) => {
        setChats([]);
        let historyRes = await getHistoryByUser(getStoredUser()?.id, session.id, pageNum);
        console.log(`get history res page${pageNum}:`, historyRes);

        historyRes = historyRes.reverse();

        let historyChats = [];

        if (historyRes && historyRes.length > 0) {
            historyRes.map((history) => {
                // const findHistory = chatsRef.current.find((chat) => chat.id === history.id);
                // if (!findHistory) {
                let msgUser = {
                    createdate: history.createdate,
                    text: history.usertext,
                    from: "You",
                    type: "sent",
                };
                historyChats.push(msgUser);
                // let msgModel = {...history}

                let msgModel = {
                    createdate: history.createdate,
                    id: history.id,
                    vidurl: history.vidurl,
                    vidurl1: history.vidurl1,
                    text: history.modeltext,
                    from: model.name,
                    ishistory: true,
                    type: "received",
                };
                historyChats.push(msgModel);
                //}
            });
            setChats([...historyChats]);
        }
    };
    const handlePubnubMessage = async (msg) => {
        //console.log("pubMsgArray ::", pubMsgArray.current);
        if (msg.indexOf(`"cache"`) !== -1) {
            let parsedMsg = JSON.parse(msg);
            const received = Date.now();
            let fname = parsedMsg[0].cache.split("/")[parsedMsg[0].cache.split("/").length - 1];
            console.log(`video chunk received(${received}) - ${epochToDate(received)}:`, parsedMsg[0].cache);
            setMsgToPlay({
                received,
                video: parsedMsg[0].cache,
            });
        } else {
            //save msg to calculate if finished
            pubMsgArray.current.push(msg);
        }

        let msgMarker = pubMsgArray.current.filter((msg) => msg === "");

        if (msgMarker.length === 1 && pubMsgArray.current.indexOf("") === 0) {
            pubMarker.current = "start";
        } else if (msgMarker.length === 1 && pubMsgArray.current.indexOf("") !== 0) {
            pubMarker.current = "end";
        } else if (msgMarker.length >= 2) {
            pubMarker.current = "end";
        }

        if (msg.indexOf(`"fileNumber"`) === -1 && msg.indexOf(`"cache"`) === -1) {
            console.log(`pubnub event  : ${Date.now()} delay(${(Date.now() - aiStartTime.current) / 1000} sec) : "${msg}"`);
        }

        const hasIsTemp = chatsRef.current.find((chat) => chat.id === "istemp");

        if (pubMarker.current !== undefined) {
            if (msg.indexOf("[") === -1 && msg.indexOf("{") === -1) {
                if (!hasIsTemp && pubMarker.current !== "end") {
                    setFetchingPubResponse(false);
                    let mmsg = {
                        id: "istemp",
                        text: msg,
                        from: model.name,
                        type: "received",
                    };
                    setChats([...chatsRef.current, mmsg]);
                } else {
                    updateChat({ id: "istemp", appendText: msg });
                }
            } else if (msg.indexOf(`[{"filepaths3"`) !== -1) {
                let parsedMsg = JSON.parse(msg);
                let filepath = parsedMsg[0].filepaths3;

                let imsg = {
                    id: "isimage",
                    genimage: filepath,
                    text: "",
                    from: model.name,
                    isFiller: true,
                    type: "received",
                };

                setChats([...chatsRef.current, imsg]);
            }
        }
    };
    const handleAiResponse = async ({ message, sessionId }) => {
        setFetchingResponse(true);
        setFetchingPubResponse(true);

        let thellm = model.llm === "" ? "gpt-4" : model.llm;
        let theimage = null;

        console.log("attachments to send : ", attachments);
        if (attachments.length > 0) {
            theimage = attachments[0].src;
            thellm = "gpt-vision";
        }
        let theSessionId = sessionId ? sessionId : currentSession.id;
        try {
            aiStartTime.current = Date.now();

            console.log(`message payload: ${aiStartTime.current}`, theSessionId, character.id, model.llm, message);
            let messageRes = await sendCreatorChat({ sessionid: theSessionId, llm: thellm, msg: message, image: theimage, modelid: character.id });
            console.log(`message response : ${Date.now()} delay(${(Date.now() - aiStartTime.current) / 1000} sec) :`, messageRes);

            // if (currentSession.isbatch === "false") {
            //     setFetchingResponse(false);
            // }

            messageRes = messageRes[0];
            if (messageRes && messageRes.id) {
                setCheckChange({ state: "check" });
                // if(props.OnCheckChanges){
                //     props.OnCheckChanges(character)
                // }

                // if(messageRes.modelid){
                //     character.id = messageRes.modelid
                //     props.OnSet(character)
                // }

                scrollToBottom();

                updateChat({
                    id: "istemp",
                    updateId: messageRes.id,
                });

                // updateChat({
                //     id: "istemp",
                //     saveId: messageRes.id,
                // });
            }
        } catch (error) {
            await addFillerResponse("I beg your pardon, can you repeat the question?");
            console.log("message bot error : ", error);
            setFetchingResponse(false);
        }
    };

    const handleSendChat = async (msg) => {
        pubMarker.current = "start";

        // if (msg.text.indexOf("picture") !== -1) {
        //     handleGenImage(msg);
        // }

        if (msg && msg != "") {
            setChats([...chatsRef.current, msg]);
            setChatInput("");
            await handleAiResponse({ message: msg.text });
        }
    };

    const handleAttach = (file) => {
        let fileReader;
        let fileid = uuidv4();
        console.log(file);
        const handleFileRead = async (e) => {
            let filecontent = fileReader.result;

            let thefile = {
                id: fileid,
                type: file.type,
                src: filecontent,
                status: "uploading",
            };
            let theAttachments = [...attachments, { ...thefile }];

            setAttachments([...theAttachments]);

            // UPLOAD
            const formData = new FormData();
            formData.append(`auth`, getStoredUser()?.auth);

            formData.append(`userid`, getStoredUser()?.id);
            formData.append(`directory`, currentSession.id);
            formData.append(`segment`, thefile);
            formData.append(`uploadfile`, file);

            let res = await axios.post(`${endpoint.upload}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            theAttachments.map((attch) => {
                if (attch.id === fileid) {
                    const uploadedsrc = `${res.data[0].filepaths3}`;
                    attch.src = uploadedsrc;
                    attch.status = "uploaded";
                }
                return { ...attch };
            });

            setAttachments([...theAttachments]);
        };
        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsDataURL(file);
    };

    const handleDetach = (file) => {
        let udaptedAttachments = attachments.filter((attch) => attch.src !== file.src);
        setAttachments([...udaptedAttachments]);
        if (pictureUploadRef.current) {
            pictureUploadRef.current.value = "";
        }
    };

    const handleRecordAudio = () => {
        setIsRecording(!isRecording);
    };

    const addFillerResponse = async (text) => {
        let fillertimer;
        return new Promise((resolve) => {
            fillertimer = setTimeout(() => {
                let responsemsg = {
                    id: "000",
                    text: text,
                    //text: "Give me a second to really think this through.Give me a second to really think this through.Give me a second to really think this through.Give me a second to really think this through.Give me a second to really think this through.",
                    from: model.name,
                    isFiller: true,
                    type: "received",
                };

                setChats([...chatsRef.current, responsemsg]);

                resolve(true);
                clearTimeout(fillertimer);
            }, 800);
        });
    };

    const resetSession = async () => {
        if (character) {
            const charInput = { ...character };
            delete charInput.id;
            delete charInput.createdate;
            charInput.session = "";
            let updateCharRes = await updateModel(character.id, charInput);
            console.log("character session reseted : ", updateCharRes);
            window.location.reload();
        }
    };

    const scrollToBottom = () => {
        if (chatsDivRef.current) {
            chatsDivRef.current.scrollTop = chatsDivRef.current.scrollHeight;
        }
    };

    return (
        <div className="flex flex-row gap-10 p-10 creator-chat-wrapper">
            <div className="flex flex-1 flex-col gap-3 h-full relative">
                {/* <button
                    className="button button-small button-trans"
                    onClick={() => {
                        resetSession();
                    }}
                >
                    reset session
                </button> */}

                {pubChannels && <Pubnuber channels={pubChannels} OnMessage={(msg) => handlePubnubMessage(msg)} />}
                {/*  -----------  CHAT BOX  -----------  */}
                <div className="chats-box creator-chats" ref={chatsBoxRef}>
                    <div className="chats " ref={chatsDivRef}>
                        {chats.map((chat, c) => (
                            <div key={c} className={`chat chat-${chat.type} chat-${chat.id ? chat.id : "you"}`}>
                                <div className="pt-2 px-2">
                                    {chat.genimage && chat.genimage !== "" && (
                                        <div className="genimage">
                                            <img src={chat.genimage} />
                                        </div>
                                    )}
                                    <div className="flex flex-row ">
                                        {chat.type === "received" && (
                                            <div className="model-image mr-3">
                                                <img src={model.image === "" ? imgPlaceholder : model.image} />
                                            </div>
                                        )}

                                        <div className="flex flex-col">
                                            <div className="flex flex-row justify-content-between items-center">
                                                <small className="opacity-50 mb-1">{chat.from}</small>
                                            </div>

                                            <div className="leading-5">
                                                {chat.type === "received" && !chat.ishistory ? (
                                                    <TypeText
                                                        isCompleted={
                                                            //pubMsgArray.current.filter((msg) => msg === "").length >= 2 ? true : false
                                                            pubMarker.current === "end" ? true : false
                                                        }
                                                        text={chat.text}
                                                        OnType={() => {
                                                            scrollToBottom();
                                                        }}
                                                        OnFinished={() => {
                                                            console.log("(!) finished typing text");
                                                            pubMsgArray.current = [];

                                                            // console.log("chat obj:", chat)
                                                            // if (chat.id === "istemp" && chat.saveId) {
                                                            //     chat.id = chat.saveId;
                                                            //     setChats([...chats]);
                                                            //     setFetchingResponse(false);
                                                            // }

                                                            scrollToBottom();
                                                        }}
                                                    />
                                                ) : (
                                                    chat.text
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {fetchingPubResponse && <div className="fetching-response opacity-25 p-3 text-white"> {model.name} is thinking... </div>}
                    </div>

                    <div ref={chatsEndRef}></div>
                </div>

                {/*  -----------  CHAT INPUT -----------  */}
                <div className="chat-input flex-col">
                    {currentSession && (
                        <>
                            {isRecording && (
                                <AudioRecorderWithLevels
                                    session={currentSession}
                                    OnTranscribe={async (text) => {
                                        setIsRecording(false);

                                        let msg = {
                                            text: text,
                                            from: "You",
                                            type: "sent",
                                        };

                                        await handleSendChat(msg);
                                    }}
                                    OnCancel={() => {
                                        setIsRecording(false);
                                    }}
                                />
                            )}
                            {!isRecording && (
                                <div className="flex flex-1 flex-col relative">
                                    <div className="flex flex-1  slideFromTop">
                                        <input
                                            type="text"
                                            placeholder="Say something nice..."
                                            value={chatInput}
                                            onChange={(e) => {
                                                setChatInput(e.target.value);
                                            }}
                                            onKeyDown={async (e) => {
                                                if (e.key === "Enter" && e.target.value !== "" && !fetchingResponse) {
                                                    let msg = {
                                                        text: e.target.value,
                                                        from: "You",
                                                        type: "sent",
                                                    };

                                                    await handleSendChat(msg);
                                                }
                                            }}
                                        />
                                        <div className="chat-input-actions">
                                            {/* <div className="flex flex-1">
                                                <div className="item">
                                                    <i className={`iicon iicon-picture link link-icon`}>
                                                        <input
                                                            ref={pictureUploadRef}
                                                            type="file"
                                                            accept="image/*"
                                                            className="input-file"
                                                            onChange={(e) => {
                                                                handleAttach(e.target.files[0]);
                                                            }}
                                                        />
                                                    </i>
                                                </div>
                                                <div className="item">
                                                    {hasWebcam && (
                                                        <i
                                                            className="iicon iicon-mic link link-icon"
                                                            onClick={() => {
                                                                handleRecordAudio();
                                                            }}
                                                        ></i>
                                                    )}
                                                </div>
                                            </div> */}

                                            {!fetchingResponse ? (
                                                <div className="item">
                                                    <div
                                                        className="px-4 border-l-2"
                                                        onClick={async () => {
                                                            if (chatInput !== "") {
                                                                let msg = {
                                                                    text: chatInput,
                                                                    from: "You",
                                                                    type: "sent",
                                                                };
                                                                await handleSendChat(msg);
                                                            }
                                                        }}
                                                    >
                                                        <Iicon icon={"send"} className={`link link-icon ${chatInput === "" ? "opacity-25" : ""}`} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="px-4 border-l-2">
                                                    <Iicon icon={"loader-small"} className={`spinning`} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            <div className="flex flex-col w-1/2 h-full overflow-hidden relative">
                <div className="creator-summary ">
                    <CharacterSummary
                        character={character}
                        checkChange={checkChange}
                        OnSet={(character) => {
                            props.OnSet(character);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default CharacterAiCreate;
