import Header from "src/layouts/header/header";
import Main from "src/layouts/main/main";
import { useNavigate } from "react-router-dom";

import "./account.css";

import Menu from "src/components/menu/menu";
import { plans } from "src/data/plans";
import Button from "src/components/ui/button";

import iconCheckCircle from "src/assets/images/icon-check-circle.svg";
import { useEffect, useState } from "react";
import FormInput from "src/components/form/input";
import iconBot from "src/assets/images/icon-bot.svg";
import { createModel } from "src/apis/aimodels";
import { getStoredUser, updateStoredUser } from "src/components/auth/auth";
import { updateUser } from "src/apis/user";
import Preloader from "src/components/preloader/preloader";

const Account = (props) => {

    const navigate = useNavigate();

    const [user, setUser] = useState();
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        if (getStoredUser() && getStoredUser()?.id) {
            setUser(getStoredUser());
        } else {
            navigate("/");
        }
    }, []);

    const handleUserImageUpload = (res) => {
        console.log(res);

        let thebucket = res.url.split("/")[0];
        thebucket = thebucket.split(".")[0];

        let thekey = res.key;

        let userImage = {
            abucket: thebucket,
            akey: thekey,
            aregion: "us-east-1",

            mbucket: thebucket,
            mkey: thekey,
            mregion: "us-east-1",

            tbucket: thebucket,
            tkey: thekey,
            tregion: "us-east-1",

            cbucket: thebucket,
            ckey: thekey,
            cregion: "us-east-1",
        };

        setUser({ ...user, ...userImage });
    };


    const handleSave = async () => {
        setFetching(true)
        // let userInput = {...user}
        // delete userInput.id
        // delete userInput.createdate
        // delete userInput.updatedate

        let userInput = {
            name: user.name,
            description: user.description,

            abucket: user.abucket,
            akey: user.akey,

            mbucket: user.mbucket,
            mkey: user.mkey,

            tbucket: user.tbucket,
            tkey: user.tkey,

            cbucket: user.cbucket,
            ckey: user.ckey,
        };

        console.log("user to save :: ", userInput);

        let updateUserRes = await updateUser(user.id, userInput);
        setFetching(false)
        updateStoredUser(updateUserRes)
        setUser({...updateUserRes})
        
    };

    return (
        <Main>
            {fetching && <Preloader type={'full-relative'} />}
            <div className="box flex flex-1 flex-col overflow-hidden p3 md:p-6">
                <div className="flex items-center my-1">
                    <div
                        className="navi navi-back mr-2"
                        onClick={() => {
                            navigate("/avatars");
                        }}
                    >
                        <i className="iicon iicon-nav-back"></i>
                    </div>
                    <div className="text-base mr-3 font-semibold flex-1">Your Account</div>

                    <Button
                        onClick={() => {
                            handleSave();
                        }}
                        buttonStyle={"inline"}
                    >
                        Save
                    </Button>
                </div>

                {user && (
                    <div className="main-account-form overflow-scroll">
                        <div className="flex flex-col gap-6 mx-auto  p-4 md:p-10 w-full md:w-1/2">
                           
                            <div className="flex flex-col flex-1 gap-6">
                                <FormInput
                                    label={`Name :`}
                                    placeholder={`Your name`}
                                    value={user.name}
                                    onChange={(e) => {
                                        user.name = e.target.value;
                                        setUser({ ...user });
                                    }}
                                    required={true}
                                />

                               
                                <div className="character-image-upload-box">
                                    {user.image === "" && (
                                        <div className="upload-box">
                                            <div>Please select your image to upload :</div>
                                            <div className="text-center mt-3">
                                                <FormInput
                                                    label={`Browse files`}
                                                    placeholder={`Your image`}
                                                    value={user.image}
                                                    type={"file"}
                                                    accept={"image/*"}
                                                    className={`mb-0`}
                                                    OnSelect={(file) => {
                                                        user.image = file;
                                                        setUser({ ...user });
                                                    }}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {user.image !== "" && (
                                        <div className="character-preview-image previewer-box">
                                            <button
                                                className="button button-small button-white"
                                                onClick={() => {
                                                    user.image = "";
                                                    setUser({ ...user });
                                                }}
                                            >
                                                clear
                                            </button>
                                            <img
                                                src={
                                                    user.image && user.image.name
                                                        ? URL.createObjectURL(user.image)
                                                        : `https://${user.tbucket}.s3.amazonaws.com/${user.tkey}`
                                                }
                                                className="preview"
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="mt-10" style={{ width: "100%" }}>
                                    <FormInput
                                        label={`Description :`}
                                        type={"textarea"}
                                        placeholder={`Describe your character`}
                                        value={user.description}
                                        onChange={(e) => {
                                            user.description = e.target.value;
                                            setUser({ ...user });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* <div className="panel me-5 slide slideFromBot">
                            <div className="flex align-items-center mb-4">
                                <h3 className="flex-1">Profile</h3>
                            </div>

                            <div className="flex flex-row">
                                <div className="mt-5">
                                    <div className="account-preview-image">
                                        {user.tbucket !== "" ? (
                                            <img src={`https://${user.tbucket}.s3.amazonaws.com/${user.tkey}`} className="user-img" />
                                        ) : (
                                            <img src={iconBot} className="default-img" />
                                        )}
                                    </div>

                                    <div className="text-center mt-3">
                                        <FormInput
                                            label={`Upload user Image`}
                                            placeholder={`Your user image`}
                                            value={user.image}
                                            type={"file"}
                                            OnSuccess={(res) => {
                                                handleUserImageUpload(res);
                                                // user.image = `${res.filepaths3}`;
                                                // setUser({ ...user });
                                            }}
                                            required={true}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col flex-1 ms-5">
                                    <div className="flex-0">
                                        <FormInput
                                            label={`Name :`}
                                            placeholder={`Your name`}
                                            value={user.name}
                                            onChange={(e) => {
                                                user.name = e.target.value;
                                                setUser({ ...user });
                                            }}
                                            required={true}
                                        />
                                    </div>

                                    <div className="flex flex-1">
                                        <FormInput
                                            label={`Description :`}
                                            type={"textarea"}
                                            placeholder={`Describe yourself`}
                                            value={user.description}
                                            onChange={(e) => {
                                                user.description = e.target.value;
                                                setUser({ ...user });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="panel slideFromBot">
                            <div className="flex align-items-center mb-4">
                                <h3 className="flex-1">Subscription</h3>

                                <Button
                                    onClick={() => {
                                        handleSave();
                                    }}
                                    buttonStyle={"inline"}
                                >
                                    Save
                                </Button>
                            </div>

                            <div className="account-plan flex flex-col flex-1">
                                <div className="flex flex-col flex-1">
                                    <div className="flex-1">
                                        You are currently subscribed to{" "}
                                        <span
                                            className="link ms-2"
                                            onClick={() => {
                                                navigate("/pricing");
                                            }}
                                        >
                                            {" "}
                                            Free Plan
                                        </span>
                                        .
                                    </div>

                                    <Button
                                        onClick={() => {
                                            navigate("/pricing");
                                        }}
                                    >
                                        Upgrade plan
                                    </Button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                )}
            </div>
        </Main>
    );
};

export default Account;
