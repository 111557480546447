import { useEffect, useState } from "react";

import Card from "src/components/card/card";
import Filter from "src/components/filter/filter";

import Grid from "src/layouts/grid/grid";
import Header from "src/layouts/header/header";
import Main from "src/layouts/main/main";
import Footer from "src/layouts/footer";
import Menu from "src/components/menu/menu";
import { getMyModels, getModels } from "src/apis/aimodels";
import Button from "src/components/ui/button";

import iconFull from "src/assets/images/icon-full.svg";
import iconGrid from "src/assets/images/icon-grid.svg";
import Preloader from "src/components/preloader/preloader";
import { getStoredUser } from "src/components/auth/auth";
import { useNavigate } from "react-router-dom";
import TitleBar from "src/layouts/title-bar/title-bar";

const MyAvatarz = (props) => {
    const navigate = useNavigate();
    const [models, setModels] = useState([]);

    const [pageNum, setPageNum] = useState(1);
    const [fetchingMore, setFetchingMore] = useState(false);

    useEffect(() => {
        handleModels({ opr: "list", pageNum });
        return () => {
            setModels([]);
        };
    }, []);

    const handleModels = async ({ opr, pageNum }) => {
        setFetchingMore(true);

        // /console.log("pageNum :: ", pageNum);
        switch (opr) {
            case "list":
                let listRes = await getModels({ userid: getStoredUser()?.id }, pageNum);
                console.log(listRes);
                if (listRes) {
                    setModels([...models, ...listRes]);

                    if (listRes.length > 0) {
                        setPageNum(pageNum + 1);
                    }
                }
                break;

            default:
                break;
        }
        setFetchingMore(false);
    };

    return (
        <Main>
            <TitleBar length={models?.length}>
                <div className="p-3 flex flex-1 items-center">
                    <div
                        className="navi navi-back mr-2"
                        onClick={() => {
                            navigate("/avatars");
                        }}
                    >
                        <i className="iicon iicon-nav-back"></i>
                    </div>
                    <div className="text-base mr-5 font-semibold">My Avatars</div>
                    <div className="opacity-50">Avatars you have created.</div>
                </div>
            </TitleBar>

            {pageNum === 1 && !fetchingMore && models.length <= 0 ? (
                <div className="flex flex-1 items-center justify-center flex-col ">
                    <div>You have not created any characters yet. </div>
                </div>
            ) : (
                <Grid
                    OnBottom={async () => {
                        handleModels({ opr: "list", pageNum });
                    }}
                >
                    {models.map((model, m) => (
                        <Card key={m} model={model} navigateEdit={`/character/${model.id}`} />
                    ))}
                </Grid>
            )}
            {fetchingMore && <Preloader type={"more"} />}
        </Main>
    );
};

export default MyAvatarz;
