import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Iicon from "src/components/iicon/iicon";
import Preloader from "src/components/preloader/preloader";
import { addToQueue, getQueue } from "src/apis/utils";
import { updateModel, getModel } from "src/apis/aimodels";
import FormInput from "src/components/form/input";
import RadioTab from "src/components/form/radio-tab/radio-tab";
import axios from "axios";

const ChatHeader = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { model, botId, currentSession, botState, isAudioOnly, videoTypes } = props;
    const [showSessions, setShowSessions] = useState(true);

    const [fetching, setFetching] = useState(false);
    let intervalModelGen = useRef();
    let intervalSilence = useRef();
    let intervalMouth = useRef();

    const fpsTypes = [
        {
            id: "fullfps",
            name: "full",
        },
        {
            id: "halffps",
            name: "half",
        },
    ];

    useEffect(() => {
        if (props.OnShowSessions) {
            props.OnShowSessions(showSessions);
        }
    }, [showSessions]);

    useEffect(() => {
        return () => {
            if (intervalModelGen.current) {
                clearInterval(intervalModelGen.current);
            }
            if (intervalSilence.current) {
                clearInterval(intervalSilence.current);
            }
            if (intervalMouth.current) {
                clearInterval(intervalMouth.current);
            }
        };
    }, []);

    useEffect(() => {
        //console.log("model---->", model);
        if (!model) return;

        if (!intervalModelGen.current) {
            pollModelGen();
        }
        if (!intervalSilence.current) {
            pollSilence();
        }

        // if (model.mouth.indexOf("generating") !== -1) {
        //     pollMouth();
        // }
        // if (model.mouth.indexOf("s3:") !== -1 && model.modelpath === "") {
        //     generateModel(model);
        // }
    }, [model]);

    useEffect(() => {
        if (botId) {
            setFetching(false);
        }
    }, [botId]);

    const pollSilence = async () => {
        const run = async () => {
            let sQueId = model.silence.split("_")[2];
            let silentQueRes = await getQueue({ id: sQueId });
            console.log("polling SILENCE res", silentQueRes);
            if (silentQueRes && silentQueRes.state) {
                //SILENCE QUE COMPLETED
                if (silentQueRes.state.indexOf("complete") !== -1) {
                    let modelPathRes = await getModel({ id: model.id });
                    if (modelPathRes && modelPathRes.modelpath.indexOf("s3:") !== -1) {
                        props.OnSet({ ...modelPathRes });
                        clearInterval(intervalSilence.current);
                    }
                    //SILENCE QUE NO SILENCE
                } else if (silentQueRes.state.indexOf("No silences longer than 1s found") !== -1) {
                    model.silence = `error_no_silence_${sQueId}`;
                    props.OnSet({ ...model });
                    clearInterval(intervalSilence.current);
                } else if (silentQueRes.state.indexOf("Unsupported language") !== -1) {
                    model.silence = `error_unsupported_lang_${sQueId}`;
                    props.OnSet({ ...model });
                    clearInterval(intervalSilence.current);
                } else if (silentQueRes.state.indexOf("error") !== -1 || silentQueRes.state.indexOf("Error") !== -1) {
                    model.silence = `error_generic_${sQueId}`;
                    props.OnSet({ ...model });
                    clearInterval(intervalSilence.current);
                } else if (silentQueRes && silentQueRes.length <= 0) {
                    clearInterval(intervalSilence.current);
                }
            }
        };
        //if (model && model.silence.indexOf("generating") !== -1 && model.static.indexOf("generating") !== -1) {
        if ((model && model.silence.indexOf("generating") !== -1) || model.static.indexOf("generating") !== -1) {
            run();
            intervalSilence.current = setInterval(() => {
                run();
            }, 10000);
        }
    };

    const pollModelGen = async () => {
        // const run = async () => {
        //     let modelPathRes = await getModel({ id: model.id });
        //     console.log("polling MODEL GEN res", modelPathRes);
        //     if (modelPathRes && modelPathRes.modelpath) {
        //         if (modelPathRes.modelpath.indexOf("s3:") !== -1) {
        //             props.OnSet({ ...modelPathRes });
        //             clearInterval(intervalModelGen.current);
        //         }
        //     }
        // };

        const run = async () => {
            let mQueId = model.modelpath.split("_")[2];
            let genQueRes = await getQueue({ id: mQueId });
            console.log("polling MODEL GEN res", genQueRes);
            if (genQueRes && genQueRes.state) {
                //MODEL GEN QUE COMPLETED
                if (genQueRes.state.indexOf("complete") !== -1) {
                    let modelPathRes = await getModel({ id: model.id });
                    if (modelPathRes && modelPathRes.modelpath.indexOf("s3:") !== -1) {
                        props.OnSet({ ...modelPathRes });
                        clearInterval(intervalModelGen.current);
                    }
                } else if (genQueRes.state.indexOf("error") !== -1 || genQueRes.state.indexOf("Error") !== -1) {
                    model.modelpath = `error_${mQueId}`;
                    props.OnSet({ ...model });
                    clearInterval(intervalModelGen.current);
                } else if (genQueRes && genQueRes.length <= 0) {
                    clearInterval(intervalModelGen.current);
                }
            }
        };
        if (model && model.modelpath.indexOf("generating") !== -1) {
            run();
            intervalModelGen.current = setInterval(() => {
                run();
            }, 10000);
        }
    };

    const pollMouth = async () => {
        const run = async () => {
            const mQueId = model.mouth.split("_")[2];
            const queRes = await getQueue({ id: mQueId });
            console.log("polling MOUTH res", queRes);
            if (queRes && queRes.state) {
                if (queRes.state.indexOf("complete") !== -1) {
                    const modelRes = await getModel({ id: model.id });
                    if (modelRes && modelRes.mouth.indexOf("s3:") !== -1) {
                        props.OnSet({ ...modelRes });
                        generateModel(modelRes);
                        clearInterval(intervalMouth.current);
                    }
                } else if (queRes.state.indexOf("error") !== -1 || queRes.state.indexOf("Error") !== -1) {
                    model.mouth = `error_${mQueId}`;
                    props.OnSet({ ...model });
                    clearInterval(intervalModelGen.current);
                } else if (queRes && queRes.length <= 0) {
                    clearInterval(intervalMouth.current);
                }
            }
        };
        if (model && model.mouth.indexOf("generating") !== -1) {
            run();
            intervalMouth.current = setInterval(() => {
                run();
            }, 10000);
        }
    };

    const generateModel = async (char) => {
        setFetching(true);
        const modelToQue = {
            userid: char.userid,
            name: char.name,
            modelid: char.id,
            src: char.mouth,
        };
        console.log("mouth: adding to queue:", modelToQue);
        // GENERATE SILENCE
        const queSilence = async () => {
            //const resSilentQue = "test2";
            let resSilentQue = await addToQueue({ key: "silence", value: JSON.stringify(modelToQue) });
            console.log("mouth: silence : added to queue : ", resSilentQue);
            char.silence = `generating_silence_${resSilentQue?.id}`;
            return resSilentQue;
            // pollSilence();
        };

        //MODELGEN
        const queGen = async () => {
            //const resQue = "test3";
            let resQue = await addToQueue({ key: "video", value: JSON.stringify(modelToQue) });
            console.log("mouth: model gen : added to queue : ", resQue);
            //const initModelRes = await intiModelGenerator({ name: charinput.name, modelid: createRes.id, src: createRes.fast0 });
            //console.log("create initModelRes :", initModelRes);
            char.modelpath = `generating_model_${resQue?.id}`;
            return resQue;
        };

        const allPromise = Promise.all([queSilence(), queGen()]);

        try {
            const values = await allPromise;
            console.log(values); // [resolvedValue1, resolvedValue2]
        } catch (error) {
            console.log(error); // rejectReason of any first rejected promise
        }

        const charInput = { ...char };
        delete charInput.id;
        delete charInput.createdate;

        let updateCharRes = await updateModel(char.id, charInput);
        props.OnSet({ ...char });
        setFetching(false);
    };

    const isFetching = (model) => {
        if (
            ((model.silence.indexOf("generating") !== -1 && model.static.indexOf("generating") !== -1) ||
                model.modelpath.indexOf("generating") !== -1 ||
                model.mouth.indexOf("generating") !== -1) &&
            model.modelpath.indexOf("error") === -1 &&
            model.silence.indexOf("error_") === -1
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <div className="chats-box-header relative oveflow-hidden p-7">
                {fetching && <Preloader type={"full-relative"} text="none" />}
                <div
                    className="navi navi-back"
                    onClick={() => {
                        if (location?.state?.from === "create") {
                            navigate("/my-avatars");
                        } else {
                            navigate(-1);
                        }
                    }}
                >
                    <i className="iicon iicon-nav-back-white"></i>
                </div>

                <div className="flex flex-col md:flex-row flex-1 md:ms-2 info items-center">
                    <div className="name text-lg flex-1 font-semibold flex gap-2 items-center text-white">
                        <div>{model?.name}</div>
                        {botId && botState && botState.state === "started" && <div className="bg-green-400 w-2 h-2 rounded-xl"></div>}
                    </div>

                    {/* <RadioTab
                        tabs={fpsTypes}
                        OnSelectType={async (val) => {
                            console.log("fps type :: ", val.id);
                            try {
                                const headers = {
                                    'Access-Control-Allow-Origin': '*', // Allow all origins (for development purposes)
                                    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE', // Allow these methods
                                    'Access-Control-Allow-Headers': 'Content-Type, Authorization' // Allow these headers
                                  };
                                const url = `https://54.89.24.57.traefik.me/${val.id}`;
                                const fps_res = await axios.get(url,{ headers: headers });
                                console.log("fps_res :: ", fps_res);
                            } catch (error) {
                                console.log(error);
                            }
                        }}
                    /> */}

                    <div className="ms-0 md:ms-4 flex items-center justify-items-end ">
                        {!botId && (
                            <RadioTab
                                tabs={videoTypes}
                                OnSelectType={(val) => {
                                    if (props.OnSelectVideoType) {
                                        props.OnSelectVideoType(val);
                                    }
                                }}
                            />
                        )}

                        {botId && (
                            <div className="bg-gray-200 rounded-xl px-3 py-1.5 text-uppercase opacity-60 font-semibold" style={{ fontSize: "11px" }}>
                                {props.videoType.name}
                            </div>
                        )}

                        {currentSession && currentSession.audioonly === "true" && (
                            <div className="tag-green bg-gray-100 text-black-600 py-1.5 px-3 rounded-xl ml-4 slideFromTop">Audio Only</div>
                        )}
                    </div>
                </div>

                {!showSessions && (
                    <div
                        className="item flex-0 item-icon ibutton-collapse"
                        onClick={() => {
                            setShowSessions(true);
                        }}
                    >
                        <i className="iicon iicon-collapse link link-icon" />
                    </div>
                )}
            </div>

            {model && isFetching(model) && (
                <div className="px-8  w-full relative ">
                    <span className="py-2 px-3 text-nowrap flex items-center slideFromBot bg-orange-100 rounded-2xl ">
                        <i className={"iicon iicon-loader-small spinning"} /> <span className="ms-2">Please wait. Fetching your character. </span>
                    </span>
                </div>
            )}

            {model && model.silence && model.silence.indexOf("error") !== -1 && model.modelpath.indexOf("error") === -1 && (
                <div className="px-8  w-full relative ">
                    <span className="text-nowrap flex items-center slideFromTop py-2 px-4 bg-orange-100 rounded-2xl">
                        <span className="flex-1 flex flex-row gap-3 items-center">
                            <i className={"iicon iicon-warn"} />

                            {model.silence.indexOf("no_silence") !== -1 && (
                                <span className="flex-1">Your video needs more silent frames. Please upload a new version with this adjustment. </span>
                            )}

                            {model.silence.indexOf("unsupported_lang") !== -1 && (
                                <span className="flex-1">Your video language is not supported. Please upload a new version with this adjustment. </span>
                            )}

                            {model.silence.indexOf("error_generic") !== -1 && (
                                <span className="flex-1">We have issues processing audio for your video. Please upload a new version </span>
                            )}
                        </span>
                        <span>
                            <button
                                className="button button-small button-white p-1 px-3 ml-2"
                                onClick={() => {
                                    navigate(`/character/${model.id}`);
                                }}
                            >
                                Reupload
                            </button>
                        </span>
                    </span>
                </div>
            )}

            {model && model.modelpath && model.modelpath.indexOf("error") !== -1 && (
                <div className="px-8  w-full relative ">
                    <span className="text-nowrap flex items-center slideFromTop  py-2 px-4 bg-orange-100 rounded-2xl">
                        <span className="flex flex-1 gap-3 items-center">
                            <i className={"iicon iicon-warn"} />

                            <span className="flex-1">We have issues processing your video. Please upload a new version </span>
                        </span>
                        <span>
                            <button
                                className="button button-small button-white p-1 px-3 ml-2"
                                onClick={() => {
                                    navigate(`/character/${model.id}`);
                                }}
                            >
                                Reupload
                            </button>
                        </span>
                    </span>
                </div>
            )}
        </>
    );
};

export default ChatHeader;
