import iconSearch from "src/assets/images/icon-search.svg";
import "./ui.css";
import Button from "src/components/ui/button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Iicon from "../iicon/iicon";

const UI_SearchBar = (props) => {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");

    const handleSearch = () => {
        if (searchText !== "") {
            navigate("/search", {
                state: {
                    searchText: searchText,
                },
            });
        }
    };

    return (
        <div className="search-bar">
            <input
                type="text"
                value={searchText}
                placeholder="Search ..."
                onChange={(e) => {
                    setSearchText(e.target.value);
                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleSearch();
                    }
                }}
            />

            
                
            <img
                src={iconSearch}
                className="icon-search pointer link-icon"
                onClick={() => {
                    handleSearch();
                }}
            />

            {/* <Button onClick={()=>{ 
                handleSearch()
            }}>Search</Button> */}
        </div>
    );
};

export default UI_SearchBar;
