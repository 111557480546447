import Header from "src/layouts/header/header";
import Main from "src/layouts/main/main";
import { useNavigate } from "react-router-dom";

import "./identities.css";

import Menu from "src/components/menu/menu";
import Grid from "src/layouts/grid/grid";

import iconCheckCircle from "src/assets/images/icon-check-circle.svg";
import { useEffect, useState } from "react";
import FormInput from "src/components/form/input";
import Button from "src/components/ui/button";

const Identities = (props) => {
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [models, setModels] = useState([]);

    useEffect(() => {
        let storedUser = localStorage.getItem("AVATARZ_user");
        let currentUser = storedUser && storedUser !== "undefined" ? JSON.parse(storedUser) : null;
        if (currentUser && currentUser.id) {
            setUser(currentUser);
        }
    }, []);

    return (
        <div className="container-fluid flex flex-row p-0">
            <Menu />
            <Main>
                <Header />

                <div className="page-content">
                    <div className="flex align-items-center">
                        <div className="flex-1">
                            <h2>Our Character Identities</h2>
                            <div className="opacity-25">Top companions. Ready to start chatting with you.</div>
                        </div>
                    </div>

                    <div className="">{/* <img src={iconFull} className="link" /> */}</div>

                    <Grid></Grid>
                </div>
            </Main>
        </div>
    );
};

export default Identities;
