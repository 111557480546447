import { useState } from "react";

const ChatsDefaults = (props) => {
    const [defaults, setDefaults] = useState([
        {
            label: "Ask a question ...",
            text: "What is your favorite book?",
        },
        {
            label: "Start a conversation ...",
            text: "What are your hobbies?",
        },
    ]);

    const setMsg = (msg) => {
        if(props.OnSelect){
            props.OnSelect(msg)
        }
    };

    return (
        <div className="w-full flex">
            <div className="flex flex-row gap-6 flex-1">
                {defaults.map((def, d) => (
                    <div
                        key={d}
                        className="transition-all flex flex-1 flex-col justify-between p-5 border border-gray-200 rounded-3xl gap-10  cursor-pointer hover:border-gray-400"
                        onClick={async () => {
                            // const msg = {
                            //     text: def.text,
                            //     from: "You",
                            //     type: "sent",
                            // };
                            setMsg(def);
                        }}
                    >
                        <div className="opacity-40 text-xs">{def.label}</div>
                        <div className="text-base">{def.text}</div>
                    </div>
                ))}

            </div>
        </div>
    );
};

export default ChatsDefaults;
