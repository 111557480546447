import Button from 'src/components/ui/button'
import './sidebar.css'


const Sidebar = (props) => {
    return ( 
        <div className="sidebar">
            <div className='mb-3'>
                <Button >Book a personalized video </Button>
            </div>

            <div className='mb-3'>
                <Button buttonStyle={`trans`} >Book a business video </Button>
            </div>

            <div className='mb-3'>
                <Button buttonStyle={`trans`} >Book a live call </Button>
            </div>
            

            



            <p className=' mt-5'>Money back guarantee</p>
            <p className='opacity-25 mt-2 font-14'>
                If you pay on web by card, we reserve the amount when you place your order but only charge once you have received the video.
            </p>

            <p className='opacity-25 mt-2 font-14'>
                If you book a video on web with another payment method, we will always provide a full refund if the celebrity doesn't respond.
            </p>


          
        </div>
     );
}
 
export default Sidebar;