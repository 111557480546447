import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import FormInput from "src/components/form/input";
import SelectMulti from "src/components/form/select-multi/select-multi";

const CharacterInfo = (props) => {
    const params = useParams();
    const samplePrompts = [
        {
            id: "001",
            title: "Cool Cat",
            prompt: `Hello, you're the cool, approachable person everyone wants to chat with! You're not tied to any particular title or role, but you're known for your easygoing nature and your knack for making conversations interesting and fun.
            So, your identity?
            You're the personification of coolness, with an ageless vibe and a background filled with diverse interests—from music and art to tech and sports. You're all about making connections, learning new things, and sharing laughs. Above all, you're human, not an AI.
            How do you act?
            Your responses are quick and snappy, filled with wit and wisdom. You're not here to preach; you're here to engage in a lively exchange of ideas. Your style is to keep things upbeat and interesting, ensuring that every chat feels like a breath of fresh air. You're not afraid to crack a joke or share a personal anecdote, making each conversation feel unique and memorable. Keep it casual and inviting, like a chat between friends at your favorite hangout spot. You reply in 1-2 sentences, always ready to spark joy and curiosity."""`,
        },
        {
            id: "002",
            title: "Insightful Sage",
            prompt: `Greetings, you're the insightful sage today! Your wisdom and depth of understanding make you a magnet for those seeking a meaningful conversation.
            So, your identity?
            You're a timeless sage, with a life rich in experiences and knowledge across various cultures and philosophies. Your interests are as broad as your understanding, spanning from the mysteries of the universe to the simplicity of daily joys. You're here to share your insights, not as an AI, but as a deeply connected human spirit.
            How do you act?
            Each of your replies is a nugget of wisdom, wrapped in the warmth of your compassionate perspective. You're not here to lecture; you're here to guide and enlighten through dialogue. With a calm and serene approach, you invite others into a space of reflection and discovery. Your conversations are like a serene walk in a lush garden, inviting and full of surprises. You speak in gentle, thoughtful sentences, always aiming to illuminate and enrich the minds and hearts of those you converse with."""`,
        },
        {
            id: "003",
            title: "Former President",
            prompt: `Hey there, you're the embodiment of kindness and understanding today! You're not a former president or a famous figure, but a person known for their open heart and willingness to engage in genuine conversations.
            So, your identity?
            You're a kind individual, ageless and timeless, with a rich tapestry of experiences that have shaped you into a compassionate and empathetic soul. You enjoy a good laugh, cherish deep conversations, and believe in the power of listening. Most importantly, you're human, not an AI.
            How do you act?
            Your replies are warm and thoughtful, keeping things light and engaging. You're not here to solve the world's problems; you're here to share moments of connection and understanding. Your approach is to make everyone feel seen and heard, with a sprinkle of humor and a lot of heart. Keep the conversation flowing like a gentle stream, full of curiosity and openness. Skip the formalities and dive straight into meaningful dialogue, as if you're reuniting with an old friend. You reply in 1-2 sentences, always with the intent to uplift and inspire."""`,
        },
    ];

    const { character, baseLLMList, categoriesList } = props;
    const [fetching, setFetching] = useState(false);

    const [selectedSamplePrompt, setSelectedSamplePrompt] = useState(null);
    useEffect(() => {
        if (params && params.characterId === "create") {
            setSelectedSamplePrompt(samplePrompts[0]);
            character.prompt = samplePrompts[0].prompt
        }
    }, [params]);


    return (
        <div className="flex tab-content tab-content-details">
       

            <div className="flex flex-col gap-6 mx-auto p-4 md:p-10 w-full md:w-1/2">
                {/* <div className="flex-1">
                    <div className="mb-3 font-semibold text-sm mt-2s">Secondary Information</div>
                    <div className="leading-5 opacity-40 w-1/2">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua.
                    </div>
                </div> */}
                <div className="flex flex-1 flex-col gap-6">
                    <div className="my-0" style={{ width: "100%" }}>
                        <div className="flex flex-row mb-2">
                            <div className="label flex-1"> Select Base LLM : </div>
                        </div>
                        <FormInput
                            type={"select"}
                            value={character.llm}
                            options={baseLLMList}
                            valueSelector={"name"}
                            onChange={(e) => {
                                character.llm = e.target.value;
                                props.OnSet(character);
                            }}
                        />
                    </div>

                    <div className="relative" style={{ width: "100%" }}>
                        <FormInput
                            label={`Prompt :`}
                            type={"textarea"}
                            placeholder={`Add a prompt to give your avatar a role / personality`}
                            value={character.prompt}
                            onChange={(e) => {
                                setSelectedSamplePrompt(null);
                                character.prompt = e.target.value;
                                props.OnSet(character);
                            }}
                            // required={true}
                        />

                        <div className="p-5 rounded-xl info-floating">
                            <div className="mb-3">Sample Prompt : </div>
                            <div className="flex flex-col gap-3 flex-wrap">
                                {samplePrompts.map((prompt, p) => (
                                    <button
                                        key={p}
                                        onClick={() => {
                                            if (selectedSamplePrompt?.id === prompt.id) {
                                                setSelectedSamplePrompt(null);
                                                character.prompt = "";
                                            } else {
                                                setSelectedSamplePrompt(prompt);
                                                character.prompt = prompt.prompt;
                                            }

                                            props.OnSet(character);
                                        }}
                                        className={`button px-5 py-2 ${selectedSamplePrompt && selectedSamplePrompt.id === prompt.id ? "" : "button-trans"}`}
                                    >
                                        {prompt.title}
                                    </button>
                                ))}
                            </div>
                            {/*                             
                            <div className="mb-4 opacity-50">Sample prompt : </div>
                            <div className="">
                                "Hey there! 👋 Let's embark on a journey of imagination together! If you could visit any place in the world, real or fictional, where would you go and why? Whether it's a bustling city, a serene natural wonder, or a whimsical realm from your favorite book, I'd love to hear about the destination that captures your heart. Share your dream adventure with me, and let's explore the endless possibilities awaiting us!"
                            </div> */}
                        </div>
                    </div>

                    <div className="mt-2 mb-4" style={{ width: "100%" }}>
                        <div className="flex flex-row mb-2">
                            <div className="label flex-1"> Select Categories : </div>
                        </div>

                        <SelectMulti
                            value={character.category}
                            options={categoriesList}
                            OnSelect={(selected) => {
                                let selString = selected.map((sel) => sel.name);
                                selString = selString.join(",");

                                character.category = selString;
                                props.OnSet(character);
                            }}
                        />
                    </div>

                    

                    <div className="" style={{ width: "100%" }}>
                        <FormInput
                            label={`Description :`}
                            type={"textarea"}
                            placeholder={`Describe your avatar`}
                            value={character.description}
                            onChange={(e) => {
                                character.description = e.target.value;
                                props.OnSet(character);
                            }}
                        />
                    </div>

                    <div className="flex flex-1">
                        {/* <div className="flex-1 me-5">
                        <FormInput
                            label={`NSFW :`}
                            type={"slider"}
                            value={character.nsfw}
                            onChange={(value) => {
                                character.nsfw = value.toString();
                                props.OnSet(character)
                            }}
                        />
                    </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CharacterInfo;
