import Header from "src/layouts/header/header";
import Main from "src/layouts/main/main";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "./character.css";

import Button from "src/components/ui/button";

import iconCheckCircle from "src/assets/images/icon-check-circle.svg";

import { useEffect, useState, useRef } from "react";
import FormInput from "src/components/form/input";
import iconBot from "src/assets/images/icon-bot.svg";
import { createModel, getModels, deleteModel, updateModel, getModel } from "src/apis/aimodels";
import { listAiStyle } from "src/apis/aistyle";
import Popup from "src/layouts/popup/popup";
import ModalCharacterNerf from "./modal-nerf";
import ModalCharacterVoice from "./modal-voice";
import ModalCharacterModel from "./modal-model";
import ModalCharacterFast from "./modal-fast";
import Preloader from "src/components/preloader/preloader";
import { getStoredUser } from "src/components/auth/auth";
import CharacterKnowledge from "./tab-knowledge";
import { addToQueue, captureKeyframes } from "src/apis/utils";
import { upload } from "src/apis/utils";
import CharacterModel from "./tab-model";
import Iicon from "src/components/iicon/iicon";
import CharacterAppearance from "./tab-appearance";
import CharacterInfo from "./tab-info";
import CharacterScenario from "./tab-scenario";
import CharacterSummary from "./tab-summary";

import { getQueue } from "src/apis/utils";
import CharacterAiCreate from "./tab-aicreate";
import CharacterUploadVid from "./tab-upload-vid";
import { keyframes } from "@emotion/react";
import { createSession } from "src/apis/aichats";

const CreateCharacter = (props) => {
    const location = useLocation();
    const params = useParams();

    const navigate = useNavigate();
    let intervalSilence = useRef();

    const creatorId = "51fc3e0d-caac-4eb0-a516-90c8dd84a4e4";

    const mainTabs = [
        {
            id: 1,
            name: "Generate",
        },
        {
            id: 2,
            name: "Configure",
        },
    ];
    const steps = [
        {
            id: 1,
            name: "Upload",
            required: true,
        },
        {
            id: 2,
            name: "Chat",
            required: true,
        },
    ];

    const [tabs, setTabs] = useState([
        {
            id: 1,
            name: "Model",
            required: true,
        },
        {
            id: 2,
            name: "Information",
        },
        {
            id: 3,
            name: "Scenario",
        },
        {
            id: 4,
            name: "Summary",
        },
    ]);

    const [showCreateModel, setShowCreateModel] = useState(false);
    const [showCreateNerf, setShowCreateNerf] = useState(false);
    const [showCreateVoice, setShowCreateVoice] = useState(false);
    const [showCreateFast, setShowCreateFast] = useState(false);

    const [baseLLMList, setBaseLLMList] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);

    const [fetching, setFetching] = useState(false);

    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [selectedMainTab, setSelectedMainTab] = useState(mainTabs[1]);
    const [selectedStep, setSelectedStep] = useState(steps[0]);

    const [nameIsUnique, setNameIsUnique] = useState(null);
    const [fetchingResponse, setFetchingResponse] = useState(false);
    const [fineTune, setFineTune] = useState(false);

    let characterDefault = {
        id: null,
        userid: getStoredUser()?.id,

        category: "",
        description: "",
        exampleconvo: "",
        firstmsg: "",
        name: "",
        nsfw: "false",
        personality: "",
        private: "true",
        prompt: "",
        scenario: "",
        tags: "",
        llm: "gpt-4",

        fast0: "",
        fast1: "",
        modelpath: "",
        silence: "",

        image: "",
        meta: "",
        session: "",
        audioonly: "false",
    };

    let characterRef = useRef();
    const [character, setCharacter] = useState(characterDefault);

    useEffect(() => {
        characterRef.current = character;
    }, [character]);

    useEffect(() => {
        return () => {
            setBaseLLMList([]);
            setCategoriesList([]);
            if (intervalSilence.current) {
                clearInterval(intervalSilence.current);
            }
        };
    }, []);

    // useEffect(() => {
    //     if (params && params.characterId && params.characterId !== "create") {
    //         setTabs([
    //             {
    //                 id: 1,
    //                 name: "Model",
    //                 required: true,
    //             },
    //             {
    //                 id: 2,
    //                 name: "Information",
    //             },
    //             {
    //                 id: 3,
    //                 name: "Scenario",
    //             },
    //             {
    //                 id: 4,
    //                 name: "Knowledge",
    //             },
    //             {
    //                 id: 5,
    //                 name: "Photos",
    //             },
    //             {
    //                 id: 6,
    //                 name: "Summary",
    //             },
    //         ]);
    //     }
    // }, [params]);

    useEffect(() => {
        //pollSilence();
        if (selectedMainTab.name === "Generate") {
            if (character.fast1.indexOf("https") !== -1 && character.fast1.indexOf("blob") === -1) {
                setSelectedStep(steps[1]);
            }
        }
    }, [character, selectedMainTab]);

    useEffect(() => {
        setCharacter(characterDefault);
        setSelectedTab(tabs[0]);
    }, [location]);

    useEffect(() => {
        if (location.state && location.state.model) {
            console.log(location.state.model);
            setCharacter({ ...location.state.model });
        }

        if (location.state && location.state.mainTab) {
            const tabIndex = mainTabs.findIndex((obj) => obj.name === location.state.mainTab);

            //console.log(location.state.mainTab, tabIndex);
            if (tabIndex !== -1) {
                setSelectedMainTab(mainTabs[tabIndex]);
            }
        }
    }, [location.state]);

    useEffect(() => {
        if (params && params.characterId && !character.id) {
            handleCharacter({ opr: "edit", charId: params.characterId });
        }
        populateLists();
    }, [params]);

    const canSubmit = () => {
        if (character.name !== "" && character.fast0 !== "") {
            return true;
            // } else if (selectedTab.name === "Information" && character.prompt !== "") {
            //     return true;
            // } else if (
            //     selectedTab.name !== "Information" &&
            //     selectedTab.name !== "Model" &&
            //     character.name !== "" &&
            //     character.fast0 !== "" &&
            //     character.prompt !== ""
            // ) {
            //     return true;
        } else {
            return false;
        }
    };

    const populateLists = async () => {
        setFetching(true);
        // let modelsList = await listAiChats();
        // setCharacterModelList(modelsList);
        // console.log(modelsList);

        let catList = await listAiStyle("category");
        setCategoriesList(catList);

        let llmList = await listAiStyle("llm");
        setBaseLLMList(llmList);

        // let nerfList = await listAiStyle("nerf");
        // setNerfModelList(nerfList);

        // let fastList = await listAiStyle("fast");
        // setFastModelList(fastList);

        // let voiceList = await listAiStyle("voice");
        // setNerfVoiceList(voiceList);
        setFetching(false);
    };

    const naviTab = (dir) => {
        switch (dir) {
            case "next":
                if (selectedTab.id < tabs.length) {
                    let nextTab = tabs.find((tab) => tab.id === selectedTab.id + 1);
                    if (!nextTab) return;
                    setSelectedTab(nextTab);
                } else if (selectedTab.id === tabs.length) {
                    if (intervalSilence.current) {
                        clearInterval(intervalSilence.current);
                    }
                    navigate(`/chat/${character.id}`, { state: { from: "create" } });
                }
                break;
            case "prev":
                if (selectedTab.id > 1) {
                    const prevTab = tabs.find((tab) => tab.id === selectedTab.id - 1);
                    if (!prevTab) return;
                    setSelectedTab(prevTab);
                }

                break;
            default:
                break;
        }
    };

    const handleCharacter = async ({ opr, charId, theCharacter }) => {
        setFetching(true);
        const processSave = async (char, norender) => {
            //CHECK IF IMAGE IS STILL A FILE
            if (char.image.name) {
                console.log("uploading image avatar :", char.image);
                let imageRes = await upload({ file: char.image, directory: char.id });
                char.image = imageRes?.data[0]?.cache;
            }

            // UPLOAD VIDEO WITH CORRECT MODEL ID
            if (char.fast0.name) {
                console.log("uploading video for model :", char.fast0);
                // upload fast0 after creating model
                let uploadFast0Res = await upload({ file: char.fast0, directory: char.id });

                console.log("upload res : ", uploadFast0Res);
                if (uploadFast0Res && uploadFast0Res.data[0]) {
                    char.fast1 = uploadFast0Res.data[0].cache;
                    char.fast0 = uploadFast0Res.data[0].s3;

                    let modelToQue = {
                        userid: char.userid,
                        name: char.name,
                        modelid: char.id,
                        src: char.fast0,
                    };
                    console.log("adding to queue:", modelToQue);

                    //CAPTURE KEYFRAMES
                    const captureFrames = async () => {
                        let keyFramesInput = {
                            url: uploadFast0Res.data[0].cache,
                            frames: "1",
                        };
                        console.log("keyFramesInput:", keyFramesInput);
                        const keyFrameRes = await captureKeyframes(keyFramesInput);
                        console.log("keyFrameRes :", keyFrameRes);
                        if (keyFrameRes && keyFrameRes.key) {
                            let imgurl = `https://svc.staging.squadz.live/download/${keyFrameRes.key}`;
                            char.image = imgurl;
                            return imgurl;
                        }
                    };

                    if (fineTune === true) {
                        const queMouth = async () => {
                            const input = {
                                userid: getStoredUser()?.id,
                                modelid: char.id,
                                src: uploadFast0Res.data[0].s3,
                            };

                            const mouthQueue = await addToQueue({ key: "mouth", value: JSON.stringify(input) });
                            console.log("mouth : added to queue : ", mouthQueue);
                            char.mouth = `generating_mouth_${mouthQueue?.id}`;
                            return mouthQueue;
                        };
                        const allPromise = Promise.all([captureFrames(), queMouth()]);
                        try {
                            const values = await allPromise;
                            console.log(values); // [resolvedValue1, resolvedValue2]
                        } catch (error) {
                            console.log(error); // rejectReason of any first rejected promise
                        }
                    } else {
                        // GENERATE SILENCE
                        const queSilence = async () => {
                            //const resSilentQue = "test2";
                            let resSilentQue = await addToQueue({ key: "silence", value: JSON.stringify(modelToQue) });
                            console.log("silence : added to queue : ", resSilentQue);
                            char.silence = `generating_silence_${resSilentQue?.id}`;
                            return resSilentQue;
                            // pollSilence();
                        };

                        //MODELGEN
                        const queGen = async () => {
                            //const resQue = "test3";
                            let resQue = await addToQueue({ key: "video", value: JSON.stringify(modelToQue) });
                            console.log("model gen : added to queue : ", resQue);
                            //const initModelRes = await intiModelGenerator({ name: charinput.name, modelid: createRes.id, src: createRes.fast0 });
                            //console.log("create initModelRes :", initModelRes);
                            char.modelpath = `generating_model_${resQue?.id}`;
                            return resQue;
                        };

                        const allPromise = Promise.all([captureFrames(), queSilence(), queGen()]);

                        try {
                            const values = await allPromise;
                            console.log(values); // [resolvedValue1, resolvedValue2]
                        } catch (error) {
                            console.log(error); // rejectReason of any first rejected promise
                        }
                    }
                }
            }
            // await new Promise((resolve) => setTimeout(resolve, 10000));

            if (!norender) {
                characterRef.current.image = char.image;
                characterRef.current.modelpath = char.modelpath;
                characterRef.current.silence = char.silence;
                characterRef.current.fast0 = char.fast0;
                characterRef.current.fast1 = char.fast1;
                setCharacter({ ...characterRef.current });
            }

            const charInput = { ...char };
            delete charInput.id;
            delete charInput.createdate;

            let updateCharRes = await updateModel(char.id, charInput);
            return updateCharRes;
        };

        let isUnique = false;
        if (character && character.name) {
            let checkNameRes = await getModel({ name: character.name });
            if (checkNameRes && checkNameRes.id && checkNameRes.id !== character.id) {
                isUnique = false;
                setNameIsUnique(false);
            } else {
                isUnique = true;
                setNameIsUnique(true);
            }
            setFetching(false);
        }

        switch (opr) {
            case "save":
                console.log(character);
                if (canSubmit() && isUnique) {
                    setFetching(true);
                    //if (character.name !== "") {

                    let charinput = { ...character };
                    delete charinput.id; //delete the null id

                    if (character.fast0.name) {
                        // remove fast is file coz will upload later
                        charinput.fast0 = "";
                        charinput.fast1 = "";
                    }

                    if (character.image.name) {
                        charinput.image = "";
                    }

                    naviTab("next");

                    let createRes = await createModel(charinput);
                    // console.log("<-- created model:", createRes);

                    character.id = createRes.id; // ADD CREATED MODEL ID
                    setCharacter({ ...character });
                    let recreateRes = await processSave(character);

                    setFetching(false);
                    if (recreateRes && recreateRes.id) {
                    }
                }
                break;

            case "save-partial":
                console.log(character);
                if (character.fast1 !== "") {
                    //if (character.name !== "") {
                    setFetching(true);

                    let charinput = { ...character };
                    delete charinput.id; //delete the null id

                    if (character.fast0.name) {
                        // remove fast is file coz will upload later
                        charinput.fast0 = "";
                        charinput.fast1 = "";
                    }

                    if (character.image.name) {
                        charinput.image = "";
                    }

                    const createSessRes = await createSession({ modelid: creatorId });
                    if (createSessRes && createSessRes.id) {
                        charinput.session = createSessRes.id;
                    }

                    let createRes = await createModel(charinput);
                    // console.log("<-- created-partial model:", createRes);

                    character.id = createRes.id; // ADD CREATED MODEL ID
                    character.session = charinput.session;
                    setCharacter({ ...character });
                    setSelectedStep(steps[1]);

                    // await processSave(character, "norender");
                    await processSave(character);

                    setFetching(false);
                }
                break;

            case "update":
                if (canSubmit() && isUnique) {
                    setFetching(true);

                    naviTab("next");
                    // console.log("--> to update : ", character);
                    let updateRes = await processSave(character);

                    setCharacter({ ...character });
                    //console.log("<-- updated model:", updateRes);
                    setFetching(false);
                    if (updateRes && updateRes.id) {
                    }
                }
                break;

            case "update-partial":
                setFetching(true);

                const thechar = await getModel({ id: character.id });

                thechar.image = theCharacter.image;
                thechar.fast0 = theCharacter.fast0;
                thechar.fast1 = theCharacter.fast1;
                thechar.private = theCharacter.private;

                //console.log("--> to update-partial : ", thechar);
                let updateRes = await processSave(thechar);

                setCharacter({ ...character });
                // console.log("<-- updated-partial model:", updateRes);

                navigate(`/chat/${character.id}`);

                setFetching(false);

                break;

            case "edit":
                setFetching(true);
                let getRes = await getModel({ id: charId });
                // console.log("<-- getRes model:", getRes);
                setFetching(false);
                if (getRes && getRes.id) {
                    // currfast0.current = getRes[0].fast0;
                    setCharacter(getRes);
                }
                break;

            case "delete":
                setFetching(true);
                let delRes = await deleteModel({ id: charId });
                setFetching(false);
                if (delRes) {
                    navigate(-1);
                }
                break;

            default:
                break;
        }
    };

    return (
        <>
            {showCreateModel && (
                <ModalCharacterModel
                    OnHide={() => {
                        setShowCreateModel(false);
                        populateLists();
                    }}
                />
            )}

            {showCreateNerf && (
                <ModalCharacterNerf
                    OnHide={() => {
                        setShowCreateNerf(false);
                        populateLists();
                    }}
                />
            )}

            {showCreateFast && (
                <ModalCharacterFast
                    OnHide={() => {
                        setShowCreateFast(false);
                        populateLists();
                    }}
                />
            )}

            {showCreateVoice && (
                <ModalCharacterVoice
                    OnHide={() => {
                        setShowCreateVoice(false);
                        populateLists();
                    }}
                />
            )}

            <Main noHeader={true}>
                <div className="box flex flex-1 flex-col p-3 md:p-6">
                    <div className="flex flex-row items-center relative">
                        {fetching && <Preloader type={"full-relative"} text={"none"} />}

                        <div
                            className="navi navi-back mr-2"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <i className="iicon iicon-nav-back"></i>
                        </div>

                        <div className="flex-1 flex flex-row items-center gap-2">
                            <div className="flex-1 flex flex-row items-center justify-between py-3">
                                <div className="flex-1 text-base font-semibold">
                                    {params?.characterId === "create" ? (
                                        <>Create your avatar</>
                                    ) : (
                                        <span
                                            className="pointer"
                                            onClick={() => {
                                                navigate(`/chat/${character.id}`);
                                            }}
                                        >
                                            {character.name?.length > 20 ? `${character.name.substring(0, 20)} ...` : character.name}
                                        </span>
                                    )}
                                </div>

                                {params && params.characterId !== "create" && (
                                    <div className="flex-0 ">
                                        <button
                                            onClick={() => {
                                                handleCharacter({ opr: "delete", charId: params.characterId });
                                            }}
                                            className={"button button-trans flex pr-4 flex-row gap-3 items-center"}
                                        >
                                            <div className="hidden md:flex">Delete Avatar </div>
                                            <div>
                                                <Iicon icon={`trash`} />
                                            </div>
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-row gap-2 md:gap-4">
                                {selectedMainTab.name === "Configure" && (
                                    <button
                                        className="button hidden md:flex"
                                        disabled={!canSubmit()}
                                        // disabled={character.name !== "" ? false : true}
                                        onClick={() => {
                                            if (!character.id) {
                                                handleCharacter({ opr: "save" });
                                            } else {
                                                handleCharacter({ opr: "update" });
                                            }
                                        }}
                                    >
                                        {selectedTab.id === tabs.length ? (
                                            <>Finish</>
                                        ) : (
                                            <>
                                                Next <Iicon icon="nav-right-white" className={"translate-x-2 md:translate-x-3"} />
                                            </>
                                        )}
                                    </button>
                                )}

                                {selectedMainTab.name === "Generate" && (
                                    <>
                                        {selectedStep.id === 1 && (
                                            <button
                                                className="button"
                                                disabled={character.fast1 === ""}
                                                onClick={async () => {
                                                    if (!character.id) {
                                                        await handleCharacter({ opr: "save-partial" });
                                                    } else {
                                                        setSelectedStep(steps[1]);
                                                    }
                                                }}
                                            >
                                                Next <Iicon icon="nav-right-white" className={"translate-x-3"} />
                                            </button>
                                        )}
                                        {selectedStep.id === 2 && (
                                            <button
                                                className="button ml-2"
                                                disabled={fetchingResponse}
                                                onClick={async () => {
                                                    if (character.id) {
                                                        await handleCharacter({ opr: "update-partial", theCharacter: character });
                                                    }
                                                }}
                                            >
                                                Finish
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row gap-10 flex-1">
                        {selectedMainTab.name === "Generate" && (
                            <div className="tab-content tab-content-uploadvid slideFromBot">
                                {selectedStep.id === 1 && (
                                    <>
                                        {fetching && <Preloader type={"full-relative"} text={"none"} />}
                                        <CharacterUploadVid
                                            character={character}
                                            nameIsUnique={nameIsUnique}
                                            OnSet={(character) => {
                                                setCharacter({ ...character });
                                                setNameIsUnique(null);
                                            }}
                                        />
                                    </>
                                )}
                                {selectedStep.id === 2 && (
                                    <CharacterAiCreate
                                        character={character}
                                        OnCheckChanges={(character) => {
                                            // setCharacter({ ...character });
                                            handleCharacter({ opr: "edit", charId: character.id });
                                        }}
                                        OnCreateSession={(character) => {
                                            console.log("OnCreateSession", character);
                                            handleCharacter({ opr: "update-partial", theCharacter: character });
                                        }}
                                        // OnResetSession={(character) => {
                                        //     setCharacter({ ...character });
                                        //     handleCharacter({ opr: "update-partial" });
                                        // }}
                                        OnFetchingResponse={(state) => {
                                            setFetchingResponse(state);
                                        }}
                                        OnSet={(character) => {
                                            setCharacter({ ...character });
                                            setNameIsUnique(null);
                                        }}
                                    />
                                )}
                            </div>
                        )}
                        {selectedMainTab.name === "Configure" && (
                            <div className="character-form slideFromBot">
                                <div className="tabs flex-row">
                                    {selectedTab && selectedTab.id > 1 && (
                                        <div
                                            className="tab-item flex-0 md:hidden"
                                            onClick={() => {
                                                naviTab("prev");
                                            }}
                                        >
                                            <Iicon icon={`nav-left`} />
                                        </div>
                                    )}
                                    {tabs.map((tab, t) => (
                                        <div
                                            key={t}
                                            className={`tab-item ` + (tab.name === selectedTab.name ? "tab-item-active flex-1" : "hidden md:flex")}
                                            onClick={() => {
                                                console.log(character.id);
                                                if (character.id) {
                                                    setSelectedTab(tab);
                                                }
                                            }}
                                        >
                                            {tab.name} {tab.required && <span className="red ml-1">*</span>}
                                        </div>
                                    ))}
                                    <div
                                        className="tab-item flex-0 md:hidden"
                                        onClick={() => {
                                            if(canSubmit() && !fetching){
                                                if (!character.id) {
                                                    handleCharacter({ opr: "save" });
                                                } else {
                                                    handleCharacter({ opr: "update" });
                                                }
                                            }
                                        }}
                                    >
                                        {selectedTab && selectedTab.id >=  tabs.length ? 
                                            <div>Finish</div>
                                        :
                                            <>Next <Iicon icon={`nav-right`} /></>
}
                                    </div>
                                </div>
                                {/* ------------------------------------------- TAB 1  -------------------------------------------*/}
                                {selectedTab.name === "Model" && (
                                    <CharacterModel
                                        character={character}
                                        nameIsUnique={nameIsUnique}
                                        fineTune={fineTune}
                                        // OnSelect={(file) => {
                                        //     filefast0.current = file;
                                        //     character.fast0 = file;
                                        //     character.fast1 = URL.createObjectURL(file);
                                        //     setCharacter({ ...character });
                                        // }}
                                        OnSet={(character) => {
                                            setCharacter({ ...character });
                                            setNameIsUnique(null);
                                        }}
                                        OnFineTune={(val) => {
                                            setFineTune(val);
                                        }}
                                    />
                                )}
                                {/* ------------------------------------------- TAB 2  -------------------------------------------*/}
                                {selectedTab.name === "Information" && (
                                    <CharacterInfo
                                        character={character}
                                        baseLLMList={baseLLMList}
                                        categoriesList={categoriesList}
                                        OnSet={(character) => {
                                            setCharacter({ ...character });
                                        }}
                                    />
                                )}

                                {/* ------------------------------------------- TAB 4  -------------------------------------------*/}

                                {selectedTab.name === "Scenario" && (
                                    <CharacterScenario
                                        character={character}
                                        OnSet={(character) => {
                                            setCharacter({ ...character });
                                        }}
                                    />
                                )}

                                {selectedTab.name === "Knowledge" && character && character.id && <CharacterKnowledge model={character} />}
                                {selectedTab.name === "Photos" && character && character.id && <CharacterAppearance model={character} />}
                                {selectedTab.name === "Summary" && character && character.id && (
                                    <div className="flex tab-content tab-content-details">
                                        <div className="flex flex-col gap-6 mx-auto p-0 md:p-10 w-full md:w-2/3">
                                            <CharacterSummary
                                                character={character}
                                                OnSet={(character) => {
                                                    setCharacter({ ...character });
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </Main>
        </>
    );
};

export default CreateCharacter;
