import axios from "axios";
import endpoint from "./endpoints";
import { getStoredUser } from "src/components/auth/auth";
import { handleResponse } from "./response";

///////////////////////////QUERIES

export const sendChat = async ({ sessionid, llm, msg, image }) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "chat",
                    pagesize: "100",
                    pagenumber: "1",
                    llm: llm,
                },
                Values: {
                    id: sessionid,
                    prompt: msg,
                },
            };

            if (image) {
                query.Values.image = image;
            }

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const sendCreatorChat = async ({ sessionid, modelid, llm, msg, image }) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "chatcharactercreation",
                    pagesize: "100",
                    pagenumber: "1",
                    llm: llm,
                },
                Values: {
                    
                    id: sessionid,
                    modelid,
                    prompt: msg,
                },
            };

            if (image) {
                query.Values.image = image;
            }

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const getVaiChats = async (input) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "vaichats",
                    act: "select",
                    pagesize: "20",
                    pagenumber: "1",
                },
                Where: input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const getLastSession = async (input) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "getaichats",
                    pagesize: "1",
                    pagenumber: "1",
                    orderby: "createdate desc",
                },
                Where: {
                    userid: getStoredUser().id,
                    modelid: input.modelid,
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const getAiChatByModelid = async (modelid) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "getaichats",
                    pagesize: "20",
                    pagenumber: "1",
                    orderby: "createdate desc",
                },
                Where: {
                    userid: getStoredUser().id,
                    modelid: modelid,
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const getAiChat = async (input) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "getaichats",
                    pagesize: "1",
                    pagenumber: "1",
                },
                Where: input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const listAiChats = async () => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "getaichats",
                    pagesize: "30",
                    pagenumber: "1",
                },
                // Where: {
                //     name:
                // },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

///////////////////////////MUTATIONS

export const updateNameToSession = async (input) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "setaichats",
                    pagesize: "1",
                    pagenumber: "1",
                },
                Where: {
                    id: input.id,
                },
                Values: {
                    name: input.name,
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const createSession = async ({modelid,audioonly}) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "addaichats",
                    pagesize: "1",
                },
                Values: {
                    userid: getStoredUser().id,
                    modelid: modelid,
                    isbatch: "true", // FOR ZEUS
                    //isbatch: "false"// FOR DIMA
                },
            };
      
            if(audioonly){
                query.Values.audioonly = "true"
            }

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const deleteSession = async (id) => {
    let query = {
        Params: {
            userid: getStoredUser().id,
            auth: getStoredUser().auth,
            sys: "ai",
            act: "deleteaichats",
            pagesize: "1",
        },
        Where: {
            id: id,
        },
    };
    if (getStoredUser() && getStoredUser().id) {
        try {
            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const createModel = async (input) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let createRes = await axios({
                method: "POST",
                url: `${endpoint.chat}/create-bot`,
                data: JSON.stringify({ Values: input }),
                headers: {
                    "Content-Type": "application/json",
                },
            });

            return createRes?.data;
        } catch (error) {
            console.log("error :", error);
        }
    }

    // let query = {
    //     Params: {
    //         userid: getStoredUser().userid,
    //         auth: getStoredUser().auth,
    //         sys: "aichats",
    //         act: "insert",
    //         pagesize: "1",
    //         pagenumber: "1",
    //     },
    //     Values: input,
    // };
    // if (getStoredUser() && getStoredUser().id) {
    //try {
    //     const res = await axios.post(endpoint.rest, query);
    //     return res?.data;
    // } catch (error) {
    //     console.log("error :", error);
    // }
};
