import { useEffect, useState } from "react";

import Card from "src/components/card/card";
import Filter from "src/components/filter/filter";

import Grid from "src/layouts/grid/grid";
import Header from "src/layouts/header/header";
import Main from "src/layouts/main/main";
import Footer from "src/layouts/footer";
import Menu from "src/components/menu/menu";
import { getModels, searchModels } from "src/apis/aimodels";
import Button from "src/components/ui/button";

import iconFull from "src/assets/images/icon-full.svg";
import iconGrid from "src/assets/images/icon-grid.svg";

//import { models } from "src/data/dummy-nerf-models";
import { listAiStyle } from "src/apis/aistyle";

const TTSHome = (props) => {
    const [models, setModels] = useState([]);

    useEffect(() => {
        handleModels({ opr: "list" });
    }, []);

    const handleModels = async ({ opr }) => {
        switch (opr) {
            case "list":
                let listRes = await listAiStyle("nerf");
                setModels(listRes);

                break;

            default:
                break;
        }
    };

    return (
        <div className="container-fluid flex flex-row p-0">
            <Menu />
            <Main>
                <Header />

                <div className="page-content">
                    <div className="flex align-items-center page-title">
                        <div className="flex-1">
                            <h2>Choose your model</h2>
                            <div className="opacity-25">Text to speech models.</div>
                        </div>

                        <div className="">
                            <div className="">Showing {models?.length} AI models.</div>
                            {/* <img src={iconFull} className="link" /> */}
                        </div>
                    </div>

                    {/* <Filter /> */}
                    <Grid>
                        {models.map((model, m) => (
                            <Card key={m} model={model} navigate={`/create-tts/${model.modelid}`} />
                        ))}
                    </Grid>
                </div>
            </Main>

            {/* <Footer></Footer> */}
        </div>
    );
};

export default TTSHome;
