import { useState, useEffect } from "react";
import Popup from "src/layouts/popup/popup";
import Button from "src/components/ui/button";
import FormInput from "src/components/form/input";
import { insertKnowledge, addToQueue } from "src/apis/utils";
import { isValidImageUrl, isValidUrl } from "src/components/utils";
import { getStoredUser } from "src/components/auth/auth";
import Preloader from "src/components/preloader/preloader";



const ModalCharacterKnowledge = (props) => {
    const [knowledge, setKnowledge] = useState({
        type: "url",
        path: "",
    });
    const [invalid, setInvalid] = useState(true);
    const [selectedTab, setSelectedTab] = useState("url");
    const [fetching, setFetching] = useState(false);

    useEffect(()=>{
        if(props.type === 'appearance'){
            setSelectedTab("upload")
        }
    },[props.type])

    useEffect(() => {
        knowledge.type = selectedTab === "url" ? "url" : "";
        knowledge.path = "";
        setKnowledge({ ...knowledge });
    }, [selectedTab]);

    const handleAddKnowledge = async () => {
        let libInput = {
            modelid: props.model.id,
            bucket: props.type === "appearance" ? "avatarz" : "",
            type: knowledge.type,
            url: knowledge.path,
        };

        let addKnowledgeRes = await insertKnowledge(libInput);
        console.log("res :", addKnowledgeRes);

        let modelToQue = {
            name:props.model.name,
            modelid:props.model.id,
            userid:getStoredUser()?.id
        }

        console.log(modelToQue)
        let resQue = await addToQueue({key:"rag", value: JSON.stringify(modelToQue)});
        console.log('rag que response :', resQue)
        props.OnHide(true);

        // let createRes = await createModel(model);
        // console.log(createRes)
        // if(createRes.indexOf('successfully') !== -1){
        //     props.OnHide(true);
        // }
    };

    return (
        <Popup
            OnHide={() => {
                props.OnHide(true);
            }}
            className={`absolute`}
        >
            <div className="p-10 relative overflow-hidden">
                {fetching && <Preloader type={"full-relative"} />}

                <h3 className="font-semibold">Add {props.type}</h3>
                <div className="opacity-25 mb-4">{props.desc}</div>

                <div className="">
                    {/* {knowledge.path !== "" && <div className="my-5 note modal-previewer">{knowledge.path}</div>} */}

                    {props.type !== 'appearance' &&
                    <div className="tabs tabs-compact">
                        <div
                            className={`tab-item ` + (selectedTab === "url" ? "tab-item-active" : "")}
                            onClick={() => {
                                setSelectedTab("url");
                            }}
                        >
                            URL
                        </div>
                      
                        <div
                            className={`tab-item ` + (selectedTab === "upload" ? "tab-item-active" : "")}
                            onClick={() => {
                                setSelectedTab("upload");
                            }}
                        >
                            Upload a File
                        </div>
                    </div>
}


                    <div className="mb-5">
                        {selectedTab === "url" && (
                            <div className="tab-content p-5">
                                <FormInput
                                    label={`Add a url `}
                                    placeholder={`Add url`}
                                    value={knowledge.path}
                                    onChange={(e) => {
                                        let thevalue = e.target.value;
                                        knowledge.path = thevalue;

                                        console.log(isValidUrl(thevalue));

                                        if (props.type === "appearance") {
                                            setInvalid(!isValidImageUrl(thevalue));
                                        } else {
                                            setInvalid(!isValidUrl(thevalue));
                                        }

                                        setKnowledge({ ...knowledge });
                                    }}
                                    required={true}
                                />
                            </div>
                        )}

                        {selectedTab === "upload" && (
                            <div className="tab-content p-5">
                                <div className="form-item-label">
                                    <div>Upload a file to be used as knowledge source.</div>
                                    <span className="required-icon">*</span>
                                </div>
                                <FormInput
                                    label={`Select file to upload`}
                                    placeholder={`Select file to upload`}
                                    directory={`${getStoredUser()?.id}_knowledge_index`}
                                    value={knowledge.path}
                                    type={"file"}
                                    accept={props.type === "appearance" ? "image/*" : "image/*, video/*, application/pdf, application/msword, .txt"}
                                    className={"form-item-last-child"}
                                    OnUpload={(res) => {
                                        setFetching(true);
                                        let thetype = "";
                                        if (res.type.indexOf("/") !== -1) {
                                            thetype = res.type.split("/")[0];
                                        }
                                        thetype = thetype === "application" ? "doc" : thetype;
                                        knowledge.type = thetype;

                                        setKnowledge({ ...knowledge });
                                    }}
                                    OnSuccess={(res) => {
                                        setFetching(false);
                                        console.log(res);
                                        knowledge.path = `${res.filepaths3}`;
                                        setInvalid(false);
                                        setKnowledge({ ...knowledge });
                                    }}
                                    required={true}
                                />
                            </div>
                        )}
                    </div>

                    <Button
                        disabled={knowledge.path === "" || invalid === true ? true : false}
                        onClick={() => {
                            handleAddKnowledge();
                        }}
                    >
                        Add {props.type}
                    </Button>
                </div>
            </div>
        </Popup>
    );
};

export default ModalCharacterKnowledge;
