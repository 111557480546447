const endpoint = {

    // rest: 'https://squadz.ngrok.app/api',  
    // upload: 'https://squadz.ngrok.app/upload',

    //rest: 'https://luka.ngrok.app/api',
    //upload: 'https://luka.ngrok.app/upload',


    rest: 'https://svc.staging.squadz.live/api/',
    upload: 'https://svc.staging.squadz.live/upload/',
    download: 'https://svc.staging.squadz.live/download/',


    restAws: `https://api.staging.squadz.live`,
    key: `eQL5vbZgFrV918SW3lCOj0astWVlIiZttyiAhz6YWfky9I4yFBvn9lsWgAFYy0uT`,
    // rest: 'https://luka.ngrok.app/api',
    // upload: 'https://luka.ngrok.app/upload',


    // rest: "https://api.staging.squadz.live/rest/api",
    // upload: "https://api.staging.squadz.live/rest/upload",

    //chat: 'https://38d7-188-252-216-128.ngrok-free.app/chat',
     chat: 'https://api.staging.squadz.live/bot/chat'

     
  
}

export default endpoint;