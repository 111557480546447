import Button  from 'src/components/ui/button';
import './subscribe-box.css'

const SubscribeBox = (props) => {
    return ( 
        <div className='px-5 subscribe-box'>
            <h4>Join our mailing list</h4>
            <p className='opacity-25 mb-5'>Be the first to know about the newest stars and<br/>best deals on avatarz</p>
            <div className='subscribe-input'>
                <input type="text" placeholder="Enter your email address..."/>
            </div>
            <div className='mt-2'>
                <Button>Subscribe</Button>
            </div>
            
            <div className='mt-3 opacity-25'>
                Emails subject to privacy policy
            </div>
        </div>

     );
}
 
export default SubscribeBox;