import { useEffect, useState, useRef } from "react";

import { usePubNub } from "pubnub-react";
const Pubnuber = (props) => {
    const pubnub = usePubNub();
    const [channels, setChannels] = useState([]);
    let listenerParams = useRef();

    useEffect(() => {
        setChannels(props.channels);
    }, [props.channels]);

    // useEffect(() => {
    //     if(props.unsubPub && props.unsubPub.state === true && listenerParams.current){
    //         console.log("✗ unsubbing : ", channels);
    //         pubnub.unsubscribeAll();
    //         pubnub.unsubscribe({ channels: channels });
    //         pubnub.removeListener(listenerParams.current);
    //     }

    // }, [props.unsubPub]);

    useEffect(() => {
        if (channels.length > 0) {
            listenerParams.current = {
                message: handlePubnubMessage,
                error: (m) => {
                    //console.log("pubnub error :", m);
                },
                status: (m) => {
                    //console.log("⚠️ pubnub status :", m);
                },
                // presence: (m) =>{console.log("pubnub presence :", m)},
            };

            console.log("✓ subbing : ", channels);
            pubnub.addListener(listenerParams.current);
            pubnub.subscribe({ channels: channels });

            return () => {
                console.log("✗ unsubbing : ", channels);
                pubnub.unsubscribeAll();
                pubnub.unsubscribe({ channels: channels });
                pubnub.removeListener(listenerParams.current);
            };
        }
    }, [pubnub, channels]);

    const handlePubnubMessage = (event) => {
        props.OnMessage(event.message);
    };

    return <></>;
};

export default Pubnuber;
