import React, { useEffect, useState, useRef } from "react";
import { epochToDate } from "../utils";

const VideoVoice = (props) => {
    const [videoQueue, setVideoQueue] = useState([]);
    const videoQueueRef = useRef([]);
    const [curr, setCurr] = useState({ findex: 0, state: null, retried: 0, elemRetried: 0 });
    let vidsRef = useRef([]);

    useEffect(() => {
        if (props.vidChunksQueue.length > 0) {
            const sortedQues = props.vidChunksQueue.sort((a, b) => a.findex - b.findex);
            setVideoQueue([...sortedQues]);
        }
    }, [props.vidChunksQueue]);

    useEffect(() => {
        videoQueueRef.current = videoQueue;
        if (videoQueue.length === 1) {
            setCurr({ findex: videoQueue[0].findex, state: null, retried: 0, elemRetried: 0 });
        }
    }, [videoQueue]);

    useEffect(() => {
       
        if (videoQueue.length >= 1) {
            const vidElem = vidsRef.current.find((vid) => parseInt(vid.dataset.findex) === curr.findex);
            const vidQue = videoQueue.find((viq) => parseInt(viq.findex) === curr.findex);
            // console.log(`vidElem ${curr.findex} :: ${vidElem ? "true" : "false"}, vidQue ${curr.findex} :: ${vidQue ? "true" : "false"}`);
            // console.log(`curr.state  ${curr.findex} :: ${curr.state}`);
            if (vidElem && vidQue) {
                let filename = vidElem.src.split("/")[vidElem.src.split("/").length - 1];
                let findex = parseInt(filename.slice(0, -5));

                if (curr.state !== "ended") {
                   // console.log('1: curr.state not ended')
                    if (vidQue.canplay) {
                       // console.log('2: vidQue.canplay true')
                        vidElem.muted = false;
                        vidElem
                            .play()
                            .then(() => {
                                props.OnLoaded(true);

                                if (props.showVoice) {
                                    props.OnPrepSilent(true);
                                }

                                const dateNow = Date.now();
                                const dateReceived = vidQue.received;

                                vidQue.pubnubtoplay = (dateNow - dateReceived) / 1000 + " sec";
                                vidQue.receivedFormatted = epochToDate(dateReceived);
                                vidQue.played = epochToDate(dateNow);
                            })
                            .catch((error) => {
                                console.log(`ERROR :: ${vidElem.src} `, error.message);
                                gotoNext();
                            });

                        vidElem.onended = (e) => {
                            curr.state = "ended";
                            gotoNext();
                        };
                    } else if (curr.retried >= 20) {
                       // console.log(`2: retried 20 times for ${filename}, will go next`);
                        gotoNext();
                    } else {
                       // console.log(`2: cant play : ${filename},  retried ${curr.retried}`);
                        let timeCantPlay = setTimeout(() => {
                            clearTimeout(timeCantPlay);
                            curr.retried = curr.retried + 1;
                            setCurr({ ...curr });
                        }, 1000);
                    }
                } else {
                  //  console.log('1: curr.state ended')
                    gotoNext();
                }
            } else {
                console.log(`cant find vidElem ${curr.findex}`);
            }
        }
    }, [curr]);

    const gotoNext = () => {
        let timeout;
        const nextFindex = curr.findex + 1;

        const nextElem = vidsRef.current.find((vid) => parseInt(vid.dataset.findex) === nextFindex);
        const nextQue = videoQueue.find((viq) => parseInt(viq.findex) === nextFindex);

        if (nextElem && nextQue && nextQue.canplay && curr.state === "ended") {
            setCurr({ findex: nextFindex, state: null, retried: 0, elemRetried: 0 });
        } else {
            //console.log(`gotoNext retrying for findex ${nextFindex}`);
            timeout = setTimeout(() => {
                curr.retried = curr.retried + 1;
                setCurr({ ...curr });
                clearTimeout(timeout);
            }, 1000);
        }
    };

    return (
        <>
            {videoQueue.map((vidObj, v) => {
                //vidObj.index = v;
                let vidsToPreload = 2;
                let thevidref;

                if (vidObj.findex <= curr.findex + vidsToPreload && !vidObj.ended) {
                    return (
                        <video
                            key={v}
                            className={`v-${vidObj.findex} video-player-${vidObj.findex === curr.findex ? "current fadeIn" : "sub"}`}
                            data-index={v}
                            data-findex={vidObj.findex}
                            loop={false}
                            // src={v >= curr.index ? vidObj.video : ""}
                            src={vidObj.video}
                            autoPlay={false}
                            muted={true}
                            playsInline
                            ref={(vidref) => {
                                if (vidref) {
                                    thevidref = vidref;
                                    let findvidref = vidsRef.current.find((vid) => vid.dataset.findex === vidref.dataset.findex);
                                    if (!findvidref) {
                                        vidsRef.current = [...vidsRef.current, vidref];
                                    }
                                }
                            }}
                            onEnded={(e) => {
                                vidObj.ended = true;
                                vidObj.isplaying = false;
                                //console.log(`▣ ended ${Date.now()}: ${epochToDate(Date.now())}`, e.target.currentSrc);
                            }}
                            onPlaying={(e) => {
                                vidObj.isplaying = true;
                                console.log(`🟢 playing ${Date.now()}: ${epochToDate(Date.now())}`, e.target.currentSrc);
                            }}
                            onPause={(e) => {
                                vidObj.isplaying = false;
                                //console.log("paused :", e.target.currentSrc);
                            }}
                            onError={(e) => {
                                let fname = e.target.currentSrc.split("/")[e.target.currentSrc.split("/").length - 1];
                                console.log(`error  ${fname}:`, e.target.error);
                            }}
                            onCanPlayThrough={(e) => {
                                // let fname = e.target.currentSrc.split("/")[e.target.currentSrc.split("/").length - 1];
                                // console.log('canplay :', fname)
                                vidObj.canplay = true;
                            }}
                        />
                    );
                }
            })}
        </>
    );
};

export default VideoVoice;
