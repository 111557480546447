import './section.css'


export const Section = (props) => {
    return ( 
        <div className={`section ${(props.sectionStyle)? `section-${props.sectionStyle}` : ``} `}>
            {props.children}
        </div>
     );
}

export const SectionItem = (props) => {
    return ( 
        <div className="section-item">
            {props.children}
        </div>
     );
}
 
 
